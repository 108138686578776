import React from 'react';
import { Button, Input, List } from "antd";
import { GetTranslation, SetAllTranslation, SetTranslation } from "../../entities/Translation";
import { GameSelectionValue } from '../../entities/GameSelectionValue';

interface OptionElementProps {
    option: GameSelectionValue,
    languageCode: string,
    overwriteAllMode: boolean,
    onChange(targetOption: GameSelectionValue): void
    onRemove(): void
}

enum Fields {
    name = 0,
    nameKey = 1,
    orderPos = 2
}

export function OptionElement({ languageCode, option, overwriteAllMode, onChange, onRemove }: OptionElementProps): React.ReactElement {
    function onOptionChange(event: React.ChangeEvent<HTMLInputElement>, field: Fields) {
        const editOption = { ...option };
        switch (field) {
            case Fields.name:
                overwriteAllMode ? SetAllTranslation(editOption.name, event.target.value) : SetTranslation(languageCode, editOption.name, event.target.value);
                break;
            case Fields.nameKey:
                editOption.nameKey = event.target.value;
                break;
            case Fields.orderPos:
                editOption.orderPos = event.target.valueAsNumber;
                break;
        }
        onChange(editOption);
    }

    return (
        <div>
            <List.Item>
                <Input value={GetTranslation(languageCode, option.name)} onChange={value => onOptionChange(value, Fields.name)} />
                <Input type="number" value={option.orderPos} onChange={value => onOptionChange(value, Fields.orderPos)} />
                <Input value={option.nameKey} disabled onChange={value => onOptionChange(value, Fields.nameKey)} />
                <Input value={option.id} disabled />
                <Button onClick={onRemove}>Remove</Button>
            </List.Item>
        </div>
    )
}