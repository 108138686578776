import React, { useState } from "react";
import { LinkedPlatformSchema } from "../../entities/LinkedPlatformSchema";
import { Modal, Button, Descriptions,  Card, Input, Form, Alert, message, Upload } from 'antd';
import {
    DeleteOutlined
} from '@ant-design/icons';
import { useApiContext } from "../../context/api-context";
import { useHistory } from "react-router-dom";
import settings from "../../settings";
import PlatformApi from "../../api/api-platform";

interface LinkedPlatformsDetailProps {
    platform: LinkedPlatformSchema,
    onSave(LinkedPlatform: LinkedPlatformSchema): Promise<boolean>,
    showDeleteButton: boolean
}

enum Values {
    platformName = "platformName",
    imageLink = "imageLink",
    userNameExample = "userNameExample",
    userNameRegex = "userNameRegex",
    userLinkExample = "userLinkExample",
    userLinkRegex = "userLinkRegex"
}

export default function LinkedPlatformsDetail({ onSave, showDeleteButton, platform }: LinkedPlatformsDetailProps): React.ReactElement {
    const [iconImageFileId, setIconImageFileId] = useState(platform.iconImageFileId);
    const [isChanged, setIsChange] = useState<boolean>(false);
    const [platformName, setPlatformName] = useState(platform.platformName);
    const [userNameExample, setUserNameExample] = useState(platform.userNameExample);
    const [userNameRegex, setUserNameRegex] = useState(platform.userNameRegex);
    const [userLinkExample, setUserLinkExample] = useState(platform.userLinkExample);
    const [userLinkRegex, setUserLinkRegex] = useState(platform.userLinkRegex);
    const apiContext = useApiContext();
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    function onReset() {
        setIsChange(false);
        setPlatformName(platform.platformName);
        setUserNameExample(platform.userNameExample);
        setUserNameRegex(platform.userNameRegex);
        setUserLinkExample(platform.userLinkExample);
        setUserLinkRegex(platform.userLinkRegex);
    }

    function onSubmit() {
        platform.platformName = platformName;
        platform.userNameExample = userNameExample;
        platform.userNameRegex = userNameRegex;
        platform.userLinkExample = userLinkExample;
        platform.userLinkRegex = userLinkRegex;

        onSave(platform).then((success) => {
            if (success) {
                setIsChange(false);
                message.success("jetzt ist's zu spät!");
            }
        });
    }

    async function onDelete() {
        if (platform.id) {
            try {
                const success = await PlatformApi.DeletePlatformSchema(platform.id);
                if (success) {
                    setIsModalVisible(false);
                    message.success("jetzt ist's zu spät!");
                    history.push("/platformlinks-all");
                }
                return success;
            }
            catch {
                return false;
            }
        }
    }

    function onChange(key: Values, e: React.ChangeEvent<HTMLInputElement>) {
        setIsChange(true);
        switch (key) {
            case Values.platformName:
                setPlatformName(e.target.value);
                break;
            case Values.userNameExample:
                setUserNameExample(e.target.value);
                break;
            case Values.userNameRegex:
                setUserNameRegex(e.target.value);
                break;
            case Values.userLinkExample:
                setUserLinkExample(e.target.value);
                break;
            case Values.userLinkRegex:
                setUserLinkRegex(e.target.value);
                break;
        }
    }

    async function onIconUpload(file: Blob): Promise<string> {
        if (!platform.id) {
            message.warning("upload after creation possible")
            return "error";
        }
        if (!file) {
            message.warning("missing content")
            return "error";
        }

        const response = await PlatformApi.PostIconImage(platform.id, file);
        if (response.status === 200) {
            setIconImageFileId(response.data.iconImageFileId);
            message.success("Uploaded!");
            return "done";
        }

        return "error";
    }

    return (<>
        <Card>
            {isChanged &&
                <>
                    <Alert
                        message="You have unsaved changes!"
                        banner
                        closable
                    />
                    <Form.Item>
                        <Button type="primary" htmlType="button" onClick={onSubmit} >
                            Submit
                        </Button>
                        <Button htmlType="button" onClick={onReset}>
                            Reset
                        </Button>
                    </Form.Item>
                </>}
            <Descriptions extra={showDeleteButton && <DeleteOutlined style={{ color: 'red', fontSize: '42px' }} onClick={showModal} />} />
            <Modal title={"Delete " + platformName + "?"} visible={isModalVisible} onOk={onDelete} onCancel={handleCancel}>
                <p>Do you REALLY want to DELETE this platform? This can not be reversed! Deleted objects are gone FOREVEEEEEEER!!</p>
            </Modal>

            <label>IconImage</label>
            <Upload
                name="IconImage"
                accept=".png, .jpg, .jpeg"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={onIconUpload}
            >
                <img src={settings.GetFileUrl(iconImageFileId)} alt="Missing" style={{ width: '100%' }} />
            </Upload>

            <Descriptions column={1} title={platformName} extra={platform.id} >
                <Descriptions.Item label="PlatformName" labelStyle={{ alignItems: "center" }} >
                    <Input name="platformName" value={platformName} onChange={(value) => onChange(Values.platformName, value)} />
                </Descriptions.Item>
                <Descriptions.Item label="UserNameExample" labelStyle={{ alignItems: "center" }} >
                    <Input name="userNameExample" value={userNameExample} onChange={(value) => onChange(Values.userNameExample, value)} />
                </Descriptions.Item>
                <Descriptions.Item label="UserNameRegex" labelStyle={{ alignItems: "center" }} >
                    <Input name="userNameRegex" value={userNameRegex} onChange={(value) => onChange(Values.userNameRegex, value)} />
                </Descriptions.Item>
                <Descriptions.Item label="UserLinkExample" labelStyle={{ alignItems: "center" }} >
                    <Input name="userLinkExample" value={userLinkExample} onChange={(value) => onChange(Values.userLinkExample, value)} />
                </Descriptions.Item>
                <Descriptions.Item label="UserLinkRegex" labelStyle={{ alignItems: "center" }} >
                    <Input name="userLinkRegex" value={userLinkRegex} onChange={(value) => onChange(Values.userLinkRegex, value)} />
                </Descriptions.Item>
            </Descriptions>
        </Card>
    </>
    );
}