import React, { useState } from 'react'
import { Card, Col, Image, Divider, Row, Descriptions, Checkbox, Button, Input, message, Alert, Form, Select } from 'antd'
import noImage from '../../images/noImage.png'
import settings from '../../settings'
import { Link, useHistory } from 'react-router-dom'
import { AiImage, DEFAULT_AWS_URL_OPTIMIZED, EUpscaleType, LeagueOfLegendsGenerateData } from '../../api/ImageApi'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import UserLink from '../user/user-link'
import { AiImageStyle, EStyleCategory } from '../../api/PromptApi'
import TextArea from 'antd/lib/input/TextArea'

interface StyleDetailProps {
   style: AiImageStyle
   onSave(style: AiImageStyle): Promise<boolean>
   onRefresh?(): void
   showDeleteButton: boolean
}

const { Option } = Select

export default function StyleDetail({
   onSave,
   onRefresh = () => {
      return
   },
   style: _style
}: StyleDetailProps): React.ReactElement {
   const [style, setStyle] = useState(_style)
   const [isChanged, setIsChange] = useState<boolean>(false)

   function onReset() {
      setIsChange(false)
      setStyle(_style)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(style).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   function onChange(editStyle: AiImageStyle) {
      setIsChange(true)
      setStyle(editStyle)
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}
         {/* {style.isHidden != image.isHidden && <Button onClick={() => onSave(style)}>Save</Button>} */}
         <Row>
            <Col flex="1">
               <Descriptions title="Style Info" bordered>
                  <DescriptionsItem span={3} label={'Id'}>
                     {style.id}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={style.name ? style.name : ''} onChange={(e) => onChange({ ...style, name: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Lora Id'}>
                     <Input value={style.loraId ? style.loraId : ''} onChange={(e) => onChange({ ...style, loraId: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Positive Prompt Trigger'}>
                     <Input
                        value={style.positivePromptTrigger ? style.positivePromptTrigger : ''}
                        onChange={(e) => onChange({ ...style, positivePromptTrigger: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Helper Prompts'}>
                     <TextArea
                        rows={5}
                        value={style.helperPrompts ? style.helperPrompts : ''}
                        onChange={(e) => onChange({ ...style, helperPrompts: e.target.value })}></TextArea>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Secondary Model Type'}>
                     {style.secondaryModelType}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'ImageUrl'}>
                     <Input value={style.imageUrl} onChange={(e) => onChange({ ...style, imageUrl: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Light Weight'}>
                     <Input type="number" value={style.lightWeight} onChange={(e) => onChange({ ...style, lightWeight: e.target.valueAsNumber })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Moderate Weight'}>
                     <Input type="number" value={style.moderateWeight} onChange={(e) => onChange({ ...style, moderateWeight: e.target.valueAsNumber })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Strong Weight'}>
                     <Input type="number" value={style.strongWeight} onChange={(e) => onChange({ ...style, strongWeight: e.target.valueAsNumber })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Intense Weight'}>
                     <Input type="number" value={style.intenseWeight} onChange={(e) => onChange({ ...style, intenseWeight: e.target.valueAsNumber })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'NSFW'}>
                     {style.nSFW}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Type'}>
                     <Select style={{ width: '100%' }} value={EStyleCategory[style.type]} onChange={(e) => onChange({ ...style, type: Number.parseInt(e) })}>
                        <Option key={EStyleCategory.Accessoires}>{'Accessoires'}</Option>
                        <Option key={EStyleCategory.Aesthetics}>{'Aesthetics'}</Option>
                        <Option key={EStyleCategory.Artists}>{'Artists'}</Option>
                        <Option key={EStyleCategory.Brands}>{'Brands'}</Option>
                        <Option key={EStyleCategory.Clothings}>{'Clothings'}</Option>
                        <Option key={EStyleCategory.Concepts}>{'Concepts'}</Option>
                        <Option key={EStyleCategory.Memes}>{'Memes'}</Option>
                        <Option key={EStyleCategory.Poses}>{'Poses'}</Option>
                        <Option key={EStyleCategory.Fetish}>{'Fetish'}</Option>
                     </Select>
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
