import axios, { AxiosResponse } from 'axios'
import { CardType } from '../entities/CardType'
import PageList from '../entities/PageList'
import ReigniteCard from '../entities/ReigniteCard'

const GetAll = async (types?: string[], owner?: boolean, q?: string, PageSize?: number, PageNumber?: number): Promise<PageList<ReigniteCard>> => {
   const params = new URLSearchParams()
   types?.forEach((type) => params.append('types', type))

   owner && params.append('owner', owner.toString())

   q && params.append('q', q)

   PageSize && params.append('PageSize', PageSize.toString())

   PageNumber && params.append('PageNumber', PageNumber.toString())

   const response = await axios.get<ReigniteCard[]>('/api/admin/card', { params: params })

   const pageInfo = JSON.parse(response.headers['paging-headers'])
   return { items: response.data, pageInfo }
}

const GetCardTypes = (): Promise<AxiosResponse<CardType[], unknown>> => {
   return axios.get<CardType[]>('/api/Resource/ECardTypes')
}

const GetAllOfUser = (userId: string): Promise<AxiosResponse<ReigniteCard[]>> => {
   return axios.get<ReigniteCard[]>(`/api/card/user/${userId}`)
}

const CardApi = {
   GetAll,
   GetAllOfUser,
   GetCardTypes
}

export default CardApi
