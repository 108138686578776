import React from "react";
import { Link } from "react-router-dom";
import { News } from "../../entities/News";

interface NewsLinkProps {
    news: News
}

export default function NewsLink({ news }: NewsLinkProps): React.ReactElement {
    return <Link to={`/news/${news.id}`}>{news.title || "NEW NEWS"}</Link>
}