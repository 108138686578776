import React, { useRef, useState } from "react";
import { Modal, Button, Descriptions, Card, Input, Form, Alert, message, Upload, Checkbox, Row, Col } from 'antd';
import {
    DeleteOutlined
} from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from "react-router-dom";
import settings from "../../settings";
import { News } from "../../entities/News";
import NewsApi from "../../api/news-api";

interface Props {
    news: News,
    onSave(news: News): Promise<boolean>,
    showDeleteButton: boolean
}

export default function NewsDetail({ onSave, showDeleteButton, news }: Props): React.ReactElement {
    const RteRef = useRef<ReactQuill | null>(null);
    const history = useHistory();

    const [isChanged, setIsChange] = useState<boolean>(false);
    const [editNews, setEditNews] = useState<News>(news);

    const [deleteActive, setDeleteActive] = useState(false);

    function onReset() {
        setIsChange(false);
        setEditNews(news);
    }

    function onSubmit() {
        if (!isChanged) { return; }

        onSave(editNews).then((success) => {
            if (success) {
                setIsChange(false);
                message.success("Saved!");
            }
        });
    }

    async function onDelete() {
        setDeleteActive(false);

        if (news.id) {
            try {
                const response = await NewsApi.Delete(news.id);
                if (response.status == 200) {
                    message.success("Deleted!");
                    history.push("/news-all");
                    return true;
                }

                message.error(`Error response status ${response.statusText} (${response.status})`);
            }
            catch {
                return false;
            }
        }

        return false;
    }

    function onChange(_news: News) {
        setIsChange(true);
        setEditNews({ ..._news, mainImageId: editNews.mainImageId })
    }

    async function onImageUpload(file: Blob): Promise<string> {
        if (!news.id) {
            message.warning("upload after creation possible")
            return "error";
        }
        if (!file) {
            message.warning("missing content")
            return "error";
        }

        const response = await NewsApi.UpdateImage(news.id, file);
        if (response.status == 200) {
            onChange({ ...editNews, mainImageId: response.data.mainImageId })
            message.success("Uploaded!");
            return "done";
        }

        return "error";
    }

    async function clearMainText() {
        RteRef.current?.getEditor().setText("")
        onChange({ ...editNews, mainText: undefined })
    }

    return (<>
        <Card>
            {isChanged &&
                <>
                    <Alert
                        message="You have unsaved changes!"
                        banner
                        closable
                    />
                    <Form.Item>
                        <Button type="primary" htmlType="button" onClick={onSubmit} >
                            Submit
                        </Button>
                        <Button htmlType="button" onClick={onReset}>
                            Reset
                        </Button>
                    </Form.Item>
                </>}

            <Descriptions />
            <Modal title={"Delete " + editNews.title + "?"} visible={deleteActive} onOk={onDelete} onCancel={() => setDeleteActive(false)}>
                <p>Do you REALLY want to DELETE this news? This can not be reversed! Deleted objects are gone FOREVEEEEEEER!!</p>
            </Modal>

            <Descriptions column={1} title={editNews.title} extra={showDeleteButton && <DeleteOutlined style={{ color: 'red', fontSize: '42px' }} onClick={() => setDeleteActive(true)} />} >
                <Descriptions.Item label="Id" labelStyle={{ alignItems: "center" }} >
                    {editNews.id}
                </Descriptions.Item>
                <Descriptions.Item label="IconImage" labelStyle={{ alignItems: "center" }} >
                    <Upload
                        name="IconImage"
                        accept=".png, .jpg, .jpeg"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={onImageUpload}
                    >
                        <img src={settings.GetFileUrl(editNews.mainImageId)} alt="Missing" style={{ width: '100%' }} />
                    </Upload>
                </Descriptions.Item>
                <Descriptions.Item label="Title" labelStyle={{ alignItems: "center" }} >
                    <Input name="title" value={editNews.title} onChange={(value) => onChange({ ...editNews, title: value.target.value })} />
                </Descriptions.Item>
                {/*<Descriptions.Item label="MainText" labelStyle={{ alignItems: "center" }} >
                    <Input.TextArea rows={10} name="mainText" value={editNews.mainText} onChange={(value) => onChange({ ...editNews, mainText: value.target.value })} />
                </Descriptions.Item>*/}
                <Descriptions.Item label="Link" labelStyle={{ alignItems: "center" }} >
                    <Input name="href" allowClear value={editNews.href} onChange={(value) => onChange({ ...editNews, href: value.target.value })} />
                </Descriptions.Item>
                <Descriptions.Item label="Published" labelStyle={{ alignItems: "center" }} >
                    <Checkbox name="published" checked={editNews.published} onChange={(value) => onChange({ ...editNews, published: value.target.checked })} />
                </Descriptions.Item>
            </Descriptions>
            <h3>Main Text:
                {editNews.mainText && <Button style={{ position: "absolute", right: "2%" }} danger shape="circle" type="primary" icon={<DeleteOutlined />} onClick={clearMainText} />}
            </h3>
            <ReactQuill
                ref={RteRef}
                style={{ height: "500px" }}
                theme="snow"
                defaultValue={editNews.mainText}
                onChange={(mainText) => onChange({ ...editNews, mainText })}
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],

                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction

                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],

                        ['clean']
                    ]
                }}
            />
        </Card>
    </>
    );
}