import { Form, Button, InputNumber, message, Input } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import GameApi from '../../api/game-api'

export default function NewGameIgdb(): React.ReactElement {
   const history = useHistory()
   const [responseText, setResponseText] = useState<string>()

   async function onFinish(newIgdbGame: { IgdbId: string }) {
      try {
         const response = await GameApi.AddIgdbGame(newIgdbGame.IgdbId)

         if (response.status === 200) {
            history.push(`/game/${response.data.id}`)
            message.success('Game Created')
         } else {
            message.error(`response ${response.statusText} (${response.status})`)
         }
      } catch (error) {
         setResponseText('Failed to create game (check console)')
         console.warn(error)
      }
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const onFinishFailed = (errorInfo: any) => {
      console.warn('Failed:', errorInfo)
   }

   return (
      <Form
         name="Igdb_Game"
         labelCol={{ span: 2 }}
         wrapperCol={{ span: 16 }}
         initialValues={{ remember: true }}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}>
         <p style={{ fontSize: 24 }}>
            Import a game from{' '}
            <a href="https://www.igdb.com/" target="_blank" rel="noreferrer">
               igdb.com
            </a>
         </p>
         <Form.Item label="IGDB ID" name="IgdbId" rules={[{ required: true, message: 'Please enter igdbId!' }]}>
            <InputNumber placeholder="136625" />
         </Form.Item>
         <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
            <Button type="primary" htmlType="submit">
               Submit
            </Button>
            <p style={{ fontSize: 40 }}>{responseText}</p>
         </Form.Item>
      </Form>
   )
}
