import axios, { AxiosResponse } from "axios"
import { News } from "../entities/News";

function GetAll(): Promise<AxiosResponse<News[], unknown>> {
    return axios.get<News[]>("/api/news/all")
}

function GetById(newsId: string): Promise<AxiosResponse<News, unknown>> {
    return axios.get<News>(`/api/news/${newsId}`)
}

function Update(news: News): Promise<AxiosResponse<News, unknown>> {
    return axios.put<News>(`/api/news`, news)
}

function UpdateImage(newsId: string, image: Blob): Promise<AxiosResponse<News, unknown>> {
    const bodyFormData = new FormData();
    bodyFormData.append("imageFile", image);

    return axios.post<News>(`/api/news/${newsId}/image`, bodyFormData, { headers: { "Content-Type": "multipart/form-data" } })
}

function Create(news: News): Promise<AxiosResponse<News, unknown>> {
    return axios.post<News>("/api/news/", news)
}

function Delete(newsId: string): Promise<AxiosResponse<unknown, unknown>> {
    return axios.delete(`/api/news/${newsId}`)
}

const NewsApi = {
    GetAll,
    GetById,
    Update,
    UpdateImage,
    Create,
    Delete
}

export default NewsApi;