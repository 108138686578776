import React from 'react'
import { Card, Col, Row, Descriptions, Image, Checkbox } from 'antd'
import { AiImage, AiImageRequestMetrics, EGenerationStatus, EUpscaleType } from '../../api/ImageApi'
import noImage from '../../images/noImage.png'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import { Link } from 'react-router-dom'
import UserLink from '../user/user-link'
import moment from 'moment'

interface StyleDetailProps {
   image: AiImage
   metrics: AiImageRequestMetrics
   onRefresh?(): void
}

export default function MetricsDetail({
   onRefresh = () => {
      return
   },
   image: aiImage,
   metrics
}: StyleDetailProps): React.ReactElement {
   return (
      <>
         <Card>
            <Row>
               <Col flex="1">
                  <Descriptions title="Style Info" bordered>
                     <DescriptionsItem span={3} label={'Id'}>
                        {metrics.id}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Status'}>
                        {EGenerationStatus[metrics.status]}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Time in Millieconds FE'}>
                        {moment(metrics.feCompletionTime).valueOf() !== -62135596800000
                           ? moment(metrics.feCompletionTime).diff(moment(metrics.requestTime), 'milliseconds')
                           : moment(metrics.beCompletionTime).diff(moment(metrics.requestTime), 'milliseconds')}
                        ms
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'RequestTime'}>
                        {moment(metrics.requestTime).format('DD.MM.YYYY HH:mm:ss')}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'BECompletionTime'}>
                        {moment(metrics.beCompletionTime).format('DD.MM.YYYY HH:mm:ss')}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'FECompletionTime'}>
                        {moment(metrics.feCompletionTime).format('DD.MM.YYYY HH:mm:ss')}
                     </DescriptionsItem>
                  </Descriptions>
               </Col>
            </Row>
         </Card>
         <Card>
            <Row>
               <Col flex="200px">
                  <Image src={aiImage.imageUrl} width={500} fallback={noImage} />
               </Col>
               <Col flex="1">
                  <Descriptions title="AI Image Info" bordered>
                     <DescriptionsItem span={3} label={'Id'}>
                        {aiImage.id}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Seed'}>
                        {aiImage.seed}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'UpscaleType'}>
                        {EUpscaleType[aiImage.upscaleType]}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Upscaled From'}>
                        <Link target="_blank" rel="noopener noreferrer" to={`/image/${aiImage.upscaledFrom}`}>
                           {aiImage.upscaledFrom}
                        </Link>
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Owner'}>
                        <UserLink user={aiImage.owner} />
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Skin'}>
                        {aiImage.skin}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Checkpoint'}>
                        <a target="_blank" href={aiImage.checkpointId} rel="noreferrer">
                           <Link target="_blank" rel="noopener noreferrer" to={`/checkpoints/${aiImage.checkpointId}`}>
                              {aiImage.checkpointId}
                           </Link>
                        </a>
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Character'}>
                        <a target="_blank" href={aiImage.characterId} rel="noreferrer">
                           <Link target="_blank" rel="noopener noreferrer" to={`/characters/${aiImage.characterId}`}>
                              {aiImage.skin} {aiImage.name}
                           </Link>
                        </a>
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'UserPrompt'}>
                        {aiImage.userPrompt}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Likes'}>
                        {aiImage.likedCount}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Impressions'}>
                        {aiImage.impressionCount}
                     </DescriptionsItem>
                     {/* <DescriptionsItem span={3} label={'Hidden'}>
                        <Checkbox checked={aiImage.isHidden} onChange={(e) => setAiImage({ ...aiImage, isHidden: e.target.checked })} />
                     </DescriptionsItem> */}
                  </Descriptions>
               </Col>
            </Row>
         </Card>
      </>
   )
}
