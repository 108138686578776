import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import AiImageApi, { AiImage, AiImageRequestMetrics } from '../../../api/ImageApi'
import MetricsDetail from '../../../components/images/metrics-detail'

interface CharacterPageProps {
   id: string
}

export default function MetricsPage({ match }: RouteComponentProps<CharacterPageProps>): React.ReactElement {
   const [metrics, setMetrics] = useState<AiImageRequestMetrics>()
   const [image, setImage] = useState<AiImage>()
   const history = useHistory()

   async function fetch() {
      setMetrics(undefined)
      setImage(undefined)
      const metrics = await AiImageApi.GetMetricsById(match.params.id)
      const image = await AiImageApi.GetImage(metrics.data.aiImageId)
      setMetrics(metrics.data)
      setImage(image.data)
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [match])

   return (
      <>
         {!metrics && !image && <Skeleton active />}
         {metrics && image && <MetricsDetail onRefresh={fetch} image={image} metrics={metrics} />}
      </>
   )
}
