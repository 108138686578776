import React, { useEffect, useState } from 'react'
import { TableParams } from '../../../components/images/image-list'
import { Table, TablePaginationConfig, Image, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { Link, useHistory } from 'react-router-dom'
import AiPromptApi, { AiImageCheckpoint, ECheckpointCategory } from '../../../api/PromptApi'
import noImage from '../../../images/noImage.png'
import { DEFAULT_AWS_URL_OPTIMIZED } from '../../../api/ImageApi'

export default function CheckpointsAllPage(): React.ReactElement {
   const history = useHistory()
   const [data, setData] = useState<AiImageCheckpoint[]>()
   const [searchValue, setSearchValue] = useState<string>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch()
   }, [])

   async function fetch() {
      setLoading(true)
      const result = await AiPromptApi.GetCheckpoints()

      setData(result.data)

      setLoading(false)
   }

   const columns: ColumnsType<AiImageCheckpoint> = [
      {
         title: 'Image',
         key: 'imageUrl',
         dataIndex: 'imageUrl',
         render: (value, checkpoint) => <Image src={checkpoint.imageUrl} width={200} fallback={noImage} />
      },
      {
         title: 'Name',
         key: 'id',
         dataIndex: 'id',
         render: (value, checkpoint) => (
            <a target="_blank" href={checkpoint.id} rel="noreferrer">
               <Link target="_blank" rel="noopener noreferrer" to={`/checkpoints/${checkpoint.id}`}>
                  {checkpoint.name}
               </Link>
            </a>
         )
      },
      {
         title: 'Type',
         key: 'type',
         dataIndex: 'type',
         render: (value, checkpoint) => <>{ECheckpointCategory[checkpoint.type]}</>
      }
   ]

   function onChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<AiImageCheckpoint> | SorterResult<AiImageCheckpoint>[]
   ) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch()
   }

   return (
      <>
         <Button onClick={() => history.push('/checkpoints-new')}>Add</Button>
         <Table columns={columns} pagination={tableParams.pagination} loading={loading} dataSource={data} onChange={onChange} />
      </>
   )
}
