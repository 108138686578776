import ReloadOutlined from '@ant-design/icons/lib/icons/ReloadOutlined'
import { Button, Card, Col, Row, Spin, Statistic, Table } from 'antd'
import Title from 'antd/lib/typography/Title'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UserApi from '../../api/api-user'
import EUserTutorialStep from '../../entities/ETutorialState'
import { UserInsights } from '../../entities/UserInsgights'
import { DownloadAsCsv } from '../../helper/DownloadHelper'

export default function UserInsightsPage(): React.ReactElement {
   const [lastUpdateDate, setLastUpdateDate] = useState<Moment>()
   const [userInsights, setUserInsights] = useState<UserInsights>()
   const [registerLoad, setRegisterLoad] = useState<boolean>(false)
   const [registerPage, setRegisterPage] = useState<number>(1)
   const [loadCount, setLoadCount] = useState<number>(0)

   async function LoadInsights() {
      setUserInsights(undefined)
      setLoadCount(loadCount + 1)
      const response = await UserApi.GetUserInsights()
      setLastUpdateDate(moment())
      setUserInsights(response.data)
   }

   async function LoadMoreRegistrations() {
      if (userInsights) {
         setLoadCount(loadCount + 1)
         setRegisterLoad(true)
         const response = await UserApi.GetRegistrationInsights(7, userInsights.registerd.length)
         if (response) {
            const insight = {
               ...userInsights,
               registerd: [...userInsights.registerd, ...response.data]
            }
            setUserInsights(insight)
            setRegisterPage(insight.registerd.length / 7)
         }
         setRegisterLoad(false)
      }
   }

   async function PageChange(page: number, pageSize: number) {
      setRegisterPage(page)
   }

   useEffect(() => {
      LoadInsights()
   }, [])

   useEffect(() => {
      if (loadCount == 11) {
         alert("Make sure you don't load insights too often, it's a heavy task, u know?")
      }
      if (loadCount == 16) {
         alert("Please... take care of your loads. I don't want to have to limit you!")
      }
   }, [loadCount])

   return (
      <>
         {!userInsights && <Spin size="large" />}
         {userInsights && lastUpdateDate && (
            <>
               <Row>
                  <Title>User Insights from {lastUpdateDate?.format('LT')}</Title>
                  <Button
                     onClick={LoadInsights}
                     style={{ marginLeft: '15px', marginTop: '2px' }}
                     size="large"
                     type="primary"
                     shape="circle"
                     icon={<ReloadOutlined />}
                  />
                  <Button style={{ margin: 5 }} type="primary" onClick={() => DownloadAsCsv('insights', [userInsights])}>
                     Export
                  </Button>
               </Row>
               <Link
                  to="/dashboard-v2"
                  style={{
                     color: 'red',
                     fontSize: '1.5rem',
                     textDecoration: 'underline'
                  }}>
                  This is the old dashboard (Open V2)
               </Link>
               <Card title={<strong>Users</strong>}>
                  <Row gutter={16}>
                     <Col span={12}>
                        <Statistic title="Total" value={userInsights.totalUsers} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Registered Users" value={userInsights.registeredUsers} suffix="Users" />
                     </Col>
                     <Col span={24}>
                        <Statistic title="With Gameprofile" value={userInsights.usersWithGameprofile} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Open Invites" value={userInsights.openInvites} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Open Registrations" value={userInsights.openRegisters} suffix="Users" />
                     </Col>
                  </Row>
               </Card>
               <Card style={{ marginTop: '15px' }} title={<strong>User Activity</strong>}>
                  <Row gutter={16}>
                     <Col span={24}>
                        <Statistic title="Online" value={userInsights.online} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Active Today" value={userInsights.activeToday} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Active Week" value={userInsights.activeWeek} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Friends" value={userInsights.friends} />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Messages" value={userInsights.messages} />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Open friend requests" value={userInsights.openRequests} />
                     </Col>
                  </Row>
               </Card>
               {/* ToDo: make prettier graphs => https://github.com/samueldelesque/react-simple-charts */}
               <Card
                  style={{ marginTop: '15px' }}
                  title={
                     <>
                        <strong>Registrations per day</strong>
                        <Button style={{ margin: 5 }} type="primary" onClick={() => DownloadAsCsv('registrations', userInsights.registerd)}>
                           Export Result
                        </Button>
                     </>
                  }>
                  <Table
                     dataSource={userInsights.registerd}
                     columns={[
                        {
                           title: 'Date',
                           key: 'time',
                           dataIndex: 'date',
                           render: (date) => moment(date).format('DD.MM.YYYY')
                        },
                        {
                           title: 'With Gameprofile',
                           dataIndex: 'withGameprofile',
                           key: 'withGameprofile'
                        },
                        {
                           title: 'Registered',
                           dataIndex: 'registered',
                           key: 'registered'
                        },
                        {
                           title: 'Total',
                           dataIndex: 'total',
                           key: 'total'
                        }
                     ]}
                     pagination={{
                        pageSize: 7,
                        onChange: PageChange,
                        current: registerPage,
                        pageSizeOptions: []
                     }}
                     footer={() => (
                        <>
                           <Button disabled={registerLoad} loading={registerLoad} type="primary" onClick={LoadMoreRegistrations}>
                              Load more...
                           </Button>
                        </>
                     )}
                  />
               </Card>
               <Card
                  style={{ marginTop: '15px' }}
                  title={
                     <>
                        <strong>Tutorial Stats</strong>
                        <Button style={{ margin: 5 }} type="primary" onClick={() => DownloadAsCsv('tutorial-status', userInsights.tutorialState)}>
                           Export
                        </Button>
                     </>
                  }>
                  <Row gutter={16}>
                     {userInsights.tutorialState.map((state) => (
                        <Col key={state.state} span={12}>
                           <Statistic title={EUserTutorialStep[state.state] || `Step ${state.state}`} value={state.count} suffix="Users" />
                        </Col>
                     ))}
                  </Row>
               </Card>
               <Card
                  style={{ marginTop: '15px' }}
                  title={
                     <>
                        <strong>Gameprofile Rank</strong>
                        <Button style={{ margin: 5 }} type="primary" onClick={() => DownloadAsCsv('gameprofile-rank', userInsights.gameprofiles)}>
                           Export
                        </Button>
                     </>
                  }>
                  <Statistic title="Total Created" value={userInsights.totalGameprofiles} suffix="Gameprofiles" />
                  <Row style={{ marginTop: '15px' }} gutter={16}>
                     {userInsights.gameprofiles.map((gameprofileInsight) => (
                        <Col key={gameprofileInsight.id} span={12}>
                           <Statistic title={gameprofileInsight.profileName} value={gameprofileInsight.count} suffix="Users" />
                        </Col>
                     ))}
                  </Row>
               </Card>
            </>
         )}
      </>
   )
}
