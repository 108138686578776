import { Select } from 'antd'
import React from 'react'

interface Props<T> {
   value: T
   from: any
   allowNull?: boolean
   onChange(value: T): void
}

export default function EnumPicker<T>({ value, from, allowNull, onChange }: Props<T>) {
   const stringEnum = Object.keys(from).map((key) => ({ value: key, label: from[key] }))
   const numberEnum = Object.keys(from)
      .map((x) => Number(x))
      .filter((x) => !isNaN(x))
      .map((key) => ({ value: key, label: from[key] }))
   const values = numberEnum.length > 0 ? numberEnum : stringEnum

   const options = allowNull ? [{ label: 'Null', value: null }, ...values] : values

   return <Select value={value} style={{ width: '100%' }} onChange={onChange} options={options} />
}
