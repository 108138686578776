import React, { useEffect, useState } from 'react'
import { TableParams } from '../../../components/images/image-list'
import { Table, TablePaginationConfig, Image, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { Link, useHistory } from 'react-router-dom'
import AiImageApi, { AiImageRequestMetrics, EGenerationStatus } from '../../../api/ImageApi'
import noImage from '../../../images/noImage.png'
import moment from 'moment'

export default function MetricsAllPage(): React.ReactElement {
   const history = useHistory()
   const [data, setData] = useState<AiImageRequestMetrics[]>()
   const [searchValue, setSearchValue] = useState<string>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch()
   }, [])

   async function fetch() {
      setLoading(true)
      const result = await AiImageApi.GetMetrics()

      setData(result.data)

      setLoading(false)
   }

   const columns: ColumnsType<AiImageRequestMetrics> = [
      {
         title: 'Image',
         key: 'aiImageId',
         dataIndex: 'aiImageId',
         render: (value, metrics) => (
            <Image
               src={'https://d1nv3961i7vw1y.cloudfront.net/images/' + metrics.aiImageId + '.jpeg?format=webp&quality=75&width=400'}
               width={200}
               fallback={noImage}
            />
         )
      },
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         render: (value, metrics) => (
            <a target="_blank" href={metrics.id} rel="noreferrer">
               <Link target="_blank" rel="noopener noreferrer" to={`/metrics/${metrics.id}`}>
                  {metrics.id}
               </Link>
            </a>
         )
      },
      {
         title: 'Status',
         key: 'status',
         dataIndex: 'status',
         filtered: true,
         defaultFilteredValue: [EGenerationStatus.Generating],
         filterMultiple: true,
         filters: [
            { text: 'Generating', value: EGenerationStatus.Generating },
            { text: 'Done', value: EGenerationStatus.Done }
         ],
         onFilter: (value, metrics) => metrics.status == value,
         render: (value, metrics) => <>{EGenerationStatus[metrics.status]}</>
      },
      {
         title: 'TotalTime FE',
         render: (value, metrics) => (
            <>
               {moment(metrics.feCompletionTime).valueOf() !== -62135596800000
                  ? moment(metrics.feCompletionTime).diff(moment(metrics.requestTime), 'milliseconds')
                  : moment(metrics.beCompletionTime).diff(moment(metrics.requestTime), 'milliseconds')}
               ms
            </>
         )
      },
      {
         title: 'RequestTime',
         key: 'requestTime',
         dataIndex: 'requestTime',
         render: (value, metrics) => <>{moment(metrics.requestTime).format('DD.MM.YYYY HH:mm:ss')}</>
      },
      {
         title: 'FECompletionTime',
         key: 'feCompletionTime',
         dataIndex: 'feCompletionTime',
         render: (value, metrics) => <>{moment(metrics.feCompletionTime).format('DD.MM.YYYY HH:mm:ss')}</>
      },
      {
         title: 'BECompletionTime',
         key: 'beCompletionTime',
         dataIndex: 'beeCompletionTime',
         render: (value, metrics) => <>{moment(metrics.beCompletionTime).format('DD.MM.YYYY HH:mm:ss')}</>
      }
   ]

   function onChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<AiImageRequestMetrics> | SorterResult<AiImageRequestMetrics>[]
   ) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch()
   }

   return (
      <>
         <Button onClick={() => history.push('/styles-new')}>Add</Button>
         <Table columns={columns} pagination={tableParams.pagination} loading={loading} dataSource={data} onChange={onChange} />
      </>
   )
}
