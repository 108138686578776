import axios from "axios";
import { Item } from "../entities/Item";

const GetAll = () => axios.get<Item[]>("/api/admin/item");
const Get = (id: string) => axios.get<Item>(`/api/admin/item/${id}`);
const Update = (item: Item) => axios.put<Item>(`/api/admin/item`, item);
const Create = (item: Item) => axios.post<Item>(`/api/admin/item`, item);
const Delete = (id: string) => axios.delete(`/api/admin/item/${id}`);

const ItemApi = {
  GetAll,
  Get,
  Update,
  Create,
  Delete
};

export default ItemApi;
