import axios from 'axios'

const GetCheckpoints = () => axios.get<AiImageCheckpoint[]>(`/api/admin/aiprompt/checkpoints`)
const GetCheckpoint = (id: string) => axios.get<AiImageCheckpoint>(`/api/admin/aiprompt/checkpoints/` + id)
const UpdateCheckpoint = (checkpoint: AiImageCheckpoint) => axios.put<AiImageCheckpoint>(`/api/admin/aiprompt/checkpoints/${checkpoint.id}`, checkpoint)
const AddCheckpoint = (checkpoint: AiImageCheckpoint) => axios.post<AiImageCheckpoint>(`/api/admin/aiprompt/checkpoints/`, checkpoint)
const GetThemes = () => axios.get<AiImageTheme[]>(`/api/admin/aiprompt/themes`)
const GetTheme = (id: string) => axios.get<AiImageTheme>(`/api/admin/aiprompt/themes/` + id)
const UpdateTheme = (theme: AiImageTheme) => axios.put<AiImageTheme>(`/api/admin/aiprompt/themes/${theme.id}`, theme)
const AddTheme = (theme: AiImageTheme) => axios.post<AiImageTheme>(`/api/admin/aiprompt/themes/`, theme)
const GetCharacters = () => axios.get<AiImageCharacter[]>(`/api/admin/aiprompt/characters`)
const GetCharacter = (id: string) => axios.get<AiImageCharacter>(`/api/admin/aiprompt/characters/` + id)
const UpdateCharacter = (character: AiImageCharacter) => axios.put<AiImageCharacter>(`/api/admin/aiprompt/characters/${character.id}`, character)
const AddCharacter = (character: AiImageCharacter) => axios.post<AiImageCharacter>(`/api/admin/aiprompt/characters/`, character)
const GetStyles = () => axios.get<AiImageStyle[]>(`/api/admin/aiprompt/styles`)
const GetStyle = (id: string) => axios.get<AiImageStyle>(`/api/admin/aiprompt/styles/` + id)
const UpdateStyle = (style: AiImageStyle) => axios.put<AiImageStyle>(`/api/admin/aiprompt/styles/${style.id}`, style)
const AddStyle = (style: AiImageStyle) => axios.post<AiImageStyle>(`/api/admin/aiprompt/styles/`, style)
const AiPromptApi = {
   GetCheckpoints,
   GetThemes,
   GetCharacters,
   GetCheckpoint,
   UpdateCheckpoint,
   AddCheckpoint,
   GetTheme,
   UpdateTheme,
   AddTheme,
   GetCharacter,
   UpdateCharacter,
   AddCharacter,
   GetStyles,
   GetStyle,
   UpdateStyle,
   AddStyle
}

export interface AiPromptOptions {
   checkpoints: AiImageCheckpoint[]
   themes: AiImageTheme[]
   characters: AiImageCharacter[]
   styles: AiImageStyle[]
}

export interface AiImageTheme {
   id: string | undefined
   name: string
   type: EThemeCategory
   imageUrl: string
}

export interface AiImageCharacter {
   id: string | undefined
   name: string
   skin: string
   themeId: string
   loraId: string
   helperPrompts: string
   positivePromptTrigger: string
   secondaryModelType: string
}

export interface AiImageCheckpoint {
   id: string | undefined
   name: string
   type: ECheckpointCategory
   description: string
   tags: string
   modelId: string
   imageUrl: string
}

export enum ECheckpointCategory {
   '2-5D' = 0,
   Anime = 1,
   RPG = 2,
   Cartoon = 3,
   Realism = 4
}

export enum EThemeCategory {
   Games = 0,
   Anime = 1
}

export interface AiImageStyle {
   id: string | undefined
   name: string
   type: EStyleCategory
   loraId: string
   positivePromptTrigger: string
   helperPrompts: string
   secondaryModelType: string
   nSFW: boolean
   imageUrl: string
   lightWeight?: number
   moderateWeight?: number
   strongWeight?: number
   intenseWeight?: number
}

export enum EStyleCategory {
   Accessoires = 1,
   Aesthetics = 2,
   Artists = 3,
   Brands = 4,
   Clothings = 5,
   Concepts = 6,
   Memes = 7,
   Poses = 8,
   Fetish = 9
}

export default AiPromptApi
