import React from 'react'
import { Link } from 'react-router-dom'
import { ShopItem } from '../../entities/ShopItem'

interface Props {
   shopItem: ShopItem
}

export default function ShopItemLink({ shopItem }: Props): React.ReactElement {
   return <Link to={`/shop-item/${shopItem.id}`}>{shopItem.name || 'NEW ShopItem'}</Link>
}
