import { Table, Image } from "antd";
import moment from "moment";
import React from "react";
import { UserGame } from "../../entities/UserGame";
import { Game } from "../../entities/Game";
import GameLink from "./game-link";
import settings from "../../settings";

interface UserGamesListProps {
    games: UserGame[]
}

export default function UserGamesList({ games }: UserGamesListProps): React.ReactElement {
    const columns = [
        {
            title: 'Image',
            dataIndex: 'game',
            key: 'game',
            render: function render(obj: Game) { return <Image style={{ height: 80 }} src={settings.GetFileUrl(obj.coverImageFileId)} alt="" /> },
        },
        {
            title: 'Name',
            dataIndex: 'game',
            key: 'game',
            render: function render(gameprofile: Game) { return <GameLink game={gameprofile} /> },
            sorter: (a: UserGame, b: UserGame) => moment(a.game.profileName).unix() - moment(b.game.profileName).unix(),
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Created',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a: UserGame, b: UserGame) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
    ];

    return <>
        <Table columns={columns} dataSource={games} pagination={false} />
    </>
}