import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import NewsApi from '../../api/news-api'
import NewsDetail from '../../components/news/news-detail'
import { useApiContext } from '../../context/api-context'
import { News } from '../../entities/News'

interface LinkedPlatformsPageProps {
   id: string
}

export default function NewsPage({ match }: RouteComponentProps<LinkedPlatformsPageProps>): React.ReactElement {
   const { AuthPending } = useApiContext()
   const [news, setNews] = useState<News>()

   //onMount
   useEffect(() => {
      if (!AuthPending) {
         NewsApi.GetById(match.params.id).then((_news) => setNews(_news.data))
      }
   }, [match, AuthPending])

   async function onSave(_news: News): Promise<boolean> {
      try {
         await NewsApi.Update(_news)
         return true
      } catch {
         return false
      }
   }

   return <>{news && <NewsDetail showDeleteButton={true} onSave={onSave} news={news} />}</>
}
