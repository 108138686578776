import { Button, Image, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { LinkOutlined } from '@ant-design/icons'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { DownloadAsCsv } from '../../helper/DownloadHelper'
import { AiImage } from '../../api/ImageApi'
import noImage from '../../images/noImage.png'

interface ImageListProps {
   images: AiImage[]
}

export default function UserImageList({ images }: ImageListProps): React.ReactElement {
   const types = Array.from(new Set(images))
   const [imagesInView, setCardsInView] = useState<AiImage[]>(images)

   const columns: ColumnsType<AiImage> = [
      {
         title: 'Image',
         key: 'imageUrl',
         dataIndex: 'imageUrl',
         render: (value, image) => (
            <a target="_blank" href={image.imageUrl} rel="noreferrer">
               <Image src={image.imageUrl} width={200} fallback={noImage} />
            </a>
         )
      },
      {
         title: 'Id',
         key: 'alias',
         dataIndex: 'alias',
         render: (value, image) => (
            <a target="_blank" href={image.imageUrl} rel="noreferrer">
               <Link target="_blank" rel="noopener noreferrer" to={`/image/${image.id}`}>
                  {image.id}
               </Link>
            </a>
         )
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         defaultSortOrder: 'descend',
         sorter: (a: AiImage, b: AiImage) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         //sorter: (a: AiImage, b: AiImage) => moment(a.lastModifyDate).unix() - moment(b.lastModifyDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id'
      }
   ]

   return (
      <>
         <Button type="primary" onClick={() => DownloadAsCsv('images', imagesInView)}>
            Export Result
         </Button>
         <Table columns={columns} dataSource={images} onChange={(a, c, d, e) => setCardsInView(e.currentDataSource)} />
      </>
   )
}
