import React, { useEffect, useState } from 'react'
import { useApiContext } from '../context/api-context'
import Settings from '../settings'

export default function HangfireDashboardPage(): React.ReactElement {
   const [mounted, setMounted] = useState<boolean>(false)
   const { token } = useApiContext()
   useEffect(() => {
      document.cookie = `HangFireCookie=${token?.value};Path=/`
      setMounted(true)
      return () => {
         document.cookie = 'HangFireCookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      }
   }, [])

   return (
      <div style={{ height: '99%' }}>{mounted && <iframe src={Settings.HANGFIRE_URL} style={{ height: '100%', width: '100%' }} frameBorder="0"></iframe>}</div>
   )
}
