import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import TransactionApi, { PrintOrder } from '../../api/TransactionApi'
import OrderDetail from '../../components/orders/order-detail'

interface OrderPageProps {
   transactionReference: string
}

export default function OrderPage({ match }: RouteComponentProps<OrderPageProps>): React.ReactElement {
   const [order, setOrder] = useState<PrintOrder>()
   const history = useHistory()

   async function fetch() {
      const result = await TransactionApi.GetOrders()

      setOrder(result.data.find((x) => x.transaction.paymentId == match.params.transactionReference))
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [match])

   async function onSave(printOrder: PrintOrder): Promise<boolean> {
      try {
         const response = await TransactionApi.UpdateOrder(printOrder)
         history.push('/orders/' + order?.transaction.paymentId)
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!order && <Skeleton active />}
         {order && <OrderDetail onSave={onSave} showDeleteButton onRefresh={fetch} order={order} />}
      </>
   )
}
