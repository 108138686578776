import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import TagApi from '../../api/tags-api'
import TagDetail from '../../components/tags/tag-detail'
import { Tag } from '../../entities/Tag'

export default function TagsNewPage(): React.ReactElement {
   const [tag] = useState<Tag>({} as Tag)
   const history = useHistory()

   async function onSave(tag: Tag): Promise<boolean> {
      try {
         const result = await TagApi.CreateTag(tag)
         history.push('/tags-all')
         return result.status === 200
      } catch {
         return false
      }
   }

   return <>{tag && <TagDetail showDeleteButton={false} onSave={onSave} tagData={tag} />}</>
}
