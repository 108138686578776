import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import TagApi from '../../api/tags-api'
import ProfileTagsList from '../../components/tags/profile-tags-list'
import { ETagType, Tag } from '../../entities/Tag'

export default function ProfileTagsAllPage(): React.ReactElement {
   const [tags, setTags] = useState<Tag[]>()

   async function fetch() {
      const result = await TagApi.GetTags([ETagType.Interests, ETagType.Mainprofile, ETagType.Device, ETagType.Purpose])
      if (result.status !== 200) {
         message.error(`response ${result.statusText} (${result.status})`)
         return
      }
      setTags(result.data)
   }

   useEffect(() => {
      fetch()
   }, [])

   return <>{tags && <ProfileTagsList tags={tags} />}</>
}
