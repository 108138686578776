import React, { useEffect, useState } from 'react'
import AiImageApi, { ForbiddenWord } from '../../api/ImageApi'
import { Button, Card, Input, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { TableParams } from '../../components/images/image-list'

export default function ImagesForbiddenWordsPage(): React.ReactElement {
   const [forbiddenWords, setForbiddenWords] = useState<ForbiddenWord[]>()
   const [newWord, setNewWord] = useState('')
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 100
      }
   })

   async function fetch() {
      const response = await AiImageApi.GetForbiddenWords()

      setForbiddenWords(response.data)
   }

   async function addForbiddenWord() {
      const response = await AiImageApi.AddForbiddenWord(newWord)

      setForbiddenWords(response.data)
      setNewWord('')
   }
   async function removeForbiddenWord(id: string) {
      const response = await AiImageApi.RemoveForbiddenWord(id)

      setForbiddenWords(response.data)
   }

   useEffect(() => {
      fetch()
   }, [])

   const columns: ColumnsType<ForbiddenWord> = [
      {
         title: 'Word',
         key: 'word',
         dataIndex: 'word'
      },
      {
         title: 'Remove',
         render: (value, word) => <Button onClick={() => removeForbiddenWord(word.id)}>Delete</Button>
      }
   ]

   return (
      <Card>
         <Space style={{ width: '100%' }}>
            <Input value={newWord} onChange={(e) => setNewWord(e.target.value)} />
            <Button onClick={addForbiddenWord}>Add</Button>
         </Space>
         <Table columns={columns} dataSource={forbiddenWords} />
      </Card>
   )
}
