import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { EObtainState, ETransactionStatus, Transaction } from '../../entities/Transaction'
import ShopItemLink from '../shop-item/shopItem-link'
import TransactionLink from '../transaction/transaction-link'
import UserLink from '../user/user-link'

interface Props {
   transactions: Transaction[]
}

export default function UserTransactionList({ transactions }: Props): React.ReactElement {
   const columns: ColumnsType<Transaction> = [
      {
         title: 'Id',
         key: 'id',
         render: function render(transaction: Transaction) {
            return <TransactionLink transaction={transaction} />
         }
      },
      {
         title: 'ShopItem',
         key: 'shopItem',
         render: function render(transaction: Transaction) {
            return <ShopItemLink shopItem={transaction.shopItem} />
         }
      },
      {
         title: 'Status',
         dataIndex: 'status',
         key: 'status',
         render: (value: number) => ETransactionStatus[value]
      },
      {
         title: 'Price',
         key: 'price',
         render: (transaction: Transaction) => `${transaction.price.value} ${transaction.price.currency}`
      },
      {
         title: 'Buyer',
         key: 'owner',
         render: (transaction: Transaction) => <UserLink user={transaction.owner} />
      },
      {
         title: 'ObtainItemState',
         dataIndex: 'obtainItemState',
         key: 'obtainItemState',
         render: (value: number) => EObtainState[value]
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         defaultSortOrder: 'descend',
         sorter: (a: Transaction, b: Transaction) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         sorter: (a: Transaction, b: Transaction) => moment(a.lastModifyDate).unix() - moment(b.lastModifyDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      }
   ]

   return (
      <>
         <Table columns={columns} dataSource={transactions} />
      </>
   )
}