import { Radio } from "antd";
import React from "react";

interface LanguagePickerProps {
    value: string | undefined
    onChange(value: string): void
}

export default function LanguagePicker({ value, onChange }: LanguagePickerProps): React.ReactElement {
    if (value === undefined) {
        onChange("en");
    }

    return (
        <Radio.Group value={value} onChange={(value) => onChange(value.target.value)}>
            <Radio.Button value="de">DE</Radio.Button>
            <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>)
}
