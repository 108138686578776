import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import PlatformApi from '../../api/api-platform'
import LinkedPlatformsDetail from '../../components/linkedplatforms/linkedplatforms-detail'
import { LinkedPlatformSchema } from '../../entities/LinkedPlatformSchema'

interface LinkedPlatformsPageProps {
   id: string
}

export default function LinkedPlatformPage({ match }: RouteComponentProps<LinkedPlatformsPageProps>): React.ReactElement {
   const [linkedplatform, setPlatform] = useState<LinkedPlatformSchema>()

   //onMount
   useEffect(() => {
      PlatformApi.GetPlatformSchema(match.params.id).then((profile) => setPlatform(profile.data))
   }, [match])

   async function onSave(LinkedPlatform: LinkedPlatformSchema): Promise<boolean> {
      try {
         await PlatformApi.PutPlatformSchema(LinkedPlatform)
         return true
      } catch {
         return false
      }
   }

   return <>{linkedplatform && <LinkedPlatformsDetail showDeleteButton={true} onSave={onSave} platform={linkedplatform} />}</>
}
