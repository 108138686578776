import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import GameApi from '../../api/game-api'
import LfgList from '../../components/lfgs/lfg-all-list'
import { Game } from '../../entities/Game'

interface GameLfgProps {
   gameId: string
}

export default function GameLfgPage({ match }: RouteComponentProps<GameLfgProps>): React.ReactElement {
   const [game, setGame] = useState<Game>()

   async function fetchGame() {
      const gameResponse = await GameApi.GetGame(match.params.gameId)

      if (gameResponse.status !== 200) {
         console.error(gameResponse.statusText)
         return
      }

      setGame(gameResponse.data)
   }

   useEffect(() => {
      fetchGame()
   }, [])

   if (!game) return <Spin />

   return (
      <>
         <h1>
            LFG Entries of <strong>{game?.profileName}</strong>
         </h1>
         <LfgList gameId={game?.id} />
      </>
   )
}
