import React, { ReactElement, useEffect, useState } from 'react'
import settings from '../settings'
import axios from 'axios'
import AuthAPI from '../api/api-token'
import moment from 'moment'
import useLocalStorage from '../hooks/useLocalStorage'

const ApiContext = React.createContext<ApiValues | undefined>(undefined)

interface ApiValues {
   IsAuthorized: boolean
   AuthPending: boolean
   token: AccessToken | undefined
   Login(mail: string, pw: string): Promise<boolean>
   Logout(): Promise<boolean>
}

interface ApiContextProviderProps {
   children: ReactElement
}

interface AccessToken {
   value: string
   expire: Date
}

axios.defaults.baseURL = settings.API_URL

export function ApiContextProvider({ children }: ApiContextProviderProps): React.ReactElement {
   const [token, setTokenObj] = useLocalStorage<AccessToken | undefined>('token_v2')
   const [authPending, setAuthPending] = useState<boolean>(true)

   const isAuthorized = token !== undefined && token?.value != null && moment(token.expire) > moment()

   axios.defaults.headers.common['Authorization'] = isAuthorized ? `Bearer ${token.value}` : ''

   const apiValue: ApiValues = {
      IsAuthorized: isAuthorized,
      AuthPending: authPending,
      token,
      Login: Login,
      Logout: Logout
   }

   //checks if token is still valid
   useEffect(() => {
      //RefreshToken()
   }, [])

   //Refresh Token
   useEffect(() => {
      if (!token) {
         setAuthPending(false)
      } else {
         if (moment(token.expire).isBefore(moment.now())) {
            RefreshToken()
         } else {
            setAuthPending(false)
         }
      }
   }, [token])

   function setToken(_token: string | undefined) {
      setTokenObj(
         _token
            ? {
                 value: _token,
                 expire: moment().add(525960, 'minutes').toDate()
              }
            : undefined
      )
   }

   async function RefreshToken() {
      const response = await AuthAPI.refreshToken()
      if (response.status !== 200) {
         setToken(undefined)
      } else {
         const _token = response.data.token
         setToken(_token)
      }
      setAuthPending(false)
   }

   async function Login(mail: string, pw: string): Promise<boolean> {
      setAuthPending(true)

      try {
         const response = await AuthAPI.login(mail, pw)
         const _token = response?.data?.token

         if (_token !== undefined) {
            setToken(_token)
            axios.defaults.headers.common['Authorization'] = `Bearer ${_token}`
            return true
         }

         return false
      } catch (e) {
         console.error(e)
         return false
      } finally {
         setAuthPending(false)
      }
   }

   async function Logout(): Promise<boolean> {
      try {
         setToken(undefined)
         const response = await AuthAPI.revokeToken()
         return response.status === 200
      } catch (e) {
         console.error(e)
         return false
      }
   }

   return <ApiContext.Provider value={apiValue}>{children}</ApiContext.Provider>
}

export const useApiContext = (): ApiValues => React.useContext(ApiContext) as ApiValues
