import React, { useState } from 'react'
import { Modal, Button, Descriptions, Card, Input, Form, Alert, message, InputNumber, Checkbox } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { ShopItem } from '../../entities/ShopItem'
import ShopItemApi from '../../api/ShopItemApi'
import ItemList from '../item/item-list'

interface Props {
   shopItem: ShopItem
   onSave(shopItem: ShopItem): Promise<boolean>
   showDeleteButton: boolean
}

export default function ShopItemDetail({ onSave, showDeleteButton, shopItem }: Props): React.ReactElement {
   const history = useHistory()

   const [isChanged, setIsChange] = useState<boolean>(false)
   const [editShopItem, setEditShopItem] = useState<ShopItem>(shopItem)

   const [deleteActive, setDeleteActive] = useState(false)

   function onReset() {
      setIsChange(false)
      setEditShopItem(shopItem)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(editShopItem).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   async function onDelete() {
      setDeleteActive(false)

      if (shopItem.id) {
         try {
            const response = await ShopItemApi.Delete(shopItem.id)
            if (response.status == 200) {
               message.success('Deleted!')
               history.push('/shopItem-all')
               return true
            }

            message.error(`Error response status ${response.statusText} (${response.status})`)
         } catch {
            return false
         }
      }

      return false
   }

   function onChange(_shopItem: ShopItem) {
      setIsChange(true)
      setEditShopItem(_shopItem)
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}

         <Modal title={'Delete ' + editShopItem.name + '?'} visible={deleteActive} onOk={onDelete} onCancel={() => setDeleteActive(false)}>
            <p>Do you REALLY want to DELETE this shopItem? This can not be reversed! Deleted objects are gone FOREVEEEEEEER!!</p>
         </Modal>

         <Descriptions
            column={1}
            title={editShopItem.name}
            extra={showDeleteButton && <DeleteOutlined style={{ color: 'red', fontSize: '42px' }} onClick={() => setDeleteActive(true)} />}>
            <Descriptions.Item label="Id" labelStyle={{ alignItems: 'center' }}>
               {editShopItem.id}
            </Descriptions.Item>
            <Descriptions.Item label="Name" labelStyle={{ alignItems: 'center' }}>
               <Input name="name" value={editShopItem.name} onChange={(value) => onChange({ ...editShopItem, name: value.target.value })} />
            </Descriptions.Item>
            <Descriptions.Item label="Currency" labelStyle={{ alignItems: 'center' }}>
               <Input
                  name="currency"
                  value={editShopItem.price.currency}
                  onChange={(event) => onChange({ ...editShopItem, price: { ...editShopItem.price, currency: event.target.value } })}
               />
            </Descriptions.Item>
            <Descriptions.Item label="Price" labelStyle={{ alignItems: 'center' }}>
               <InputNumber
                  name="price"
                  value={editShopItem.price.value}
                  onChange={(value) => onChange({ ...editShopItem, price: { ...editShopItem.price, value } })}
               />
            </Descriptions.Item>
            <Descriptions.Item label="Disabled" labelStyle={{ alignItems: 'center' }}>
               <Checkbox name="disabled" value={editShopItem.disabled} onChange={(event) => onChange({ ...editShopItem, disabled: event.target.checked })} />
            </Descriptions.Item>
            <ItemList items={editShopItem.items} />
         </Descriptions>
      </Card>
   )
}
