import { Tabs, Button } from 'antd'
import React from 'react'
import Selection from '../../../entities/Selection'
import { GetTranslation } from '../../../entities/Translation'
import EntityHelper from '../../../helper/EntityHelper'
import GameSelectionTab from './games-selection-tab'

const { TabPane } = Tabs

interface SelectionTreeProps {
   selections: Selection[]
   languageCode: string
   onChange(targetSelections: Selection[]): void
}

export default function GameSelectionList({ selections, languageCode, onChange }: SelectionTreeProps): React.ReactElement {
   const operations = <Button onClick={onAddSelection}>Add</Button>

   function onSelectionChange(targetSelection: Selection, index: number) {
      const editSelections = Array.from(selections)
      editSelections[index] = targetSelection
      onChange(editSelections)
   }

   function onAddSelection() {
      const newSelection: Selection = {
         name: {
            key: EntityHelper.generateUUID(),
            translations: [
               { languageCode: 'en', value: 'New Selection' },
               { languageCode: 'de', value: 'Neue Selektion' }
            ]
         },
         matchBehavior: 0,
         weight: 1,
         multiselectionAllowed: false
      }
      onChange(selections ? selections.concat(newSelection) : [newSelection])
   }

   function onRemoveSelection(index: number) {
      let editSelections = Array.from(selections) as Selection[]

      if (editSelections === undefined) {
         editSelections = []
      } else {
         editSelections.splice(index, 1)
      }

      onChange(editSelections)
   }

   return (
      <div>
         <Tabs tabBarExtraContent={operations}>
            {selections?.length >= 1 &&
               selections.map((selection, index) => (
                  <TabPane tab={GetTranslation(languageCode, selection.name)} key={index}>
                     <GameSelectionTab
                        languageCode={languageCode}
                        selection={selection}
                        onChange={(value) => onSelectionChange(value, index)}
                        onRemove={() => onRemoveSelection(index)}
                     />
                  </TabPane>
               ))}
         </Tabs>
      </div>
   )
}
