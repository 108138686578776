import React, { useEffect, useState } from "react";
import { message } from "antd";
import { ShopItem } from "../../entities/ShopItem";
import ShopItemApi from "../../api/ShopItemApi";
import ShopItemList from "../../components/shop-item/shopItem-list";

export default function ShopItemAllPage(): React.ReactElement {
    const [shopItems, setShopItems] = useState<ShopItem[]>();

    async function fetch() {
        const result = await ShopItemApi.GetAll()
        if (result.status !== 200) {
            message.error(`response ${result.statusText} (${result.status})`)
            return;
        }
        setShopItems(result.data)
    }

    //onMount
    useEffect(() => {
        fetch()
    }, []);

    return <>
        {shopItems && <ShopItemList shopItems={shopItems} />}
    </>
}
