import axios, { AxiosResponse } from 'axios'
import { LfgEntry } from '../entities/LfgEntry'
import PageList from '../entities/PageList'

async function GetLfgEntries(gameId?: string, PageSize?: number, PageNumber?: number): Promise<PageList<LfgEntry>> {
   const response = await axios.get<LfgEntry[]>(`/api/admin/lfg`, { params: { gameId, PageSize, PageNumber } })

   const pageInfo = JSON.parse(response.headers['paging-headers'])
   return { items: response.data, pageInfo }
}

const LfgApi = {
   GetLfgEntries
}

export default LfgApi
