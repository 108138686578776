import React from "react";
import "./App.css";
import Login from "./pages/login-page";
import { useApiContext } from "./context/api-context";
import { Route } from "react-router";
import MasterLayout from "./layout/master-layout";
import { Spin } from "antd";

function App(): React.ReactElement {
  const { IsAuthorized, AuthPending } = useApiContext();

  return (
    <Route>
      {
        AuthPending ? <Spin size="large"/> : !IsAuthorized ? <Login /> : <MasterLayout /> 
      }
    </Route>
  );
  /*return (
     <div className="App">
       <header className="App-header">
         <img src={logo} className="App-logo" alt="logo" />
         <p>
           Edit <code>src/App.tsx</code> and save to reload.
         </p>
         <a
           className="App-link"
           href="https://reactjs.org"
           target="_blank"
           rel="noopener noreferrer"
         >
           Learn React
         </a>
       </header>
     </div>
   );*/
}

export default App;
