import { ECardType } from "./ReigniteCard";
import { ShopItem } from "./ShopItem";

export interface Item {
  name: string;
  type: EItemType;
  value: number;
  cardType: ECardType | null;
  category: null | string;
  alias: null | string;
  owners: null;
  shopItems: ShopItem[];
  createdDate: Date;
  lastModifyDate: Date;
  ownerId: null;
  owner: null;
  id: string;
}

export enum EItemType
{
    Custom = 0,
    GemCharge = 1,
    SingleClaim = 2,
    AddCardSlots = 3,
}