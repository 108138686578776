import { message, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import TagApi from '../../api/tags-api'
import TagDetail from '../../components/tags/tag-detail'
import { Tag } from '../../entities/Tag'

interface TagPageProps {
   id: string
}

export default function TagPage({ match }: RouteComponentProps<TagPageProps>): React.ReactElement {
   const [tag, setTag] = useState<Tag>()

   async function fetch() {
      const response = await TagApi.GetTag(match.params.id)
      if (response.status !== 200) {
         message.error(`response ${response.statusText} (${response.status})`)
         return
      }
      setTag(response.data)
   }

   async function onSave(tag: Tag) {
      try {
         const tagResponse = await TagApi.PutTag(tag)
         if (tagResponse.status !== 200) {
            message.error(`response ${tagResponse.statusText} (${tagResponse.status})`)
            return false
         } else {
            setTag(tagResponse.data)
         }
         return true
      } catch {
         return false
      }
   }

   useEffect(() => {
      fetch()
   }, [match])

   return (
      <>
         {!tag && <Spin size="large" />}
         {tag && <TagDetail tagData={tag} onSave={onSave} showDeleteButton={true} />}
      </>
   )
}
