import React, { useEffect, useState } from 'react'
import { EUserRole } from '../../entities/EUserRole'
import { EGender } from '../../entities/EGender'
import { ReigniteUser } from '../../entities/ReigniteUser'
import { Card, Col, Descriptions, Image, Divider, Row, Input, message, Button, Space, InputNumber } from 'antd'
import moment from 'moment'
import noImage from '../../images/noImage.png'
import UserList from './user-list'
import FriendrequestList from '../friendrequest/friendrequest-list'
import UserLinkedPlatformsList from '../linkedplatforms/user-linkedplatforms-list'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import settings from '../../settings'
import { DeleteOutlined, EyeInvisibleOutlined, FrownOutlined, SmileOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import UserApi from '../../api/api-user'
import UserGamesList from '../games/user-games-list'
import LfgApi from '../../api/lfg-api'
import { LfgEntry } from '../../entities/LfgEntry'
import UserCardList from './user-cards'
import ProfileTagsList from '../tags/profile-tags-list'
import UserLfgList from '../lfgs/user-lfg-list'
import UserTransactionList from './user-transactions'
import UserImageList from './user-images'
import AiImageApi, { AiImage } from '../../api/ImageApi'

interface UserPageProps {
   user: ReigniteUser
   onSave(ReigniteUser: ReigniteUser): Promise<boolean>
   onRefresh?(): void
   showDeleteButton: boolean
}

export default function UserDetail({
   showDeleteButton,
   onSave,
   onRefresh = () => {
      return
   },
   user
}: UserPageProps): React.ReactElement {
   const [username, setUsername] = useState(user.nickname)
   const [usernameHash, setUsernameHash] = useState(user.nicknameHash)
   const [mail, setMail] = useState(user.mailAdress)
   const [password] = useState(user.password)
   const [role, setRole] = useState(user.userRole)
   const [firesoulGems, setFireSoulGems] = useState(user.fireSoulGems)

   const [firstName, setFirstName] = useState(user.contact.firstname)
   const [lastName, setLastName] = useState(user.contact.lastname)
   const [birthday, setBirthday] = useState(user.contact.birthday)
   const [age, setAge] = useState(user.contact.age)
   const [gender, setGender] = useState(user.contact.gender)
   const [country, setCountry] = useState(user.contact.country)
   const [mainLanguage, setMainLanguage] = useState(user.contact.mainLanguage)
   const [languages, setLanguages] = useState(user.contact.languages)

   const [communication, setCommuncation] = useState(user.mainProfile.communication)
   const [humour, setHumour] = useState(user.mainProfile.humour)
   const [mindset, setMindset] = useState(user.mainProfile.mindset)
   const [teamplay, setTeamplay] = useState(user.mainProfile.teamplay)
   const [tactical, setTactical] = useState(user.mainProfile.tactical)
   const [teamLead, setTeamLead] = useState(user.mainProfile.teamLead)
   const [voiceChat, setVoiceChat] = useState(user.mainProfile.voiceChat)
   const [biography, setBiography] = useState(user.mainProfile.biography)
   const [lfgEntries, setLfgEntries] = useState<LfgEntry[]>(user.lfgEntries)
   const [aiImages, setAiImages] = useState<AiImage[]>()
   const [filterActive, setFilterActive] = useState(true)

   const history = useHistory()

   async function onDelete() {
      if (user.id && confirm('Do you want to DELETE USER?')) {
         try {
            const success = await UserApi.DeleteReigniteUser(user.id)
            if (success) {
               message.success(`${user.nickname}#${user.nicknameHash} deleted!`)
               history.push('/user-all')
            } else {
               throw new Error('unsuccessfuly delete')
            }
         } catch (er) {
            console.error(er)
            message.error(`error on deleted!`)
         }
      }
   }

   async function onDeleteImage() {
      if (user.id && confirm('Do you want to DELETE IMAGE?')) {
         try {
            const success = await UserApi.DeleteUserImage(user.id)
            if (success) {
               message.success(`${user.nickname}#${user.nicknameHash} image-deleted!`)
               onRefresh()
            } else {
               throw new Error('unsuccessfuly')
            }
         } catch (er) {
            console.error(er)
            message.error(`error on image-delete!`)
         }
      }
   }

   async function onBann() {
      if (user.id && confirm('Do you want to BANN USER?')) {
         try {
            const success = await UserApi.BannUser(user.id)
            if (success) {
               message.success(`${user.nickname}#${user.nicknameHash} banned!`)
               onRefresh()
            } else {
               throw new Error('unsuccessfuly')
            }
         } catch (er) {
            console.error(er)
            message.error(`error on bann!`)
         }
      }
   }

   async function onUnbann() {
      if (user.id) {
         try {
            const success = await UserApi.UnbannUser(user.id)
            if (success) {
               message.success(`${user.nickname}#${user.nicknameHash} deleted!`)
               onRefresh()
            } else {
               throw new Error('unsuccessfuly')
            }
         } catch (er) {
            console.error(er)
            message.error(`error on unbann!`)
         }
      }
   }

   async function fetch() {
      const response = await AiImageApi.GetImagesOfUser(user.id)

      setAiImages(response.data)
   }

   useEffect(() => {
      fetch()
   }, [])

   return (
      <Card>
         {showDeleteButton && (
            <Space style={{ marginBottom: '5px' }}>
               <Button type="primary" danger icon={<DeleteOutlined />} onClick={onDelete}>
                  Delete User
               </Button>
               {user.profilePictureFileId && (
                  <Button danger icon={<EyeInvisibleOutlined />} onClick={onDeleteImage}>
                     Remove User-Image
                  </Button>
               )}
               {user.userRole >= EUserRole.User && (
                  <Button danger icon={<FrownOutlined />} onClick={onBann}>
                     BANN
                  </Button>
               )}
               {user.userRole === EUserRole.Blocked && (
                  <Button type="primary" icon={<SmileOutlined />} onClick={onUnbann}>
                     UNBANN
                  </Button>
               )}
            </Space>
         )}
         <Row>
            <Col flex="200px">
               <Image src={settings.GetFileUrl(user.profilePictureFileId)} width={180} fallback={noImage} />
            </Col>
            <Col flex="1">
               <Descriptions labelStyle={{ width: '20%' }} title={'User Info'} bordered column={1} extra={user.id}>
                  <Descriptions.Item label="Name" labelStyle={{ alignItems: 'center' }}>
                     {username}#{usernameHash}
                  </Descriptions.Item>
                  <Descriptions.Item label="Mail" labelStyle={{ alignItems: 'center' }}>
                     {mail}
                  </Descriptions.Item>
                  <Descriptions.Item label="Password Set" labelStyle={{ alignItems: 'center' }}>
                     <Checkbox checked={password !== null ? true : false} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Role" labelStyle={{ alignItems: 'center' }}>
                     {EUserRole[role]}
                  </Descriptions.Item>
                  <Descriptions.Item label="Firesoul Gems" labelStyle={{ alignItems: 'center' }}>
                     <InputNumber value={firesoulGems} onChange={(value) => setFireSoulGems(value)} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Images Count" labelStyle={{ alignItems: 'center' }}>
                     {aiImages?.length}
                  </Descriptions.Item>
                  <Descriptions.Item label="Bought Transactions Count" labelStyle={{ alignItems: 'center' }}>
                     {user.transactions.filter((x) => x.price.currency == null).length}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="CardSlots" labelStyle={{ alignItems: 'center' }}>
                     <InputNumber value={user.cardSlots} />
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Created" labelStyle={{ alignItems: 'center' }}>
                     {moment(user.userState?.createdDate).format('ll | HH:mm A')}
                  </Descriptions.Item>
                  <Descriptions.Item label="LastModified" labelStyle={{ alignItems: 'center' }}>
                     {moment(user.userState?.lastOnlineDate).format('ll | HH:mm A')}
                  </Descriptions.Item>
               </Descriptions>
            </Col>
         </Row>
         <Divider />

         {aiImages && (
            <>
               <h3>
                  <strong>AI Images</strong>
               </h3>
               <UserImageList images={aiImages} />
               <Divider />
            </>
         )}

         <h3>
            <strong>Cards</strong>
         </h3>
         <UserCardList cards={user.cards} />
         <Divider />

         <h3>
            <strong>Transactions</strong>
         </h3>
         <Checkbox checked={filterActive} onChange={(e) => setFilterActive(e.target.checked)}>
            Filter bought Transactions
         </Checkbox>
         <UserTransactionList transactions={filterActive ? user.transactions.filter((x) => x.price.currency == null) : user.transactions} />
         <Divider />

         <Descriptions bordered column={5} title="Contact" extra={user.contact.id}>
            <Descriptions.Item label="Firstname" labelStyle={{ alignItems: 'center' }}>
               <Input value={firstName} onChange={(value) => setFirstName(value.target.value)} />
            </Descriptions.Item>
            <Descriptions.Item label="Lastname" labelStyle={{ alignItems: 'center' }}>
               <Input value={lastName} onChange={(value) => setLastName(value.target.value)} />
            </Descriptions.Item>
            <Descriptions.Item label="Birthday" labelStyle={{ alignItems: 'center' }}>
               {moment(birthday).format('ll')}
            </Descriptions.Item>
            <Descriptions.Item label="Age" labelStyle={{ alignItems: 'center' }}>
               <Input value={age} onChange={(value) => setAge(value.target.valueAsNumber)} />
            </Descriptions.Item>
            <Descriptions.Item label="Gender" labelStyle={{ alignItems: 'center' }}>
               {EGender[gender]}
            </Descriptions.Item>
            <Descriptions.Item label="Country" labelStyle={{ alignItems: 'center' }}>
               <Input value={country} onChange={(value) => setCountry(value.target.value)} />
            </Descriptions.Item>
            <Descriptions.Item label="MainLanguage" labelStyle={{ alignItems: 'center' }}>
               <Input value={mainLanguage} onChange={(value) => setMainLanguage(value.target.value)} />
            </Descriptions.Item>
            <Descriptions.Item label="Languages" labelStyle={{ alignItems: 'center' }}>
               {languages.map((lang) => lang.languageCode).join(', ')}
            </Descriptions.Item>
         </Descriptions>
         <Divider />
         <Descriptions bordered column={5} title="MainProfile" extra={user.mainProfile.id}>
            <Descriptions.Item label="Communication" labelStyle={{ alignItems: 'center' }}>
               <Input value={communication} onChange={(value) => setCommuncation(value.target.valueAsNumber)} />
            </Descriptions.Item>
            <Descriptions.Item label="Tactical" labelStyle={{ alignItems: 'center' }}>
               <Input value={tactical} onChange={(value) => setTactical(value.target.valueAsNumber)} />
            </Descriptions.Item>
            <Descriptions.Item label="Humour" labelStyle={{ alignItems: 'center' }}>
               <Input value={humour} onChange={(value) => setHumour(value.target.valueAsNumber)} />
            </Descriptions.Item>
            <Descriptions.Item label="Mindset" labelStyle={{ alignItems: 'center' }}>
               <Input value={mindset} onChange={(value) => setMindset(value.target.valueAsNumber)} />
            </Descriptions.Item>
            <Descriptions.Item label="Teamplay" labelStyle={{ alignItems: 'center' }}>
               <Input value={teamplay} onChange={(value) => setTeamplay(value.target.valueAsNumber)} />
            </Descriptions.Item>
            <Descriptions.Item label="Teamlead" labelStyle={{ alignItems: 'center' }}>
               <Checkbox checked={teamLead !== null ? true : false} />
            </Descriptions.Item>
            <Descriptions.Item span={4} label="VoiceChat" labelStyle={{ alignItems: 'center' }}>
               <Checkbox checked={voiceChat !== null ? true : false} />
            </Descriptions.Item>
            <Descriptions.Item span={5} label="Biography" labelStyle={{ alignItems: 'center' }}>
               <Input value={biography} onChange={(value) => setBiography(value.target.value)} />
            </Descriptions.Item>
         </Descriptions>
         <Divider />

         <h3>
            <strong>Platformlinks</strong>
         </h3>
         <UserLinkedPlatformsList linkedPlatforms={user.linkedPlatforms} />
         <Divider />

         <h3>
            <strong>Games</strong>
         </h3>
         <UserGamesList games={user.games} />
         <Divider />

         <h3>
            <strong>Tags</strong>
         </h3>
         <ProfileTagsList tags={user.tags} />
         <Divider />

         <h3>
            <strong>LFG Entries</strong>
         </h3>
         <UserLfgList entries={lfgEntries} />
         <Divider />

         <h3>
            <strong>Friendrequests</strong>
         </h3>
         <FriendrequestList friendrequests={[...user.outgoingFriendrequests, ...user.incomingFriendrequests]} owner={user} pagination={false} />
         <Divider />

         <h3>
            <strong>Friends</strong>
         </h3>
         <UserList users={user.friends.map((x) => x.friend)} pagination={false} />
      </Card>
   )
}
