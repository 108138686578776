import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { LinkOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useState } from "react";
import ReigniteCard from "../../entities/ReigniteCard";
import { Link } from "react-router-dom";
import { DownloadAsCsv } from "../../helper/DownloadHelper";

interface CardsListProps {
  cards: ReigniteCard[];
}

export default function UserCardList({
  cards,
}: CardsListProps): React.ReactElement {
  const types = Array.from(new Set(cards.map((x) => x.type?.toString())));
  const [cardsInView, setCardsInView] = useState<ReigniteCard[]>(cards);

  const columns: ColumnsType<ReigniteCard> = [
    {
      title: "Alias",
      dataIndex: "alias",
      render: (value, card) => (
        <a
          target="_blank"
          href={`https://cards.reignite.gg/view/${
            card.type
          }/${encodeURIComponent(card.alias)}`}
          rel="noreferrer"
        >
          {card.alias}
          <LinkOutlined style={{ paddingLeft: "4px" }} />
        </a>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      filters: types.map((filter) => ({
        value: filter,
        text: filter,
      })),
      onFilter: (value, card) => card.type === value,
    },
    {
      title: "Owner",
      filters: [
        { text: "With Owner", value: true },
        { text: "Without Owner", value: false },
      ],
      onFilter: (value, card) => (value === true) === (card.ownerId !== null),
      render: (value, card) =>
        card.ownerId ? (
          <Link to={`/user/${card.ownerId}`}>{card.ownerId}</Link>
        ) : (
          "None"
        ),
    },
    {
      title: "Created",
      dataIndex: "createdDate",
      key: "createdDate",
      defaultSortOrder: "descend",
      sorter: (a: ReigniteCard, b: ReigniteCard) =>
        moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      render: function render(date: Date) {
        return moment(date).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      title: "LastModifyDate",
      dataIndex: "lastModifyDate",
      key: "lastModifyDate",
      sorter: (a: ReigniteCard, b: ReigniteCard) =>
        moment(a.lastModifyDate).unix() - moment(b.lastModifyDate).unix(),
      render: function render(date: Date) {
        return moment(date).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
  ];

  return (
    <>
      <Button
        type="primary"
        onClick={() => DownloadAsCsv("cards", cardsInView)}
      >
        Export Result
      </Button>
      <Table
        columns={columns}
        dataSource={cards}
        onChange={(a, c, d, e) => setCardsInView(e.currentDataSource)}
      />
    </>
  );
}