import axios, { AxiosResponse } from 'axios'
import { ETagType, Tag } from '../entities/Tag'
import { mapObjectToUrlParam } from '../helper/ApiHelper'

function GetTags(types: ETagType[], gameIds?: string): Promise<AxiosResponse<Tag[]>> {
   const params = mapObjectToUrlParam({ types, gameIds })
   return axios.get<Tag[]>(`/api/admin/tag`, { params })
}

function GetTag(id: string): Promise<AxiosResponse<Tag>> {
   return axios.get<Tag>(`/api/admin/tag/${id}`)
}

function PutTag(tag: Tag): Promise<AxiosResponse<Tag>> {
   return axios.put<Tag>(`/api/admin/tag`, tag)
}

function CreateTag(tag: Tag): Promise<AxiosResponse<Tag>> {
   return axios.post<Tag>(`/api/admin/tag`, tag)
}

function DeleteTag(id: string): Promise<AxiosResponse> {
   return axios.delete(`/api/admin/tag/remove/${id}`)
}

const TagApi = {
   GetTags,
   GetTag,
   PutTag,
   CreateTag,
   DeleteTag
}

export default TagApi
