import React, { useState } from 'react'
import { Card, Col, Image, Divider, Row, Descriptions, Checkbox, Button, Input, message, Alert, Form } from 'antd'
import noImage from '../../images/noImage.png'
import settings from '../../settings'
import { Link, useHistory } from 'react-router-dom'
import { AiImage, EUpscaleType, LeagueOfLegendsGenerateData } from '../../api/ImageApi'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import UserLink from '../user/user-link'
import { AiImageCharacter, AiImageCheckpoint, ECheckpointCategory } from '../../api/PromptApi'

interface CheckpointDetailProps {
   checkpoint: AiImageCheckpoint
   onSave(checkpoint: AiImageCheckpoint): Promise<boolean>
   onRefresh?(): void
   showDeleteButton: boolean
}

export default function CheckpointDetail({
   onSave,
   onRefresh = () => {
      return
   },
   checkpoint: _checkpoint
}: CheckpointDetailProps): React.ReactElement {
   const [checkpoint, setCheckpoint] = useState(_checkpoint)

   const [isChanged, setIsChange] = useState<boolean>(false)

   function onReset() {
      setIsChange(false)
      setCheckpoint(_checkpoint)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(checkpoint).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   function onChange(editCheckpoint: AiImageCheckpoint) {
      setIsChange(true)
      setCheckpoint(editCheckpoint)
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}
         {/* {aiImage.isHidden != image.isHidden && <Button onClick={() => onSave(aiImage)}>Save</Button>} */}
         <Row>
            <Col flex="200px">
               <Image src={checkpoint.imageUrl} width={500} fallback={noImage} />
            </Col>
            <Col flex="1">
               <Descriptions title="Checkpoint Info" bordered>
                  <DescriptionsItem span={3} label={'Id'}>
                     {checkpoint.id}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={checkpoint.name} onChange={(e) => onChange({ ...checkpoint, name: e.target.value })} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Description'}>
                     <Input value={checkpoint.description} onChange={(e) => onChange({ ...checkpoint, description: e.target.value })} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'ModelId'}>
                     <Input value={checkpoint.modelId} onChange={(e) => onChange({ ...checkpoint, modelId: e.target.value })} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Tags'}>
                     <Input value={checkpoint.tags} onChange={(e) => onChange({ ...checkpoint, tags: e.target.value })} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'ImageUrl'}>
                     <Input value={checkpoint.imageUrl} onChange={(e) => onChange({ ...checkpoint, imageUrl: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Type'}>
                     {ECheckpointCategory[checkpoint.type]}
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
