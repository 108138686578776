import { Alert, Button, Card, Col, Descriptions, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import AiPromptApi, { AiImageTheme, EThemeCategory } from '../../../api/PromptApi'
import { DEFAULT_AWS_URL_OPTIMIZED } from '../../../api/ImageApi'

export default function ThemeNewPage(): React.ReactElement {
   const history = useHistory()
   const [theme, setTheme] = useState<AiImageTheme>({
      id: undefined,
      name: '',
      imageUrl: DEFAULT_AWS_URL_OPTIMIZED,
      type: EThemeCategory.Anime
   })
   const [isValid, setIsValid] = useState(false)

   const { Option } = Select

   async function onSave(): Promise<boolean> {
      try {
         if (theme == undefined) return false

         const response = await AiPromptApi.AddTheme(theme)
         history.push('/themes/' + response.data.id)
         return true
      } catch {
         return false
      }
   }

   useEffect(() => {
      if (theme.name != '' && theme.imageUrl != '') setIsValid(true)
   }, [theme])

   return (
      <Card>
         {isValid ? (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSave}>
                     Submit
                  </Button>
               </Form.Item>
            </>
         ) : (
            <></>
         )}
         {/* {theme.isHidden != image.isHidden && <Button onClick={() => onSave(theme)}>Save</Button>} */}
         <Row>
            <Col flex="1">
               <Descriptions title="Theme Info" bordered>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={theme.name} onChange={(e) => setTheme({ ...theme, name: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'ImageUrl'}>
                     <Input value={theme.imageUrl} onChange={(e) => setTheme({ ...theme, imageUrl: e.target.value })}></Input>
                     <label>z.B. https://d1nv3961i7vw1y.cloudfront.net/assets/ai-prompts/categories/AttackOnTitan.png</label>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Type'}>
                     <Select style={{ width: '100%' }} value={EThemeCategory[theme.type]} onChange={(e) => setTheme({ ...theme, type: Number.parseInt(e) })}>
                        <Option key={EThemeCategory.Anime}>{'Anime'}</Option>
                        <Option key={EThemeCategory.Games}>{'Games'}</Option>
                     </Select>
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
