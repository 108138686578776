import { Form, Input, Button } from 'antd'
import React, { useState } from 'react'
import UserApi from '../../api/api-user'
import { NewUser } from '../../entities/NewUser'

export default function UserNewPage(): React.ReactElement {
   const [responseText, setResponseText] = useState<string>()

   async function onFinish(user: NewUser) {
      user.nicknameHash = '0'
      try {
         await UserApi.PostUser(user)
         //history.push(`/user/${response.id}`);
         setResponseText('user created successfully')
      } catch (error) {
         //const response = error as Response;
         setResponseText('Already exists or invalid user')
      }
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const onFinishFailed = (errorInfo: any) => {
      console.warn('Failed:', errorInfo)
   }

   return (
      <Form
         name="New_User"
         labelCol={{ span: 2 }}
         wrapperCol={{ span: 16 }}
         initialValues={{ remember: true }}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}>
         <Form.Item label="Nickname" name="nickname" rules={[{ required: true, message: 'Please enter a nickname!' }]}>
            <Input placeholder="Benutzername" />
         </Form.Item>
         <Form.Item
            label="Mail-Adress"
            name="mailAdress"
            rules={[
               { required: true, message: 'Please enter a mail-adress!' },
               { type: 'email', message: 'Please enter a valid mail-adress!' }
            ]}>
            <Input placeholder="Mail-Adress" />
         </Form.Item>
         <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please enter a password!' }]}>
            <Input.Password placeholder="Password" />
         </Form.Item>
         <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
               {
                  required: true,
                  message: 'Please confirm your password!'
               },
               ({ getFieldValue }) => ({
                  validator(_, value) {
                     if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                     }
                     return Promise.reject(new Error('The two passwords that you entered do not match!'))
                  }
               })
            ]}>
            <Input.Password />
         </Form.Item>
         <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
            <Button type="primary" htmlType="submit">
               Submit
            </Button>
            <p style={{ fontSize: 40 }}>{responseText}</p>
         </Form.Item>
      </Form>
   )
}
