import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import AiPromptApi, { AiImageCheckpoint } from '../../../api/PromptApi'
import CheckpointDetail from '../../../components/images/checkpoint-detail'

interface CharacterPageProps {
   id: string
}

export default function CheckpointPage({ match }: RouteComponentProps<CharacterPageProps>): React.ReactElement {
   const [checkpoint, setCheckpoint] = useState<AiImageCheckpoint>()
   const history = useHistory()

   async function fetch() {
      setCheckpoint(undefined)
      const checkpoint = await AiPromptApi.GetCheckpoint(match.params.id)
      setCheckpoint(checkpoint.data)
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [match])

   async function onSave(checkpoint: AiImageCheckpoint): Promise<boolean> {
      try {
         const response = await AiPromptApi.UpdateCheckpoint(checkpoint)
         history.push('/checkpoints/' + checkpoint.id)
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!checkpoint && <Skeleton active />}
         {checkpoint && <CheckpointDetail onSave={onSave} showDeleteButton onRefresh={fetch} checkpoint={checkpoint} />}
      </>
   )
}
