import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import GameApi from '../../api/game-api'
import TagApi from '../../api/tags-api'
import GameTagsList from '../../components/tags/game-tags-list'
import { Game } from '../../entities/Game'
import { ETagType, Tag } from '../../entities/Tag'

interface GameTagsProps {
   gameId: string
}

export default function GameTagsPage({ match }: RouteComponentProps<GameTagsProps>): React.ReactElement {
   const [tags, setTags] = useState<Tag[]>()
   const [game, setGame] = useState<Game>()

   async function fetch() {
      const response = await TagApi.GetTags([ETagType.Game], match.params.gameId)
      const gameResponse = await GameApi.GetGame(match.params.gameId)

      if (response.status !== 200) {
         console.error(response.statusText)
         return
      }

      setTags(response.data)
      setGame(gameResponse.data)
   }

   useEffect(() => {
      fetch()
   }, [])

   if (!tags) return <Spin />

   return (
      <>
         <h1>
            Tags of <strong>{game?.profileName}</strong>
         </h1>
         <GameTagsList tags={tags} />
      </>
   )
}
