import React, { useEffect, useState } from 'react'
import { Descriptions, Input, Divider, Button, List, Checkbox } from 'antd'
import { GameSelectionValue } from '../../../entities/GameSelectionValue'
import Selection from '../../../entities/Selection'
import { GetTranslation, SetTranslation } from '../../../entities/Translation'
import MatchBehaviorOption, { MatchBehaviorOptions } from '../../../entities/MatchBehaviorOption'
import { Select } from 'antd'
import { OptionElement } from '../games-optionelement'
import EntityHelper from '../../../helper/EntityHelper'

const { Option } = Select

interface SelectionTabProps {
   selection: Selection
   languageCode: string
   onChange(targetSchema: Selection): void
   onRemove(): void
}

enum Fields {
   matchBehavior = 0,
   multiselectionAllowed = 1,
   name = 2,
   options = 3,
   weight = 4
}

export default function GameSelectionTab({ languageCode, selection, onRemove, onChange }: SelectionTabProps): React.ReactElement {
   const [currentBehaviorOption, setCurrentBehaviorOption] = useState<MatchBehaviorOption>()
   const [overwriteAllMode, setOverwriteAllMode] = useState<boolean>(false)

   function onValueChange(event: React.ChangeEvent<HTMLInputElement>, field: Fields) {
      const editSelection = { ...selection }
      switch (field) {
         case Fields.name:
            SetTranslation(languageCode, editSelection.name, event.target.value)
            break
         case Fields.matchBehavior:
            editSelection.matchBehavior = event.target.valueAsNumber
            break
         case Fields.weight:
            editSelection.weight = event.target.valueAsNumber
            break
         case Fields.multiselectionAllowed:
            editSelection.multiselectionAllowed = event.target.checked
            break
      }
      onChange(editSelection)
   }

   useEffect(() => {
      const behavior = MatchBehaviorOptions.find((x) => x.key === selection.matchBehavior)
      setCurrentBehaviorOption(behavior)
   }, [selection.matchBehavior])

   function onMatchBehaviorChange(value: number) {
      const editSelection = { ...selection }
      editSelection.matchBehavior = value
      onChange(editSelection)
   }

   function onOptionChange(targetOption: GameSelectionValue, index: number) {
      const editSelection = { ...selection }
      if (editSelection.options === undefined) {
         throw new Error('Option is undefined!')
      }
      editSelection.options[index] = targetOption
      onChange(editSelection)
   }

   function onAddOption() {
      const editSelection = { ...selection }
      if (editSelection.options === undefined) {
         editSelection.options = []
      }
      const newOption: GameSelectionValue = {
         name: {
            key: EntityHelper.generateUUID(),
            translations: [
               { languageCode: 'en', value: 'New Option' },
               { languageCode: 'de', value: 'Neue Option' }
            ]
         },
         nameKey: 'New_Option',
         orderPos: -1,
         id: undefined,
         selectionId: selection.id
      }
      editSelection.options?.push(newOption)
      onChange(editSelection)
   }

   function onRemoveOptionValue(index: number) {
      const editSelection = { ...selection }

      if (editSelection.options === undefined) {
         editSelection.options = []
      } else {
         editSelection.options.splice(index, 1)
      }

      onChange(editSelection)
   }

   return (
      <div>
         <Descriptions
            column={2}
            title="Selection"
            extra={
               <>
                  {selection.id}
                  <Button onClick={onRemove}>Remove</Button>
               </>
            }>
            <Descriptions.Item label="Name" labelStyle={{ alignItems: 'center', margin: '5px' }}>
               <Input
                  name="Name"
                  style={{ width: '50%' }}
                  value={GetTranslation(languageCode, selection.name)}
                  onChange={(value) => onValueChange(value, Fields.name)}
               />
            </Descriptions.Item>
            <Descriptions.Item label="MatchBehaviour" labelStyle={{ alignItems: 'start', margin: '5px' }}>
               <div>
                  <Select style={{ width: '250px' }} value={selection.matchBehavior} onChange={onMatchBehaviorChange}>
                     {MatchBehaviorOptions.map((option) => (
                        <Option value={option.key} key={option.key}>
                           {option.value}
                        </Option>
                     ))}
                  </Select>
                  {currentBehaviorOption && <div style={{ width: '100%', color: 'darkblue', fontStyle: 'oblique' }}>*{currentBehaviorOption.discription}</div>}
               </div>
            </Descriptions.Item>
            <Descriptions.Item label="Weight" labelStyle={{ alignItems: 'center', margin: '5px' }}>
               <Input name="weight" type="number" style={{ width: '50%' }} value={selection.weight} onChange={(value) => onValueChange(value, Fields.weight)} />
            </Descriptions.Item>
            <Descriptions.Item label="MultiSelectionAllowed" labelStyle={{ alignItems: 'center', margin: '5px' }}>
               <Input
                  name="multiselectionAllowed"
                  style={{ width: '20px', verticalAlign: 'center' }}
                  type="checkbox"
                  checked={selection.multiselectionAllowed ? true : false}
                  onChange={(value) => onValueChange(value, Fields.multiselectionAllowed)}
               />
            </Descriptions.Item>
         </Descriptions>
         <Divider />
         <List>
            <List.Item>
               <label>Name</label>
               <label>OrderPos</label>
               <label>NameKey</label>
               <label>Id</label>
            </List.Item>
            {selection.options
               ?.sort((a, b) => a.orderPos - b.orderPos)
               .map((option, index) => (
                  <OptionElement
                     overwriteAllMode={overwriteAllMode}
                     option={option}
                     languageCode={languageCode}
                     key={index}
                     onChange={(value) => onOptionChange(value, index)}
                     onRemove={() => onRemoveOptionValue(index)}
                  />
               ))}
         </List>
         <Checkbox checked={overwriteAllMode} onChange={(e) => setOverwriteAllMode(e.target.checked)}>
            Overwrite All
         </Checkbox>
         <Button onClick={onAddOption}>Add</Button>
      </div>
   )
}
