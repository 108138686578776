import { Button, Input, Table, Image, Checkbox } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DownloadAsCsv } from '../../helper/DownloadHelper'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import UserLink from '../user/user-link'
import AiImageApi, { AiImage } from '../../api/ImageApi'
import { Link, useHistory } from 'react-router-dom'
import noImage from '../../images/noImage.png'
import { ReigniteUser } from '../../entities/ReigniteUser'

export interface TableParams {
   pagination?: TablePaginationConfig
   sortField?: string
   sortOrder?: string
   filters?: Record<string, FilterValue | null>
}

export interface CheckImage {
   id: string
   imageUrl: string
   userPrompt: string
   owner: ReigniteUser
   isPublic: boolean
}

export default function ImageCurateList(): React.ReactElement {
   const history = useHistory()
   const [data, setData] = useState<CheckImage[]>([])
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch()
   }, [])

   async function fetch() {
      setLoading(true)
      const result = await AiImageApi.GetRawImages()

      const images = result.data

      const rawData: CheckImage[] = images.map((x) => {
         return { id: x.id, imageUrl: x.imageUrl, isPublic: false, userPrompt: x.userPrompt, owner: x.owner }
      })

      setData(rawData)

      setLoading(false)
   }

   async function onSave() {
      if (data) {
         const saveData = data?.map((x) => {
            return { id: x.id, isPublic: x.isPublic }
         })
         await AiImageApi.CurateImages(saveData)
         history.go(0)
      }
   }

   function update(id: string, updatedData: Partial<CheckImage>) {
      setData(data.map((item) => (item.id === id ? { ...item, ...updatedData } : item)))
   }

   const columns: ColumnsType<CheckImage> = [
      {
         title: 'Image',
         key: 'imageUrl',
         dataIndex: 'imageUrl',
         render: (value, image) => <Image src={image.imageUrl} width={200} fallback={noImage} />
      },
      {
         title: 'Prompt',
         key: 'userPrompt',
         dataIndex: 'userPrompt'
      },
      {
         title: 'Id',
         key: 'alias',
         dataIndex: 'alias',
         render: (value, image) => (
            <Link target="_blank" rel="noopener noreferrer" to={`/image/${image.id}`}>
               {image.id}
            </Link>
         )
      },
      {
         title: 'Owner',
         key: 'owner',
         render: (value, image) => (image.owner ? <UserLink user={image.owner} /> : 'None')
      },
      {
         title: 'Show in Gallery',
         render: (value, image) => <Checkbox checked={image.isPublic} onChange={(e) => update(image.id, { isPublic: e.target.checked })} />
      }
   ]

   return (
      <>
         <Table columns={columns} pagination={tableParams.pagination} loading={loading} dataSource={data} />
         <Button onClick={onSave}>Save</Button>
      </>
   )
}
