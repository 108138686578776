import axios from "axios";

export interface AuthResponse {
  id: string;
  nickname: string;
  nicknameHash: string;
  token: string;
}

const login = (MailAdress: string, Password: string) =>
  axios.post<AuthResponse>(
    `/api/token`,
    { MailAdress, Password },
    { validateStatus: () => true, withCredentials: true }
  );

const revokeToken = () =>
  axios.post<string>(`/api/token/revoke`, undefined, {
    withCredentials: true,
  });

const refreshToken = () =>
  axios
    .post<AuthResponse>(`/api/token/refresh`, undefined, {
      withCredentials: true,
    })
    .catch((error) => {
      return error;
    });

const AuthAPI = {
  login,
  refreshToken,
  revokeToken,
};

export default AuthAPI;
