import axios, { AxiosResponse } from 'axios'
import { Game } from '../entities/Game'
import PageList from '../entities/PageList'

async function GetGames(q?: string, PageSize?: number, PageNumber?: number): Promise<PageList<Game>> {
   const response = await axios.get<Game[]>(`/api/admin/game`, { params: { q, PageSize, PageNumber } })

   const pageInfo = JSON.parse(response.headers['paging-headers'])
   return { items: response.data, pageInfo }
}

function GetGame(id: string): Promise<AxiosResponse<Game>> {
   return axios.get<Game>(`/api/admin/game/${id}`)
}

function PutGame(gameprofile: Game): Promise<AxiosResponse<Game>> {
   return axios.put<Game>(`/api/admin/game`, gameprofile)
}

function PostGame(gameprofile: Game): Promise<AxiosResponse<Game>> {
   return axios.post<Game>(`/api/admin/game`, gameprofile)
}

function AddIgdbGame(igdbId: string): Promise<AxiosResponse<Game>> {
   return axios.post<Game>(`/api/admin/game/igdb/import/${igdbId}`)
}

function DeleteGame(id: string): Promise<boolean> {
   return axios.delete(`/api/admin/game/${id}`)
}

function PostCoverImage(gameId: string, imageFile: Blob): Promise<AxiosResponse<Game>> {
   const body = new FormData()
   body.append('imageFile', imageFile)

   return axios.post<Game>(`/api/admin/game/${gameId}/cover`, body)
}

function PostBannerImage(gameId: string, imageFile: Blob): Promise<AxiosResponse<Game>> {
   const body = new FormData()
   body.append('imageFile', imageFile)

   return axios.post<Game>(`/api/admin/game/${gameId}/banner`, body)
}

const GameApi = {
   GetGames,
   GetGame,
   PutGame,
   PostGame,
   AddIgdbGame,
   DeleteGame,
   PostCoverImage,
   PostBannerImage
}

export default GameApi
