import { Alert, Button, Card, Descriptions, Input, message } from 'antd'
import React, { useState } from 'react'
import TagApi from '../../api/tags-api'
import { ETagType, Tag } from '../../entities/Tag'
import { DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import EnumPicker from '../picker/enum-picker'

interface TagDetailProps {
   tagData: Tag
   onSave(Tag: Tag): Promise<boolean>
   showDeleteButton: boolean
}

export default function TagDetail({ tagData, onSave, showDeleteButton }: TagDetailProps): React.ReactElement {
   const [isChanged, setIsChanged] = useState<boolean>(false)
   const [tag, setTag] = useState<Tag>(tagData)
   const history = useHistory()

   function onReset() {
      setIsChanged(false)
      setTag(tagData)
   }

   async function onSubmit() {
      onSave(tag).then((success) => {
         if (success) {
            setIsChanged(false)
            message.success('submit succesful')
         }
      })
   }

   async function onDelete() {
      if (tag.id) {
         try {
            const success = await TagApi.DeleteTag(tag.id)
            if (success) {
               history.push('/tags-profile-all')
            }
            return success
         } catch {
            return false
         }
      }
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Button type="primary" htmlType="submit" onClick={onSubmit}>
                  Submit
               </Button>
               <Button htmlType="button" onClick={onReset}>
                  Reset
               </Button>
            </>
         )}
         <Descriptions extra={showDeleteButton && <DeleteOutlined style={{ color: 'red', fontSize: '42px' }} onClick={onDelete} />} />
         <Descriptions column={3} title={tag.name} extra={tag.id}>
            <Descriptions.Item span={3} label="Name" labelStyle={{ alignItems: 'center' }}>
               <Input
                  name="tagName"
                  value={tag.name}
                  onChange={(value) => {
                     setTag({ ...tag, name: value.target.value })
                     setIsChanged(true)
                  }}
               />
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Category" labelStyle={{ alignItems: 'center' }}>
               <Input
                  name="tagCategory"
                  value={tag.category}
                  onChange={(value) => {
                     setTag({ ...tag, category: value.target.value })
                     setIsChanged(true)
                  }}
               />
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Type" labelStyle={{ alignItems: 'center' }}>
               <EnumPicker
                  from={ETagType}
                  onChange={(value) => {
                     setTag({ ...tag, type: value })
                     setIsChanged(true)
                  }}
                  value={tag.type}
               />
            </Descriptions.Item>
            {tag.game && (
               <>
                  <Descriptions.Item span={3} label="GameName" labelStyle={{ alignItems: 'center' }}>
                     <Input type="string" name="tagGameName" value={tag.game.profileName} disabled />
                  </Descriptions.Item>
               </>
            )}
            <Descriptions.Item span={3} label="GameId" labelStyle={{ alignItems: 'center' }}>
               <Input
                  type="string"
                  name="tagGameId"
                  value={tag.gameId}
                  onChange={(value) => {
                     setTag({ ...tag, gameId: value.target.value })
                     setIsChanged(true)
                  }}
               />
            </Descriptions.Item>
            <Descriptions.Item span={3} label="UseCount" labelStyle={{ alignItems: 'center' }}>
               <Input
                  type="number"
                  name="tagUseCount"
                  value={tag.useCount}
                  onChange={(value) => {
                     setTag({ ...tag, useCount: value.target.valueAsNumber })
                     setIsChanged(true)
                  }}
               />
            </Descriptions.Item>
         </Descriptions>
      </Card>
   )
}
