export default interface ReigniteCard {
   alias: string
   type: ECardType
   cardType: number
   jsonData: string
   statsExpireTime: Date
   statsUpdateError: boolean
   statsExpired: boolean
   createdDate: Date
   lastModifyDate: Date
   ownerId: null | string
   owner: null
   id: string
   likedCount?: number
   isLikedByMe?: boolean
}

// export enum ECardType {
//   ConnectionPass = "ConnectionPass",
//   Cosplay = "Cosplay",
//   DotaProfile = "DotaProfile",
//   Fortnite = "Fortnite",
//   SteamProfile = "SteamProfile",
//   Streamer = "Streamer",
// }

export enum ECardType {
   Undefined = 0,
   SteamProfile = 1,
   Dota2 = 2,
   Cosplay = 3,
   Fortnite = 4,
   Anime = 5,
   PlaystationProfile = 6,
   ConnectionPass = 7,
   GenshinImpact = 8,
   Streamer = 9,
   LeagueOfLegends = 10,
   Valorant = 11,
   CounterStrike = 12,
   TeamfightTactics = 13,
   RocketLeague = 14,
   PUBG = 15,
   GTA5 = 16,
   FortniteTracker = 17,
   Xbox = 18
}
