import { Table, Image } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { LinkedPlatform } from "../../entities/LinkedPlatform";
import { LinkedPlatformSchema } from "../../entities/LinkedPlatformSchema";
import settings from '../../settings'

interface UserLinkedPlatformsListProps {
    linkedPlatforms: LinkedPlatform[]
}

export default function UserLinkedPlatformsList({ linkedPlatforms }: UserLinkedPlatformsListProps): React.ReactElement {
    const columns = [
        {
            title: 'Logo',
            dataIndex: 'linkedPlatformSchema',
            key: 'linkedPlatformSchema',
            render: function render(obj: LinkedPlatformSchema) { return <Image style={{ height: 40 }} src={settings.GetFileUrl(obj.iconImageFileId)} alt="" /> },
        },
        {
            title: 'Name',
            dataIndex: 'linkedPlatformSchema',
            key: 'linkedPlatformSchema',
            render: function render(obj: LinkedPlatformSchema) { return <Link to={`/platformlinks/${obj.id}`}>{obj.platformName}</Link> }
        },
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'User Link',
            dataIndex: 'userLink',
            key: 'userLink',
            render: function render(link: string) { return link ? <Link to={link} target="blank_">{link}</Link> : "None" },
        },
        {
            title: 'Created',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a: LinkedPlatform, b: LinkedPlatform) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
    ];

    return <>
        <Table columns={columns} dataSource={linkedPlatforms} pagination={false} />
    </>
}