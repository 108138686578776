import { Game } from './Game'

export interface Tag {
   id?: string
   name: string
   category: string
   type: ETagType
   gameId?: string
   game?: Game
   useCount?: number
   alias?: string
}

export enum ETagType {
   Undefine = 0,
   Interests = 1,
   Mainprofile = 2,
   Device = 3,
   Purpose = 4,
   Game = 5,
   AiImage = 6
}
