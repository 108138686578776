import { Checkbox, Image, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TransactionApi, { PrintOrder } from '../../api/TransactionApi'
import { TableParams } from '../../components/images/image-list'
import noImage from '../../images/noImage.png'

export default function OrdersPage(): React.ReactElement {
   const [data, setData] = useState<PrintOrder[]>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch()
   }, [])

   async function fetch() {
      setLoading(true)

      const result = await TransactionApi.GetOrders()

      setData(result.data)

      setLoading(false)
   }

   const columns: ColumnsType<PrintOrder> = [
      {
         title: 'Image',
         key: 'imageUrl',
         dataIndex: 'imageUrl',
         render: (value, order) => <Image src={order.imageUrl} width={200} fallback={noImage} />
      },
      {
         title: 'TransactionReference',
         dataIndex: 'transaction.paymentId',
         key: 'transaction.paymentId',
         render: (value, order) => (
            <Link target="_blank" rel="noopener noreferrer" to={`/orders/${order.transaction.paymentId}`}>
               {order.transaction.paymentId}
            </Link>
         )
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'Name',
         dataIndex: 'name',
         key: 'name'
      },
      {
         title: 'Surname',
         dataIndex: 'surname',
         key: 'surname'
      },
      {
         title: 'City',
         dataIndex: 'city',
         key: 'city'
      },
      {
         title: 'ZIP/PLZ',
         dataIndex: 'zip',
         key: 'zip'
      },
      {
         title: 'Street',
         dataIndex: 'street',
         key: 'street'
      },
      {
         title: 'MailAdress',
         dataIndex: 'mailadress',
         key: 'mailadress'
      },
      {
         title: 'Commissioned (In Auftrag gegeben)',
         dataIndex: 'commissioned',
         key: 'commissioned',
         render: function render(commissioned: boolean) {
            return <Checkbox checked={commissioned} />
         }
      },
      {
         title: 'Completed',
         dataIndex: 'completed',
         key: 'completed',
         render: function render(completed: boolean) {
            return <Checkbox checked={completed} />
         }
      }
   ]

   return (
      <>
         <Table columns={columns} loading={loading} dataSource={data} />
      </>
   )
}
