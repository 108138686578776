import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import AiPromptApi, { AiImageStyle } from '../../../api/PromptApi'
import StyleDetail from '../../../components/images/style-detail'

interface CharacterPageProps {
   id: string
}

export default function StylePage({ match }: RouteComponentProps<CharacterPageProps>): React.ReactElement {
   const [style, setStyle] = useState<AiImageStyle>()
   const history = useHistory()

   async function fetch() {
      setStyle(undefined)
      const style = await AiPromptApi.GetStyle(match.params.id)
      setStyle(style.data)
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [match])

   async function onSave(style: AiImageStyle): Promise<boolean> {
      try {
         const response = await AiPromptApi.UpdateStyle(style)
         history.push('/styles/' + style.id)
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!style && <Skeleton active />}
         {style && <StyleDetail onSave={onSave} showDeleteButton onRefresh={fetch} style={style} />}
      </>
   )
}
