import React, { useEffect, useState } from 'react'
import { TableParams } from '../../../components/images/image-list'
import { Table, TablePaginationConfig, Image, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { Link, useHistory } from 'react-router-dom'
import AiPromptApi, { AiImageTheme, EThemeCategory } from '../../../api/PromptApi'
import noImage from '../../../images/noImage.png'

export default function ThemesAllPage(): React.ReactElement {
   const [data, setData] = useState<AiImageTheme[]>()
   const history = useHistory()
   const [searchValue, setSearchValue] = useState<string>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch()
   }, [])

   async function fetch() {
      setLoading(true)
      const result = await AiPromptApi.GetThemes()

      setData(result.data)

      setLoading(false)
   }

   const columns: ColumnsType<AiImageTheme> = [
      {
         title: 'Image',
         key: 'imageUrl',
         dataIndex: 'imageUrl',
         render: (value, theme) => <Image src={theme.imageUrl} width={300} fallback={noImage} />
      },
      {
         title: 'Theme',
         key: 'theme',
         dataIndex: 'theme',
         render: (value, theme) => (
            <a target="_blank" href={theme.id} rel="noreferrer">
               <Link target="_blank" rel="noopener noreferrer" to={`/themes/${theme.id}`}>
                  {theme.name}
               </Link>
            </a>
         )
      },
      {
         title: 'Type',
         key: 'type',
         dataIndex: 'type',
         render: (value, theme) => <>{EThemeCategory[theme.type]}</>
      }
   ]

   function onChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<AiImageTheme> | SorterResult<AiImageTheme>[]
   ) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch()
   }

   return (
      <>
         <Button onClick={() => history.push('/themes-new')}>Add</Button>
         <Table columns={columns} pagination={tableParams.pagination} loading={loading} dataSource={data} onChange={onChange} />
      </>
   )
}
