import React from 'react'
import { ReigniteUser } from '../../entities/ReigniteUser'
import { Table, TablePaginationConfig } from 'antd'
import moment from 'moment'
import { Friendrequest } from '../../entities/Friendrequest'
import { EFriendrequestReason } from '../../entities/EFriendrequestReason'
import UserLink from '../user/user-link'

interface FriendrequestListProps {
   friendrequests: Friendrequest[]
   owner: ReigniteUser
   pagination?: false | TablePaginationConfig | undefined
}

export default function FriendrequestList({ friendrequests, owner, pagination }: FriendrequestListProps): React.ReactElement {
   const columns = [
      {
         title: 'From',
         key: 'owner',
         dataIndex: 'owner',
         render: function render(obj: ReigniteUser) {
            return obj !== undefined ? obj.nickname : owner.nickname
         }
      },
      {
         title: 'To',
         key: 'requestedUser',
         dataIndex: 'requestedUser',
         render: function render(obj: ReigniteUser) {
            return obj !== undefined ? <UserLink user={obj} /> : <UserLink user={owner} />
         }
      },
      {
         title: 'Greeting Text',
         dataIndex: 'greetingtext',
         key: 'greetingtext'
      },
      {
         title: 'Reason',
         dataIndex: 'reason',
         key: 'reason',
         render: function render(reason: EFriendrequestReason) {
            return EFriendrequestReason[reason]
         },
         filters: [
            {
               text: 'Unknown',
               value: 0
            },
            {
               text: 'Matchrequest',
               value: 1
            },
            {
               text: 'Directrequest',
               value: 2
            }
         ],
         onFilter: (value: string | number | boolean, record: Friendrequest) => record.reason === value
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         sorter: (a: Friendrequest, b: Friendrequest) => moment(a.createdDate).unix() - moment(b.createdDate).unix()
      },
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id'
      }
   ]

   return (
      <>
         <Table columns={columns} dataSource={friendrequests} pagination={pagination} />
      </>
   )
}
