import React, { useEffect, useState } from "react";
import { message } from "antd";
import { Item } from "../entities/Item";
import ItemApi from "../api/ItemApi";
import ItemList from "../components/item/item-list";

export default function ItemAllPage(): React.ReactElement {
    const [items, setItems] = useState<Item[]>();

    async function fetch() {
        const result = await ItemApi.GetAll()
        if (result.status !== 200) {
            message.error(`response ${result.statusText} (${result.status})`)
            return;
        }
        setItems(result.data)
    }

    //onMount
    useEffect(() => {
        fetch()
    }, []);

    return <>
        {items && <ItemList items={items} />}
    </>
}
