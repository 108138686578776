export function mapObjectToUrlParam(obj: any): URLSearchParams {
   const keys = Object.keys(obj)
   const params = new URLSearchParams()

   function add(key: string, value: any) {
      if (Array.isArray(value)) {
         value.forEach((x) => add(key, x))
      } else {
         params.append(key, value.toString())
      }
   }

   keys.filter((key) => !!obj[key]).forEach((key) => add(key, obj[key]))

   return params
}
