import React, { useState } from 'react'
import { Card, Col, Image, Divider, Row, Descriptions, Checkbox, Button, Input, message, Alert, Form } from 'antd'
import noImage from '../../images/noImage.png'
import settings from '../../settings'
import { Link, useHistory } from 'react-router-dom'
import { AiImage, DEFAULT_AWS_URL_OPTIMIZED, EUpscaleType, LeagueOfLegendsGenerateData } from '../../api/ImageApi'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import UserLink from '../user/user-link'
import { AiImageCharacter } from '../../api/PromptApi'
import TextArea from 'antd/lib/input/TextArea'

interface CharacterDetailProps {
   character: AiImageCharacter
   onSave(character: AiImageCharacter): Promise<boolean>
   onRefresh?(): void
   showDeleteButton: boolean
}

export default function CharacterDetail({
   onSave,
   onRefresh = () => {
      return
   },
   character: _character
}: CharacterDetailProps): React.ReactElement {
   const [character, setCharacter] = useState(_character)
   const [isChanged, setIsChange] = useState<boolean>(false)

   function onReset() {
      setIsChange(false)
      setCharacter(_character)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(character).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   function onChange(editCharacter: AiImageCharacter) {
      setIsChange(true)
      setCharacter(editCharacter)
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}
         {/* {character.isHidden != image.isHidden && <Button onClick={() => onSave(character)}>Save</Button>} */}
         <Row>
            <Col flex="200px">
               <Image src={`${DEFAULT_AWS_URL_OPTIMIZED}/assets/ai-prompts/characters/${character.loraId}.jpg`} width={500} fallback={noImage} />
            </Col>
            <Col flex="1">
               <Descriptions title="Character Info" bordered>
                  <DescriptionsItem span={3} label={'Id'}>
                     {character.id}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={character.name ? character.name : ''} onChange={(e) => onChange({ ...character, name: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Skin'}>
                     <Input value={character.skin ? character.skin : ''} onChange={(e) => onChange({ ...character, skin: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Lora Id'}>
                     <Input value={character.loraId ? character.loraId : ''} onChange={(e) => onChange({ ...character, loraId: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Positive Prompt Trigger'}>
                     <Input
                        value={character.positivePromptTrigger ? character.positivePromptTrigger : ''}
                        onChange={(e) => onChange({ ...character, positivePromptTrigger: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Helper Prompts'}>
                     <TextArea
                        rows={5}
                        value={character.helperPrompts ? character.helperPrompts : ''}
                        onChange={(e) => onChange({ ...character, helperPrompts: e.target.value })}></TextArea>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Secondary Model Type'}>
                     {character.secondaryModelType}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Theme'}>
                     <Link target="_blank" rel="noopener noreferrer" to={`/themes/${character.themeId}`}>
                        {character.themeId}
                     </Link>
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
