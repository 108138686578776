import React, { useEffect, useState } from "react";
import NewsApi from "../../api/news-api";
import NewsList from "../../components/news/news-list";
import { useApiContext } from "../../context/api-context";
import { News } from "../../entities/News";

export default function NewsAllPage(): React.ReactElement {
    const { AuthPending } = useApiContext();
    const [news, setNews] = useState<News[]>();

    //onMount
    useEffect(() => {
        if (!AuthPending) {
            NewsApi.GetAll().then((_news) => { setNews(_news.data) });
        }
    }, [AuthPending]);

    return <>
        {news && <NewsList news={news} />}
    </>
}
