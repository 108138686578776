import { Button, Input, Table, Image, Checkbox } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DownloadAsCsv } from '../../helper/DownloadHelper'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import UserLink from '../user/user-link'
import AiImageApi, { AiImage } from '../../api/ImageApi'
import { Link } from 'react-router-dom'
import noImage from '../../images/noImage.png'

export interface TableParams {
   pagination?: TablePaginationConfig
   sortField?: string
   sortOrder?: string
   filters?: Record<string, FilterValue | null>
}

export interface ImageListProps {
   filter: 'newest' | 'hot'
   uncensored?: boolean
}

export default function ImageList({ filter, uncensored = false }: ImageListProps): React.ReactElement {
   const [data, setData] = useState<AiImage[]>()
   const [filterActive, setFilterActive] = useState(true)
   const [searchValue, setSearchValue] = useState<string>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch()
   }, [])

   async function fetch() {
      setLoading(true)

      let result
      if (uncensored) {
         result = await AiImageApi.GetRawImages()
      } else {
         result = await AiImageApi.GetPublicImages(filter == 'hot' ? true : false)
      }

      setData(result.data)

      setLoading(false)
   }

   async function deleteImage(id: string) {
      setLoading(true)

      const response = await AiImageApi.DeleteImageById(id)
      if (response.status !== 200) {
         console.error(response.statusText)
         return
      }

      fetch()

      setLoading(false)
   }

   const columnsDelete: ColumnsType<AiImage> = [
      {
         title: 'Image',
         key: 'imageUrl',
         dataIndex: 'imageUrl',
         render: (value, image) => <Image src={image.imageUrl} width={200} fallback={noImage} />
      },
      {
         title: 'Id',
         key: 'alias',
         dataIndex: 'alias',
         render: (value, image) => (
            <Link target="_blank" rel="noopener noreferrer" to={`/image/${image.id}`}>
               {image.id}
            </Link>
         )
      },
      {
         title: 'Owner',
         key: 'owner',
         render: (value, image) => (image.owner ? <UserLink user={image.owner} /> : 'None')
      },
      {
         title: 'Prompt',
         key: 'userPrompt',
         dataIndex: 'userPrompt'
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'Champion',
         dataIndex: 'name',
         key: 'name'
      },
      {
         title: 'Action',
         render: (value, image) => <Button onClick={(e) => deleteImage(image.id)}>Deleet</Button>
      }
   ]

   const columns: ColumnsType<AiImage> = [
      {
         title: 'Image',
         key: 'imageUrl',
         dataIndex: 'imageUrl',
         render: (value, image) => <Image src={image.imageUrl} width={200} fallback={noImage} />
      },
      {
         title: 'Id',
         key: 'alias',
         dataIndex: 'alias',
         render: (value, image) => (
            <Link target="_blank" rel="noopener noreferrer" to={`/image/${image.id}`}>
               {image.id}
            </Link>
         )
      },
      {
         title: 'Owner',
         key: 'owner',
         render: (value, image) => (image.owner ? <UserLink user={image.owner} /> : 'None')
      },
      {
         title: 'Likes',
         dataIndex: 'likedCount'
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'Champion',
         dataIndex: 'name',
         key: 'name'
      }
   ]

   function onChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<AiImage> | SorterResult<AiImage>[]) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch()
   }

   return (
      <>
         {/* <Input.Search
            title="Search"
            placeholder="search for Alias, Id or OwnerId"
            onSearch={setSearchValue}
            allowClear
            style={{ width: 400, marginBottom: 5 }}
         /> */}
         {data && (
            <Button type="primary" onClick={() => DownloadAsCsv('cards', data)}>
               Export Result
            </Button>
         )}
         <Table columns={uncensored ? columnsDelete : columns} pagination={tableParams.pagination} loading={loading} dataSource={data} onChange={onChange} />
      </>
   )
}
