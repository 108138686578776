import { Button, Input, Table, TablePaginationConfig } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GameApi from '../../api/game-api'
import { Game } from '../../entities/Game'
import settings from '../../settings'
import { TableParams } from '../cards/cards-list'
import GameLink from './game-link'

export default function GamesList(): React.ReactElement {
   const [data, setData] = useState<Game[]>()
   const [searchValue, setSearchValue] = useState<string>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch({ ...tableParams, pagination: { ...tableParams.pagination, current: 1 } })
   }, [searchValue])

   async function fetch(_tableParams: TableParams) {
      setLoading(true)
      const result = await GameApi.GetGames(searchValue, _tableParams.pagination?.pageSize, _tableParams.pagination?.current)

      setData(result.items)
      setTableParams({
         ..._tableParams,
         pagination: {
            total: result.pageInfo.TotalCount,
            current: result.pageInfo.CurrentPage,
            pageSize: result.pageInfo.PageSize
         }
      })

      setLoading(false)
   }

   const columns = [
      {
         title: 'Cover',
         dataIndex: 'coverImageFileId',
         key: 'coverImageFileId',
         render: function render(fileId: string) {
            return <img width={40} src={settings.GetFileUrl(fileId)} />
         }
      },
      {
         title: 'Game',
         key: 'game',
         render: function render(game: Game) {
            return <GameLink game={game} />
         }
      },
      {
         title: 'Tags',
         render: (game: Game) => (
            <Link to={`./tags/${game.id}`}>
               <Button>View</Button>
            </Link>
         )
      },
      {
         title: 'LFG Entries',
         render: (game: Game) => (
            <Link to={`./lfg/${game.id}`}>
               <Button>View</Button>
            </Link>
         )
      },
      {
         title: 'Published',
         dataIndex: 'published',
         key: 'published',
         render: function render(value: boolean) {
            return <Checkbox checked={value} />
         },
         filters: [
            {
               text: 'Not published',
               value: false
            },
            {
               text: 'Published',
               value: true
            }
         ]
      },

      {
         title: 'Generated',
         dataIndex: 'generated',
         key: 'generated',
         render: function render(value: boolean) {
            return <Checkbox checked={value} />
         },
         filters: [
            {
               text: 'False',
               value: false
            },
            {
               text: 'True',
               value: true
            }
         ]
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         sorter: (a: Game, b: Game) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         sorter: (a: Game, b: Game) => moment(a.lastModifyDate).unix() - moment(b.lastModifyDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id'
      }
   ]

   function onChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<Game> | SorterResult<Game>[]) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch(_tableParams)
   }

   return (
      <>
         <Input.Search title="Search" placeholder="search for Name or Id" onSearch={setSearchValue} allowClear style={{ width: 400, marginBottom: 5 }} />
         <Table columns={columns} loading={loading} pagination={tableParams.pagination} dataSource={data} onChange={onChange} />
      </>
   )
}
