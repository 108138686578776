import { Form, Input, Button, Select } from 'antd'
import React, { useState } from 'react'
import UserApi from '../../api/api-user'
import { InviteUser } from '../../entities/InviteUser'

export default function UserInvitePage(): React.ReactElement {
   const [responseText, setResponseText] = useState<string>()

   async function onFinish(user: InviteUser) {
      try {
         const response = await UserApi.InviteUser(user)
         setResponseText(await response.data.text())
      } catch (error) {
         setResponseText('Failed to create user (check console)')
         console.warn(error)
      }
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const onFinishFailed = (errorInfo: any) => {
      console.warn('Failed:', errorInfo)
   }

   return (
      <Form
         name="Invite_user"
         labelCol={{ span: 2 }}
         wrapperCol={{ span: 16 }}
         initialValues={{ remember: true }}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}>
         <Form.Item label="Nickname" name="Nickname" rules={[{ required: true, message: 'Please enter a nickname!' }]}>
            <Input placeholder="Benutzername" />
         </Form.Item>
         <Form.Item
            label="Mail-Adress"
            name="MailAdress"
            rules={[
               { required: true, message: 'Please enter a mail-adress!' },
               { type: 'email', message: 'Please enter a valid mail-adress!' }
            ]}>
            <Input placeholder="Mail-Adress" />
         </Form.Item>
         <Form.Item label="FireSoulGems" name="FireSoulGems">
            <Input defaultValue={0} type="number" />
         </Form.Item>
         <Form.Item label="Sprache" name="LanguageCode">
            <Select defaultValue="de">
               <Select.Option value="de">Deutsch</Select.Option>
               <Select.Option value="en">Englisch</Select.Option>
            </Select>
         </Form.Item>
         <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
            <Button type="primary" htmlType="submit">
               Submit
            </Button>
            <p style={{ fontSize: 40 }}>{responseText}</p>
         </Form.Item>
      </Form>
   )
}
