import { Checkbox, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { ShopItem } from '../../entities/ShopItem'
import ItemLink from '../item/item-link'
import ShopItemLink from './shopItem-link'

interface Props {
   shopItems: ShopItem[]
}

export default function ShopItemList({ shopItems }: Props): React.ReactElement {
   const columns: ColumnsType<ShopItem> = [
      {
         title: 'Name',
         key: 'name',
         render: function render(item: ShopItem) {
            return <ShopItemLink shopItem={item} />
         },
         sorter: (a: ShopItem, b: ShopItem) => a.name.localeCompare(b.name)
      },
      {
         title: 'Price',
         key: 'price',
         render: (item: ShopItem) => `${item.price.value} ${item.price.currency}`
      },
      {
         title: 'Items',
         key: 'items',
         render: (item: ShopItem) => (
            <Space>
               {item.items.map((_item) => (
                  <ItemLink key={_item.id} item={_item} />
               ))}
            </Space>
         )
      },
      {
         title: 'Disabled',
         dataIndex: 'disabled',
         key: 'disabled',
         render: (value: boolean) => <Checkbox checked={value} disabled />
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         defaultSortOrder: 'descend',
         sorter: (a: ShopItem, b: ShopItem) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         sorter: (a: ShopItem, b: ShopItem) => moment(a.lastModifyDate).unix() - moment(b.lastModifyDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      }
   ]

   return (
      <>
         <Table columns={columns} dataSource={shopItems} />
      </>
   )
}
