import { Button, Input, Table } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { LinkOutlined } from '@ant-design/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReigniteCard from '../../entities/ReigniteCard'
import { Link } from 'react-router-dom'
import { DownloadAsCsv } from '../../helper/DownloadHelper'
import ApiCards from '../../api/CardApi'
import { CardType } from '../../entities/CardType'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import UserLink from '../user/user-link'

export interface TableParams {
   pagination?: TablePaginationConfig
   sortField?: string
   sortOrder?: string
   filters?: Record<string, FilterValue | null>
}

export default function CardList(): React.ReactElement {
   const [data, setData] = useState<ReigniteCard[]>()
   const [searchValue, setSearchValue] = useState<string>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })
   const [types, setTypes] = useState<CardType[]>()

   useEffect(() => {
      fetch(tableParams)
   }, [searchValue])

   async function fetch(_tableParams: TableParams) {
      setLoading(true)
      const result = await ApiCards.GetAll(
         _tableParams.filters?.type as any,
         _tableParams.filters?.owner as any,
         searchValue,
         _tableParams.pagination?.pageSize,
         _tableParams.pagination?.current
      )

      setData(result.items)
      setTableParams({
         ..._tableParams,
         pagination: {
            total: result.pageInfo.TotalCount,
            current: result.pageInfo.CurrentPage,
            pageSize: result.pageInfo.PageSize
         }
      })

      setLoading(false)
   }

   async function fetchTypes() {
      const _types = await ApiCards.GetCardTypes()
      setTypes(_types.data)
   }

   //onMount
   useEffect(() => {
      fetchTypes()
   }, [])

   const columns: ColumnsType<ReigniteCard> = [
      {
         title: 'Alias',
         key: 'alias',
         dataIndex: 'alias',
         render: (value, card) => (
            <a target="_blank" href={`https://cards.reignite.gg/view/${card.type}/${encodeURIComponent(card.alias)}`} rel="noreferrer">
               {card.alias}
               <LinkOutlined style={{ paddingLeft: '4px' }} />
            </a>
         )
      },
      {
         title: 'Type',
         dataIndex: 'type',
         key: 'type',
         filterMultiple: true,
         filters: types?.map((type) => ({
            value: type.value,
            text: type.value
         }))
      },
      {
         title: 'Owner',
         key: 'owner',
         filterMultiple: false,
         filters: [
            { text: 'With Owner', value: true },
            { text: 'Without Owner', value: false }
         ],
         render: (value, card) => (card.owner ? <UserLink user={card.owner} /> : 'None')
      },
      {
         title: 'Likes',
         dataIndex: 'likedCount'
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id'
      }
   ]

   function onChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ReigniteCard> | SorterResult<ReigniteCard>[]
   ) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch(_tableParams)
   }

   return (
      <>
         <Input.Search
            title="Search"
            placeholder="search for Alias, Id or OwnerId"
            onSearch={setSearchValue}
            allowClear
            style={{ width: 400, marginBottom: 5 }}
         />
         {data && (
            <Button type="primary" onClick={() => DownloadAsCsv('cards', data)}>
               Export Result
            </Button>
         )}
         <Table columns={columns} pagination={tableParams.pagination} loading={loading} dataSource={data} onChange={onChange} />
      </>
   )
}
