import axios, { AxiosResponse } from 'axios'
import { ReigniteUser } from '../entities/ReigniteUser'
import { CheckImage } from '../components/images/image-curate-list'

const GetImage = (id: string) => axios.get<AiImage>(`/api/aiimage/${id}`, { params: { skipImpressionCount: true } })
const GetForbiddenWords = () => axios.get<ForbiddenWord[]>(`/api/aiimage/forbiddenWords`)
const AddForbiddenWord = (word: string) => axios.post<ForbiddenWord[]>(`/api/aiimage/forbiddenWords/?word=${word}`)
const RemoveForbiddenWord = (id: string) => axios.delete<ForbiddenWord[]>(`/api/aiimage/forbiddenWords/${id}`)
const UpdateVisibleState = (hidden: boolean, id: string) => axios.put<AiImage>(`/api/aiimage/visible/${id}?hidden=${hidden}`)
const GetImagesOfUser = (userId: string, skip?: number, take?: number) => axios.get<AiImage[]>(`/api/aiimage/user/${userId}`, { params: { skip, take } })
const GetPublicImages = (orderByLikeCount: boolean, skip?: number, take?: number): Promise<AxiosResponse<AiImage[]>> =>
   axios.get<AiImage[]>(`/api/aiimage/images`, { params: { skip, take, orderByLikeCount } })
const GetRawImages = (take?: number): Promise<AxiosResponse<AiImage[]>> => axios.get<AiImage[]>(`/api/admin/aiimage/images`, { params: { take } })
const CurateImages = (images: CuratedImages[]): Promise<AxiosResponse<AiImage[]>> => axios.post<AiImage[]>(`/api/admin/aiimage/curate`, images)
const GetMetrics = () => axios.get<AiImageRequestMetrics[]>(`/api/admin/aiimage/metrics`)
const GetMetricsById = (id: string) => axios.get<AiImageRequestMetrics>(`/api/admin/aiimage/metrics/${id}`)
const DeleteImageById = (id: string) => axios.delete(`/api/admin/aiimage/${id}`)
const GetIsTestserver = () => axios.get(`/api/aiimage/getTesting`)
const UpdateTestserver = (testing: boolean) => axios.post(`/api/aiimage/updateTesting/${testing}`)
const AiImageApi = {
   GetImage,
   CurateImages,
   UpdateVisibleState,
   GetImagesOfUser,
   GetPublicImages,
   GetRawImages,
   GetForbiddenWords,
   AddForbiddenWord,
   RemoveForbiddenWord,
   GetMetrics,
   GetMetricsById,
   DeleteImageById,
   GetIsTestserver,
   UpdateTestserver
}

export default AiImageApi

export const DEFAULT_AWS_URL_OPTIMIZED = 'https://d1nv3961i7vw1y.cloudfront.net'

interface CuratedImages {
   id: string
   isPublic: boolean
}

export interface AiImage {
   imageUrl: string
   name: string
   skin: string
   id: string
   createdDate: string
   lastModifyDate: string
   owner: ReigniteUser
   likedCount: number
   isLikedByMe: boolean
   impressionCount: number
   isHidden: boolean
   checkpointId: string
   characterId: string
   userPrompt: string
   upscaledFrom: string
   upscaleType: EUpscaleType
   seed: number
}

export enum EUpscaleType {
   'True Form' = 0,
   'Double Scale' = 1,
   'Triple Scale' = 2
}

export interface ForbiddenWord {
   id: string
   word: string
}

export interface LeagueOfLegendsGenerateData {
   id: string
   champRole: string
   champName: string
   champStyle: LeagueOfLegendsChampStyle
}

export interface AiImageRequestMetrics {
   id: string
   aiImageId: string
   status: EGenerationStatus
   requestTime: Date
   feCompletionTime: Date
   beCompletionTime: Date
}

export enum EGenerationStatus {
   Generating = 0,
   Done = 1
}

export interface LeagueOfLegendsChampStyle {
   id: string
   name: string
   imageUrl: string
   loraId?: string
   loraWeight?: string
   promptPosLora?: string
   promptPosTrigger?: string
   promptPosDemo?: string
   promptNeg?: string
   promptUser: string
   loraPosTriggerKey: string
   secondaryModelType: string
   // cfgSale?: number;
   // clipScip?: number;
   // steps?: number;
   // upscaleDenoiseStrength?: string;
}
