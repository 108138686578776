import { Table } from 'antd'
import React from 'react'
import { Tag } from '../../entities/Tag'
import GameLink from '../games/game-link'
import TagLink from './tag-link'

interface TagsProps {
   singleGame?: boolean
   tags: Tag[]
}

export default function GameTagsList({ singleGame, tags }: TagsProps): React.ReactElement {
   const columns = [
      {
         title: 'Tag',
         key: 'tag',
         sorter: (a: Tag, b: Tag) => a.name.localeCompare(b.name),
         render: (tag: Tag) => <TagLink tag={tag} />
      },
      {
         title: 'Game',
         render: (tag: Tag) => tag.game && <GameLink game={tag.game} />
         //sorter: (a: Tag, b: Tag) => a.category.localeCompare(b.gameId),
      },
      {
         title: 'Use Count',
         dataIndex: 'useCount',
         key: 'useCount'
         //sorter: (a: Tag, b: Tag) => a.useCount - b.useCount
      },
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id'
      }
   ]

   const reducedColumns = [
      {
         title: 'Tag',
         key: 'tag',
         sorter: (a: Tag, b: Tag) => a.name.localeCompare(b.name),
         render: (tag: Tag) => <TagLink tag={tag} />
      },
      {
         title: 'Use Count',
         dataIndex: 'useCount',
         key: 'useCount'
         //sorter: (a: Tag, b: Tag) => a.useCount - b.useCount
      },
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id'
      }
   ]

   return <Table columns={singleGame ? reducedColumns : columns} dataSource={tags} />
}
