/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import { EUserRole } from '../entities/EUserRole'
import { InviteUser } from '../entities/InviteUser'
import { NewUser } from '../entities/NewUser'
import PageList from '../entities/PageList'
import { ReigniteDetailListUser } from '../entities/ReigniteDetailListUser'
import { ReigniteUser } from '../entities/ReigniteUser'
import { InsightRegistrations, UserInsights } from '../entities/UserInsgights'
import { RegisterdV2, UserInsightsV2 } from '../entities/UserInsgightsV2'

function GetMe() {
   return axios.get<ReigniteUser>(`/api/user/me`)
}

function GetUserInsights() {
   return axios.get<UserInsights>(`/api/admin/insights/userinsights`)
}

function GetUserInsightsV2() {
   return axios.get<UserInsightsV2>(`/api/admin/insights/userinsightsv2`)
}

function GetRegistrationInsights(days: number, skip: number) {
   return axios.get<InsightRegistrations[]>(`/api/admin/insights/registerd?days=${days}&skip=${skip}`)
}

function GetRegistrationInsightsV2(days: number, skip: number) {
   return axios.get<RegisterdV2[]>(`/api/admin/insights/registerdv2?days=${days}&skip=${skip}`)
}

function GetUsers() {
   return axios.get<ReigniteUser[]>(`/api/admin/user`)
}

const GetUsersDetail = async (q?: string, PageSize?: number, PageNumber?: number): Promise<PageList<ReigniteDetailListUser>> => {
  const response = await axios.get<ReigniteDetailListUser[]>(`/api/admin/user/list`, { params: { q, PageSize, PageNumber } })
  
  const pageInfo = JSON.parse(await response.headers['paging-headers'])
  return { items: response.data, pageInfo }
}

function GetUser(id: string) {
   return axios.get<ReigniteUser>(`/api/admin/user/${id}`)
}

function PutUser(user: ReigniteUser) {
   return axios.put<ReigniteUser>(`/api/admin/user`, user)
}

function PostUser(user: NewUser) {
   return axios.post<ReigniteUser>(`/api/admin/user`, user)
}

function _InviteUser(user: InviteUser) {
   return axios.post(`/api/admin/user/invite`, user)
}

function DeleteReigniteUser(id: string) {
   return axios.delete(`/api/admin/user/${id}`)
}

function DeleteUserImage(id: string) {
   return axios.delete(`/api/user/ProfilePicture/${id}`)
}

function BannUser(id: string) {
   return axios.put<ReigniteUser>(`/api/admin/user/role/${id}/${EUserRole.Blocked}`, undefined)
}

function UnbannUser(id: string) {
   return axios.put<ReigniteUser>(`/api/admin/user/role/${id}/${EUserRole.User}`, undefined)
}

const UserApi = {
   GetMe,
   GetUserInsights,
   GetUserInsightsV2,
   GetRegistrationInsights,
   GetRegistrationInsightsV2,
   GetUsers,
   GetUsersDetail,
   GetUser,
   PutUser,
   PostUser,
   InviteUser: _InviteUser,
   DeleteReigniteUser,
   DeleteUserImage,
   BannUser,
   UnbannUser
}

export default UserApi
