import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { EItemType, Item } from '../../entities/Item'
import { ECardType } from '../../entities/ReigniteCard'
import ItemLink from './item-link'

interface ItemListProps {
   items: Item[]
}

export default function ItemList({ items }: ItemListProps): React.ReactElement {
   const columns: ColumnsType<Item> = [
      {
         title: 'Name',
         key: 'name',
         render: function render(item: Item) {
            return <ItemLink item={item} />
         },
         sorter: (a: Item, b: Item) => a.name.localeCompare(b.name)
      },
      {
         title: 'Type',
         dataIndex: 'type',
         key: 'type',
         render: (value: number) => EItemType[value]
      },
      {
         title: 'Value',
         dataIndex: 'value',
         key: 'value'
      },
      {
         title: 'CardType',
         dataIndex: 'cardType',
         key: 'cardType',
         render: (value: number) => ECardType[value]
      },
      {
         title: 'Category',
         dataIndex: 'category',
         key: 'category'
      },
      {
         title: 'Alias',
         dataIndex: 'alias',
         key: 'alias'
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         defaultSortOrder: 'descend',
         sorter: (a: Item, b: Item) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         sorter: (a: Item, b: Item) => moment(a.lastModifyDate).unix() - moment(b.lastModifyDate).unix(),
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      }
   ]

   return (
      <>
         <Table columns={columns} dataSource={items} />
      </>
   )
}
