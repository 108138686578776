import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import TagApi from '../../../api/tags-api'
import { Tag } from '../../../entities/Tag'
import ImageTagDetail from '../../../components/images/imagetag-detail'

interface CharacterPageProps {
   id: string
}

export default function ImageTagPage({ match }: RouteComponentProps<CharacterPageProps>): React.ReactElement {
   const [imagetag, setImageTag] = useState<Tag>()
   const history = useHistory()

   async function fetch() {
      setImageTag(undefined)
      const imagetag = await TagApi.GetTag(match.params.id)
      setImageTag(imagetag.data)
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [match])

   async function onSave(imagetag: Tag): Promise<boolean> {
      try {
         const response = await TagApi.PutTag(imagetag)
         history.push('/image-tags/' + imagetag.id)
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!imagetag && <Skeleton active />}
         {imagetag && <ImageTagDetail onSave={onSave} showDeleteButton onRefresh={fetch} imagetag={imagetag} />}
      </>
   )
}
