import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import AiPromptApi, { AiImageTheme } from '../../../api/PromptApi'
import ThemeDetail from '../../../components/images/theme-detail'

interface ThemePageProps {
   id: string
}

export default function ThemePage({ match }: RouteComponentProps<ThemePageProps>): React.ReactElement {
   const [theme, setTheme] = useState<AiImageTheme>()
   const history = useHistory()

   async function fetch() {
      setTheme(undefined)
      const theme = await AiPromptApi.GetTheme(match.params.id)
      setTheme(theme.data)
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [match])

   async function onSave(theme: AiImageTheme): Promise<boolean> {
      try {
         const response = await AiPromptApi.UpdateTheme(theme)
         history.push('/themes/' + theme.id)
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!theme && <Skeleton active />}
         {theme && <ThemeDetail onSave={onSave} showDeleteButton onRefresh={fetch} theme={theme} />}
      </>
   )
}
