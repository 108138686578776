import { Alert, Button, Card, Checkbox, Col, Descriptions, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AiPromptApi, { AiImageStyle, EStyleCategory } from '../../../api/PromptApi'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import { DEFAULT_AWS_URL_OPTIMIZED } from '../../../api/ImageApi'
import TextArea from 'antd/lib/input/TextArea'

export default function StyleNewPage(): React.ReactElement {
   const history = useHistory()
   const [style, setStyle] = useState<AiImageStyle>({
      id: undefined,
      name: '',
      helperPrompts: '',
      loraId: '',
      positivePromptTrigger: '',
      secondaryModelType: 'lora',
      lightWeight: 0.7,
      moderateWeight: 0.8,
      strongWeight: 0.9,
      intenseWeight: 1.0,
      nSFW: false,
      type: EStyleCategory.Accessoires,
      imageUrl: 'https://d1nv3961i7vw1y.cloudfront.net/assets/ai-prompts/styles/'
   })
   const [isValid, setIsValid] = useState(false)

   const { Option } = Select

   async function onSave(): Promise<boolean> {
      try {
         if (style == undefined) return false

         const response = await AiPromptApi.AddStyle(style)
         history.push('/styles/' + response.data.id)
         return true
      } catch {
         return false
      }
   }

   useEffect(() => {
      if (
         style.name != '' &&
         style.loraId != '' &&
         style.lightWeight != undefined &&
         style.moderateWeight != undefined &&
         style.strongWeight != undefined &&
         style.intenseWeight != undefined
      )
         setIsValid(true)
   }, [style])

   return (
      <Card>
         {isValid ? (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSave}>
                     Submit
                  </Button>
               </Form.Item>
            </>
         ) : (
            <></>
         )}
         {/* {style.isHidden != image.isHidden && <Button onClick={() => onSave(style)}>Save</Button>} */}
         <Row>
            <Col flex="1">
               <Descriptions title="Style Info" bordered>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={style.name} onChange={(e) => setStyle({ ...style, name: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Lora Id'}>
                     <Input value={style.loraId} onChange={(e) => setStyle({ ...style, loraId: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Positive Prompt Trigger'}>
                     <Input value={style.positivePromptTrigger} onChange={(e) => setStyle({ ...style, positivePromptTrigger: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Helper Prompts'}>
                     <TextArea rows={5} value={style.helperPrompts} onChange={(e) => setStyle({ ...style, helperPrompts: e.target.value })}></TextArea>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Secondary Model Type'}>
                     <Input disabled value={style.secondaryModelType} onChange={(e) => setStyle({ ...style, secondaryModelType: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'ImageUrl'}>
                     <Input value={style.imageUrl} onChange={(e) => setStyle({ ...style, imageUrl: e.target.value })}></Input>
                     <label>z.B. https://d1nv3961i7vw1y.cloudfront.net/assets/ai-prompts/styles/Adidas.jpeg</label>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Light Weight'}>
                     <Input type="number" value={style.lightWeight} onChange={(e) => setStyle({ ...style, lightWeight: e.target.valueAsNumber })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Moderate Weight'}>
                     <Input type="number" value={style.moderateWeight} onChange={(e) => setStyle({ ...style, moderateWeight: e.target.valueAsNumber })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Strong Weight'}>
                     <Input type="number" value={style.strongWeight} onChange={(e) => setStyle({ ...style, strongWeight: e.target.valueAsNumber })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Intense Weight'}>
                     <Input type="number" value={style.intenseWeight} onChange={(e) => setStyle({ ...style, intenseWeight: e.target.valueAsNumber })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'NSFW'}>
                     <Checkbox checked={style.nSFW} onChange={(e) => setStyle({ ...style, nSFW: e.target.checked })}></Checkbox>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Type'}>
                     <Select style={{ width: '100%' }} value={EStyleCategory[style.type]} onChange={(e) => setStyle({ ...style, type: Number.parseInt(e) })}>
                        <Option key={EStyleCategory.Accessoires}>{'Accessoires'}</Option>
                        <Option key={EStyleCategory.Aesthetics}>{'Aesthetics'}</Option>
                        <Option key={EStyleCategory.Artists}>{'Artists'}</Option>
                        <Option key={EStyleCategory.Brands}>{'Brands'}</Option>
                        <Option key={EStyleCategory.Clothings}>{'Clothings'}</Option>
                        <Option key={EStyleCategory.Concepts}>{'Concepts'}</Option>
                        <Option key={EStyleCategory.Memes}>{'Memes'}</Option>
                        <Option key={EStyleCategory.Poses}>{'Poses'}</Option>
                        <Option key={EStyleCategory.Fetish}>{'Fetish'}</Option>
                     </Select>
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
