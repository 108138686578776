import React, { useState } from 'react'
import { Modal, Button, Descriptions, Card, Input, Form, Alert, message, InputNumber } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { EItemType, Item } from '../../entities/Item'
import ItemApi from '../../api/ItemApi'
import { ECardType } from '../../entities/ReigniteCard'
import EnumPicker from '../picker/enum-picker'

interface Props {
   item: Item
   onSave(item: Item): Promise<boolean>
   showDeleteButton: boolean
}

export default function ItemDetail({ onSave, showDeleteButton, item }: Props): React.ReactElement {
   const history = useHistory()

   const [isChanged, setIsChange] = useState<boolean>(false)
   const [editItem, setEditItem] = useState<Item>(item)

   const [deleteActive, setDeleteActive] = useState(false)

   function onReset() {
      setIsChange(false)
      setEditItem(item)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(editItem).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   async function onDelete() {
      setDeleteActive(false)

      if (item.id) {
         try {
            const response = await ItemApi.Delete(item.id)
            if (response.status == 200) {
               message.success('Deleted!')
               history.push('/item-all')
               return true
            }

            message.error(`Error response status ${response.statusText} (${response.status})`)
         } catch {
            return false
         }
      }

      return false
   }

   function onChange(_item: Item) {
      setIsChange(true)
      setEditItem(_item)
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}

         <Modal title={'Delete ' + editItem.name + '?'} visible={deleteActive} onOk={onDelete} onCancel={() => setDeleteActive(false)}>
            <p>Do you REALLY want to DELETE this item? This can not be reversed! Deleted objects are gone FOREVEEEEEEER!!</p>
         </Modal>

         <Descriptions
            column={1}
            title={editItem.name}
            extra={showDeleteButton && <DeleteOutlined style={{ color: 'red', fontSize: '42px' }} onClick={() => setDeleteActive(true)} />}>
            <Descriptions.Item label="Id" labelStyle={{ alignItems: 'center' }}>
               {editItem.id}
            </Descriptions.Item>
            <Descriptions.Item label="Name" labelStyle={{ alignItems: 'center' }}>
               <Input name="name" value={editItem.name} onChange={(value) => onChange({ ...editItem, name: value.target.value })} />
            </Descriptions.Item>
            <Descriptions.Item label="Type" labelStyle={{ alignItems: 'center' }}>
               <EnumPicker value={editItem.type} from={EItemType} onChange={(type) => onChange({ ...editItem, type })} />
            </Descriptions.Item>
            <Descriptions.Item label="Value" labelStyle={{ alignItems: 'center' }}>
               <InputNumber name="value" value={editItem.value} onChange={(value) => onChange({ ...editItem, value })} />
            </Descriptions.Item>
            <Descriptions.Item label="CardType" labelStyle={{ alignItems: 'center' }}>
               <EnumPicker value={editItem.cardType} allowNull from={ECardType} onChange={(cardType) => onChange({ ...editItem, cardType })} />
            </Descriptions.Item>
            <Descriptions.Item label="Category" labelStyle={{ alignItems: 'center' }}>
               <Input name="category" value={editItem.category || ''} onChange={(event) => onChange({ ...editItem, category: event.target.value })} />
            </Descriptions.Item>
            <Descriptions.Item label="Alias" labelStyle={{ alignItems: 'center' }}>
               <Input name="alias" value={editItem.alias || ''} onChange={(event) => onChange({ ...editItem, alias: event.target.value })} />
            </Descriptions.Item>
         </Descriptions>
      </Card>
   )
}
