import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React from 'react'
import { ETagType, Tag } from '../../entities/Tag'
import TagLink from './tag-link'

interface TagsProps {
   tags: Tag[]
}

export default function ProfileTagsList({ tags }: TagsProps): React.ReactElement {
   const columns: ColumnsType<Tag> = [
      {
         title: 'Tag',
         key: 'tag',
         sorter: (a: Tag, b: Tag) => a.name.localeCompare(b.name),
         render: function render(tag: Tag) {
            return <TagLink tag={tag} />
         }
      },
      {
         title: 'Type',
         sorter: (a: Tag, b: Tag) => a.type - b.type,
         render: (tag: Tag) => ETagType[tag.type],
         filterMultiple: true,
         filters: Object.keys(ETagType)
            .map((x) => Number(x))
            .filter((x) => !isNaN(x) && x !== ETagType.Game)
            .map((key) => ({ value: key, text: ETagType[key] })),
         onFilter: (value, tag) => tag.type === value
      },
      {
         title: 'Category',
         dataIndex: 'category',
         key: 'category',
         sorter: (a: Tag, b: Tag) => a.category.localeCompare(b.category)
      },
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id'
      }
   ]

   return <Table columns={columns} dataSource={tags} />
}
