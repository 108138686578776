import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ShopItemApi from '../../api/ShopItemApi'
import ShopItemDetail from '../../components/shop-item/shopItem-detail'
import { useApiContext } from '../../context/api-context'
import { ShopItem } from '../../entities/ShopItem'

interface Props {
   id: string
}

export default function ShopItemPage({ match }: RouteComponentProps<Props>): React.ReactElement {
   const { AuthPending } = useApiContext()
   const [shopItem, setShopItem] = useState<ShopItem>()

   //onMount
   useEffect(() => {
      if (!AuthPending) {
         ShopItemApi.Get(match.params.id).then((_item) => setShopItem(_item.data))
      }
   }, [match, AuthPending])

   async function onSave(_item: ShopItem): Promise<boolean> {
      try {
         await ShopItemApi.Update(_item)
         return true
      } catch {
         return false
      }
   }

   return <>{shopItem && <ShopItemDetail showDeleteButton={true} onSave={onSave} shopItem={shopItem} />}</>
}
