import React from 'react'
import { Link } from 'react-router-dom'
import { Item } from '../../entities/Item'

interface ItemLinkProps {
   item: Item
}

export default function ItemLink({ item }: ItemLinkProps): React.ReactElement {
   return (
      <Link target="_blank" rel="noopener noreferrer" to={`/item/${item.id}`}>
         {item.name || 'NEW Item'}
      </Link>
   )
}
