import React from 'react'
import { Table } from 'antd'
import { LfgEntry } from '../../entities/LfgEntry'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import GameLink from '../games/game-link'

interface LfgListProps {
   entries: LfgEntry[] | undefined
}

export default function UserLfgList({ entries }: LfgListProps): React.ReactElement {
   const reducedColumns: ColumnsType<LfgEntry> = [
      {
         title: 'Game',
         render: (entry: LfgEntry) => <GameLink game={entry.game} />
      },
      {
         title: 'Create Date',
         dataIndex: 'startDate',
         key: 'startDate'
      },
      {
         title: 'Id',
         render: (entry) => (entry.id ? <Link to={`/lfg/${entry.id}`}>{entry.id}</Link> : 'None')
      }
   ]

   return <Table columns={reducedColumns} dataSource={entries} />
}
