import React from "react";
import { Link } from "react-router-dom";
import { Tag } from "../../entities/Tag";

interface TagLinkProps {
  tag: Tag;
}

export default function TagLink({ tag }: TagLinkProps): React.ReactElement {
  return <Link to={`/tag/${tag.id}`}>{tag.name || "NEW TAG"}</Link>;
}
