import React, { useEffect, useState } from 'react'
import { ReigniteDetailListUser } from '../../entities/ReigniteDetailListUser'
import { Button, Input, Table, TablePaginationConfig } from 'antd'
import { EUserRole } from '../../entities/EUserRole'
import moment from 'moment'
import UserLink from './user-link'
import { DownloadAsCsv } from '../../helper/DownloadHelper'
import { ColumnsType, FilterValue, SorterResult } from 'antd/lib/table/interface'
import UserApi from '../../api/api-user'

interface TableParams {
   pagination?: TablePaginationConfig
   sortField?: string
   sortOrder?: string
   filters?: Record<string, FilterValue | null>
}

export default function UserListDetail(): React.ReactElement {
   const [searchValue, setSearchValue] = useState<string>()
   const [data, setData] = useState<ReigniteDetailListUser[]>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      if (tableParams.pagination) {
         fetch(searchValue && searchValue?.length > 0 ? { current: 1, pageSize: 20 } : tableParams.pagination)
      }
   }, [searchValue])

   async function fetch(pagination: TablePaginationConfig) {
      setLoading(true)
      const result = await UserApi.GetUsersDetail(searchValue, pagination?.pageSize, pagination?.current)
      setData(result.items)
      setTableParams({
         ...tableParams,
         pagination: {
            total: result.pageInfo.TotalCount,
            current: result.pageInfo.CurrentPage,
            pageSize: result.pageInfo.PageSize
         }
      })
      setLoading(false)
   }

   function Download() {
      if (!data) return

      const formatUsers = data.map((user) => ({
         id: user.id,
         nickname: user.nickname,
         nicknameHash: user.nicknameHash,
         userRole: EUserRole[user.userRole],
         fireSoulGems: user.fireSoulGems,
         mailAdress: user.mailAdress,
         tutorialStep: EUserRole[user.tutorialStep],
         pwSet: user.pwSet,
         thirdPartyProviders: user.thirdPartyProvider.join(','),
         createdDate: user.userState.createdDate,
         lastModifyDate: user.userState.lastModifyDate,
         gameprofileCount: user.gameprofileCount,
         friendCount: user.friendCount
      }))

      DownloadAsCsv('users', formatUsers)
   }

   const columns: ColumnsType<ReigniteDetailListUser> = [
      {
         title: 'Name',
         render: function render(user: ReigniteDetailListUser) {
            return <UserLink user={user} />
         },
         sorter: (a: ReigniteDetailListUser, b: ReigniteDetailListUser) => a.nickname.localeCompare(b.nickname)
      },
      {
         title: 'Mail',
         dataIndex: 'mailAdress',
         sorter: (a: ReigniteDetailListUser, b: ReigniteDetailListUser) => a.mailAdress.localeCompare(b.mailAdress)
      },
      {
         title: 'Role',
         dataIndex: 'userRole',
         render: function render(role: EUserRole) {
            return EUserRole[role]
         },
         filters: [
            {
               text: 'Blocked',
               value: 7
            },
            {
               text: 'Unverified',
               value: 8
            },
            {
               text: 'ThirdPartyUser',
               value: 9
            },
            {
               text: 'User',
               value: 10
            },
            {
               text: 'Manager',
               value: 50
            },
            {
               text: 'SuperAdmin',
               value: 100
            }
         ],
         onFilter: (value: string | number | boolean, record: ReigniteDetailListUser) => record.userRole === value
      },
      {
         title: 'Providers',
         dataIndex: 'thirdPartyProvider',
         filters: [
            {
               text: 'None',
               value: 'none'
            },
            {
               text: 'Any',
               value: 'any'
            },
            {
               text: 'discord',
               value: 'discord'
            }
         ],
         onFilter: (value: string | number | boolean, record: ReigniteDetailListUser) => {
            if (value === 'none') {
               return record.thirdPartyProvider?.length === 0
            }
            if (value === 'any') {
               return record.thirdPartyProvider?.length > 0
            }
            return record.thirdPartyProvider?.includes(value as string)
         }
      },
      /*{
      title: "Gameprofile Count",
      dataIndex: "gameprofileCount",
      filters: [
        {
          text: "None",
          value: "none",
        },
        {
          text: ">0",
          value: 0,
        },
        {
          text: ">1",
          value: 1,
        },
        {
          text: ">2",
          value: 2,
        },
        {
          text: ">3",
          value: 3,
        },
        {
          text: ">4",
          value: 4,
        },
      ],
      onFilter: (
        value: string | number | boolean,
        record: ReigniteDetailListUser
      ) => {
        if (value === "none") {
          return record.gameprofileCount === 0;
        }
        return record.gameprofileCount > value;
      },
    },*/
      {
         title: 'AI Images Count',
         dataIndex: 'aiImageCount',
         filters: [
            {
               text: 'None',
               value: 'none'
            },
            {
               text: '>0',
               value: 0
            },
            {
               text: '>5',
               value: 5
            },
            {
               text: '>10',
               value: 10
            },
            {
               text: '>20',
               value: 20
            },
            {
               text: '>50',
               value: 50
            }
         ],
         onFilter: (value: string | number | boolean, record: ReigniteDetailListUser) => {
            if (value === 'none') {
               return record.aiImageCount === 0
            }
            return record.aiImageCount > Number(value)
         }
      },
      {
         title: 'Transactions Count',
         dataIndex: 'transactionCount',
         filters: [
            {
               text: 'None',
               value: 'none'
            },
            {
               text: '>0',
               value: 0
            },
            {
               text: '>2',
               value: 2
            },
            {
               text: '>4',
               value: 4
            },
            {
               text: '>6',
               value: 6
            },
            {
               text: '>8',
               value: 8
            }
         ],
         onFilter: (value: string | number | boolean, record: ReigniteDetailListUser) => {
            if (value === 'none') {
               return record.transactionCount === 0
            }
            return record.transactionCount > Number(value)
         }
      },
      /*{
      title: "Friends Count",
      dataIndex: "friendCount",
      filters: [
        {
          text: "None",
          value: "none",
        },
        {
          text: ">0",
          value: 0,
        },
        {
          text: ">1",
          value: 1,
        },
        {
          text: ">2",
          value: 2,
        },
        {
          text: ">3",
          value: 3,
        },
        {
          text: ">4",
          value: 4,
        },
      ],
      onFilter: (
        value: string | number | boolean,
        record: ReigniteDetailListUser
      ) => {
        if (value === "none") {
          return record.friendCount === 0;
        }
        return record.friendCount > value;
      },
    },*/
      /*{
      title: "FireSoulGems",
      dataIndex: "fireSoulGems",
      sorter: (a: ReigniteDetailListUser, b: ReigniteDetailListUser) =>
        a.fireSoulGems - b.fireSoulGems,
    },*/
      {
         title: 'PW Set',
         filters: [
            {
               text: 'true',
               value: true
            },
            {
               text: 'false',
               value: false
            }
         ],
         render: function render(a: ReigniteDetailListUser) {
            return a.pwSet.toString()
         },
         onFilter: (value: string | number | boolean, record: ReigniteDetailListUser) => value === record.pwSet
      },
      /*{
      title: "State",
      filters: [
        {
          text: "Online",
          value: EUserState.Online,
        },
        {
          text: "Offline",
          value: EUserState.Offline,
        },
        {
          text: "Away",
          value: EUserState.Away,
        },
      ],
      render: function render(a: ReigniteDetailListUser) {
        return a.userState ? EUserState[a.userState?.state] : "Unknown";
      },
      onFilter: (
        value: string | number | boolean,
        record: ReigniteDetailListUser
      ) => record.userState?.state === value,
    },*/
      /*{
      title: "Tutorial",
      dataIndex: "tutorialStep",
      filters: [
        {
          text: "New",
          value: EUserTutorialStep.New,
        },
        {
          text: "Closed",
          value: EUserTutorialStep.Closed,
        },
        {
          text: "Open",
          value: "open",
        },
      ],
      render: function render(a: EUserTutorialStep) {
        return EUserTutorialStep[a];
      },
      onFilter: (
        value: string | number | boolean,
        record: ReigniteDetailListUser
      ) => {
        if (value === "open") {
          return record.tutorialStep > 0;
        }
        return record.tutorialStep === value;
      },
    },*/
      {
         title: 'Created',
         defaultSortOrder: 'descend',
         sorter: (a: ReigniteDetailListUser, b: ReigniteDetailListUser) => moment(a.userState?.createdDate).unix() - moment(b.userState?.createdDate).unix(),
         render: function render(a: ReigniteDetailListUser) {
            return a.userState ? moment(a.userState?.createdDate).format('DD.MM.YYYY HH:mm') : 'Unknown'
         }
      }
      /*{
      title: "Last Online",
      sorter: (a: ReigniteDetailListUser, b: ReigniteDetailListUser) =>
        moment(a.userState?.lastOnlineDate).unix() -
        moment(b.userState?.lastOnlineDate).unix(),
      render: function render(a: ReigniteDetailListUser) {
        return a.userState
          ? moment(a.userState?.lastOnlineDate).format("DD.MM.YYYY HH:mm")
          : "Unknown";
      },
    },*/
   ]

   function onChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ReigniteDetailListUser> | SorterResult<ReigniteDetailListUser>[]
   ) {
      setTableParams({
         pagination,
         filters,
         ...sorter
      })

      fetch(pagination)
   }

   return (
      <>
         <Input.Search
            title="Search"
            placeholder="search for nickname, email or userId"
            onSearch={setSearchValue}
            allowClear
            style={{ width: 400, marginBottom: 5 }}
         />
         <Button type="primary" onClick={Download}>
            Export Result
         </Button>
         <Table columns={columns} rowKey={(record) => record.id} dataSource={data} pagination={tableParams.pagination} loading={loading} onChange={onChange} />
      </>
   )
}
