import { Dispatch, useEffect, useState } from 'react'

export default function useLocalStorage<T>(key: string): [T, Dispatch<T>] {
   const [value, setValue] = useState<T>(getStorageValue())

   function getStorageValue() {
      if (typeof window !== 'undefined') {
         const storageValue = localStorage.getItem(key)
         try {
            return storageValue ? JSON.parse(storageValue) : undefined
         } catch {
            console.warn('unable to parse json, from local storage!')
            localStorage.removeItem(key)
         }
      }
      return undefined
   }

   function setStorageValue(_value: T) {
      setValue(_value)
      if (_value) {
         localStorage.setItem(key, JSON.stringify(_value))
      } else {
         localStorage.removeItem(key)
      }
   }

   useEffect(() => {
      setValue(getStorageValue())
   }, [])

   return [value, setStorageValue]
}