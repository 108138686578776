import React, { useEffect, useState } from 'react'
import { ReigniteUser } from '../../entities/ReigniteUser'
import { Button, Input, Table, TablePaginationConfig } from 'antd'
import { EUserRole } from '../../entities/EUserRole'
import moment from 'moment'
import UserLink from './user-link'
import { EUserState } from '../../entities/EUserState'
import { DownloadAsCsv } from '../../helper/DownloadHelper'
import { ColumnsType, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'

interface UserListProps {
   users: ReigniteUser[]
   pagination?: false | TablePaginationConfig | undefined
}

export default function UserList({ users, pagination }: UserListProps): React.ReactElement {
   const [searchValue, setSearchValue] = useState<string>()
   const [resultUsers, setResultUsers] = useState<ReigniteUser[]>(users)
   const [usersInView, setUsersInView] = useState<ReigniteUser[]>(users)

   useEffect(() => {
      if (searchValue && searchValue.length > 0) {
         setResultUsers(
            users.filter(
               (user) =>
                  `${user.nickname}#${user.nicknameHash}`.toLowerCase().includes(searchValue.toLowerCase()) ||
                  user.id === searchValue ||
                  (user.mailAdress && user.mailAdress?.toLowerCase().includes(searchValue?.toLowerCase()))
            )
         )
      } else {
         setResultUsers(users)
      }
   }, [searchValue, users])

   function Download() {
      const formatUsers = usersInView.map((user) => ({
         id: user.id,
         nickname: user.nickname,
         nicknameHash: user.nicknameHash,
         userRole: EUserRole[user.userRole],
         fireSoulGems: user.fireSoulGems,
         mailAdress: user.mailAdress,
         tutorial: user.tutorialStep,
         pwSet: user.password != undefined,
         createdDate: user.userState.createdDate,
         lastModifyDate: user.userState.lastModifyDate
      }))

      DownloadAsCsv('users', formatUsers)
   }

   const columns: ColumnsType<ReigniteUser> = [
      {
         title: 'Name',
         key: 'id',
         render: function render(user: ReigniteUser) {
            return <UserLink user={user} />
         },
         sorter: (a: ReigniteUser, b: ReigniteUser) => a.nickname.localeCompare(b.nickname)
      },
      {
         title: 'Mail',
         dataIndex: 'mailAdress',
         sorter: (a: ReigniteUser, b: ReigniteUser) => a.mailAdress.localeCompare(b.mailAdress)
      },
      {
         title: 'Role',
         dataIndex: 'userRole',
         render: function render(role: EUserRole) {
            return EUserRole[role]
         },
         filters: [
            {
               text: 'Blocked',
               value: 7
            },
            {
               text: 'Unverified',
               value: 8
            },
            {
               text: 'ThirdPartyUser',
               value: 9
            },
            {
               text: 'User',
               value: 10
            },
            {
               text: 'Manager',
               value: 50
            },
            {
               text: 'SuperAdmin',
               value: 100
            }
         ],
         onFilter: (value: string | number | boolean, record: ReigniteUser) => record.userRole === value
      },
      {
         title: 'FireSoulGems',
         dataIndex: 'fireSoulGems',
         sorter: (a: ReigniteUser, b: ReigniteUser) => a.fireSoulGems - b.fireSoulGems
      },
      {
         title: 'PW Set',
         filters: [
            {
               text: 'true',
               value: true
            },
            {
               text: 'false',
               value: false
            }
         ],
         render: function render(a: ReigniteUser) {
            return (a.password != undefined).toString()
         },
         onFilter: (value: string | number | boolean, record: ReigniteUser) => (value ? record.password != undefined : record.password == undefined)
      },
      {
         title: 'State',
         filters: [
            {
               text: 'Online',
               value: EUserState.Online
            },
            {
               text: 'Offline',
               value: EUserState.Offline
            },
            {
               text: 'Away',
               value: EUserState.Away
            }
         ],
         render: function render(a: ReigniteUser) {
            return a.userState ? EUserState[a.userState?.state] : 'Unknown'
         },
         onFilter: (value: string | number | boolean, record: ReigniteUser) => record.userState?.state === value
      },
      {
         title: 'Created',
         defaultSortOrder: 'descend',
         sorter: (a: ReigniteUser, b: ReigniteUser) => moment(a.userState?.createdDate).unix() - moment(b.userState?.createdDate).unix(),
         render: function render(a: ReigniteUser) {
            return a.userState ? moment(a.userState?.createdDate).format('DD.MM.YYYY HH:mm') : 'Unknown'
         }
      },
      {
         title: 'Last Online',
         sorter: (a: ReigniteUser, b: ReigniteUser) => moment(a.userState?.lastOnlineDate).unix() - moment(b.userState?.lastOnlineDate).unix(),
         render: function render(a: ReigniteUser) {
            return a.userState ? moment(a.userState?.lastOnlineDate).format('DD.MM.YYYY HH:mm') : 'Unknown'
         }
      }
   ]

   function onChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ReigniteUser> | SorterResult<ReigniteUser>[],
      extra: TableCurrentDataSource<ReigniteUser>
   ) {
      setUsersInView(extra.currentDataSource)
   }

   return (
      <>
         <Input.Search
            title="Search"
            placeholder="search for nickname, email or userId"
            onSearch={setSearchValue}
            allowClear
            style={{ width: 400, marginBottom: 5 }}
         />
         <Button type="primary" onClick={Download}>
            Export Result
         </Button>
         <Table columns={columns} dataSource={resultUsers} pagination={pagination} onChange={onChange} />
      </>
   )
}
