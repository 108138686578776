import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { LfgEntry } from '../../entities/LfgEntry'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import GameLink from '../games/game-link'
import moment from 'moment'
import LfgApi from '../../api/lfg-api'
import { TableParams } from '../cards/cards-list'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'

interface LfgAllListProps {
   gameId?: string
}

export default function LfgList({ gameId }: LfgAllListProps): React.ReactElement {
   const [entries, setEntries] = useState<LfgEntry[]>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   async function fetch(_tableParams: TableParams) {
      setLoading(true)
      const response = await LfgApi.GetLfgEntries(gameId, _tableParams.pagination?.pageSize, _tableParams.pagination?.current)

      setEntries(response.items)
      setTableParams({
         ...tableParams,
         pagination: {
            total: response.pageInfo.TotalCount,
            current: response.pageInfo.CurrentPage,
            pageSize: response.pageInfo.PageSize
         }
      })

      setLoading(false)
   }

   function onChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<LfgEntry> | SorterResult<LfgEntry>[]
   ) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch(_tableParams)
   }

   useEffect(() => {
      fetch({ ...tableParams, pagination: { ...tableParams.pagination, current: 1 } })
   }, [])

   const columns: ColumnsType<LfgEntry> = [
      {
         title: 'Game',
         render: (entry: LfgEntry) => <GameLink game={entry.game} />
      },
      {
         title: 'Owner',
         render: (entry) =>
            entry.id ? (
               <Link to={`/user/${entry.owner?.id}`}>
                  {entry.owner?.nickname}#{entry.owner?.nicknameHash}
               </Link>
            ) : (
               'None'
            )
      },
      {
         title: 'Create Date',
         dataIndex: 'startDate',
         key: 'startDate',
         render: (value: Date) => moment(value).format('DD.MM.YYYY HH:mm')
      },
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id'
      }
   ]

   return <Table columns={columns} loading={loading} dataSource={entries} pagination={tableParams.pagination} onChange={onChange} />
}
