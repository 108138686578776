import moment from "moment"

export function DownloadAsCsv(name: string, data: any[]): void {
    const csv = ConvertToCsv(data)
    DownloadFile(new Blob([csv]), `${name}-${moment().format("YMMDD")}.csv`)
}

export function ConvertToCsv(data: any[]): string {
    const array = [Object.keys(data[0])].concat(data)

    return array.map(it => {
        return Object.values(it).join(";")
    }).join('\n')
}

export function DownloadFile(blob: Blob, fileName: string): void {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}
