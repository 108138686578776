import React, { useState } from 'react'
import { Card, Col, Image, Divider, Row, Descriptions, Checkbox, Button, message, Alert, Form, Input } from 'antd'
import noImage from '../../images/noImage.png'
import settings from '../../settings'
import { Link, useHistory } from 'react-router-dom'
import { AiImage, EUpscaleType, LeagueOfLegendsGenerateData } from '../../api/ImageApi'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import UserLink from '../user/user-link'
import { AiImageTheme, EThemeCategory } from '../../api/PromptApi'

interface ThemeDetailProps {
   theme: AiImageTheme
   onSave(theme: AiImageTheme): Promise<boolean>
   onRefresh?(): void
   showDeleteButton: boolean
}

export default function ThemeDetail({
   onSave,
   onRefresh = () => {
      return
   },
   theme: _theme
}: ThemeDetailProps): React.ReactElement {
   const [theme, setTheme] = useState(_theme)

   const [isChanged, setIsChange] = useState<boolean>(false)

   function onReset() {
      setIsChange(false)
      setTheme(_theme)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(theme).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   function onChange(editTheme: AiImageTheme) {
      setIsChange(true)
      setTheme(editTheme)
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}
         {/* {theme.isHidden != image.isHidden && <Button onClick={() => onSave(theme)}>Save</Button>} */}
         <Row>
            <Col flex="200px">
               <Image src={theme.imageUrl} width={500} fallback={noImage} />
            </Col>
            <Col flex="1">
               <Descriptions title="Theme Info" bordered>
                  <DescriptionsItem span={3} label={'Id'}>
                     {theme.id}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={theme.name} onChange={(e) => onChange({ ...theme, name: e.target.value })} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'ImageUrl'}>
                     <Input value={theme.imageUrl} onChange={(e) => onChange({ ...theme, imageUrl: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Type'}>
                     {EThemeCategory[theme.type]}
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
