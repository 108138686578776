import axios from 'axios'
import PageList from '../entities/PageList'
import { Price } from '../entities/Price'
import { EObtainState, ETransactionStatus, Transaction } from '../entities/Transaction'

const GetAll = async (
   status?: ETransactionStatus[],
   currency?: string,
   obtainItemState?: EObtainState[],
   q?: string,
   PageSize?: number,
   PageNumber?: number
): Promise<PageList<Transaction>> => {
   const params = new URLSearchParams()
   status?.forEach((stat) => params.append('status', stat.toFixed()))

   currency && params.append('currency', currency)

   obtainItemState?.forEach((itemState) => params.append('obtainItemState', itemState.toFixed()))

   q && params.append('q', q)

   PageSize && params.append('PageSize', PageSize.toString())

   PageNumber && params.append('PageNumber', PageNumber.toString())

   const response = await axios.get<Transaction[]>('/api/admin/transaction', { params: params })

   const pageInfo = JSON.parse(await response.headers['paging-headers'])
   return { items: response.data, pageInfo }
}
const Get = (id: string) => axios.get<Transaction>(`/api/admin/transaction/${id}`)
const GetSubscriptions = () => axios.get<SubscriptionRoot>(`/api/admin/transaction/subscriptions-mollie`)
const GetOrders = () => axios.get<PrintOrder[]>(`/api/admin/transaction/orders`)
const UpdateOrder = (order: PrintOrder) => axios.put<PrintOrder>(`/api/admin/transaction/orders/${order.id}`, order)
const Update = (item: Transaction) => axios.put<Transaction>(`/api/admin/transaction`, item)
const Create = (item: Transaction) => axios.post<Transaction>(`/api/admin/transaction`, item)
const Delete = (id: string) => axios.delete(`/api/admin/transaction/${id}`)

const TransactionApi = {
   GetAll,
   GetSubscriptions,
   GetOrders,
   UpdateOrder,
   Get,
   Update,
   Create,
   Delete
}

export interface SubscriptionRoot {
   _embedded: Subscription[]
}

export interface Subscription {
   id: string
   status: string
   amount: Price
}

export interface PrintOrder {
   id: string
   commissioned: boolean
   completed: boolean
   name: string
   surname: string
   city: string
   zip: string
   street: string
   number: string
   appartment: string
   mailadress: string
   phone: string
   imageUrl: string
   transaction: Transaction
   createdDate: Date
   lastModifyDate: Date
   transactionId: string | null
}

export default TransactionApi
