import React from "react";
import { Link } from "react-router-dom";
import { Game } from "../../entities/Game";

interface GameLinkProps {
    game: Game
}

export default function GameLink({ game }: GameLinkProps): React.ReactElement {
    return <Link to={`/game/${game.id}`}>{game.profileName || "NEW GAME"}</Link>
}