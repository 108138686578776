import React, { useEffect, useState } from 'react'
import { TableParams } from '../../../components/images/image-list'
import { Table, TablePaginationConfig, Image, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { Link, useHistory } from 'react-router-dom'
import TagApi from '../../../api/tags-api'
import { ETagType, Tag } from '../../../entities/Tag'

export default function ImageTagsAllPage(): React.ReactElement {
   const history = useHistory()
   const [data, setData] = useState<Tag[]>()
   const [searchValue, setSearchValue] = useState<string>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch()
   }, [])

   async function fetch() {
      setLoading(true)
      const result = await TagApi.GetTags([ETagType.AiImage])

      setData(result.data)

      setLoading(false)
   }

   const columns: ColumnsType<Tag> = [
      {
         title: 'Name',
         key: 'id',
         dataIndex: 'id',
         render: (value, tag) => (
            <a target="_blank" href={tag.id} rel="noreferrer">
               <Link target="_blank" rel="noopener noreferrer" to={`/image-tags/${tag.id}`}>
                  {tag.name}
               </Link>
            </a>
         )
      },
      {
         title: 'Alias',
         key: 'alias',
         dataIndex: 'alias'
      }
   ]

   function onChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<Tag> | SorterResult<Tag>[]) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch()
   }

   return (
      <>
         <Button onClick={() => history.push('/image-tags-new')}>Add</Button>
         <Table columns={columns} pagination={tableParams.pagination} loading={loading} dataSource={data} onChange={onChange} />
      </>
   )
}
