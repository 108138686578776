import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PlatformApi from '../../api/api-platform'
import LinkedPlatformsDetail from '../../components/linkedplatforms/linkedplatforms-detail'
import { LinkedPlatformSchema } from '../../entities/LinkedPlatformSchema'

export default function LinkedPlatformNewPage(): React.ReactElement {
   const [linkedplatform] = useState<LinkedPlatformSchema>({} as LinkedPlatformSchema)
   const history = useHistory()

   async function onSave(LinkedPlatform: LinkedPlatformSchema): Promise<boolean> {
      try {
         await PlatformApi.PostPlatformSchema(LinkedPlatform)
         history.push('/platformlinks-all')
         return true
      } catch {
         return false
      }
   }

   return <>{linkedplatform && <LinkedPlatformsDetail showDeleteButton={false} onSave={onSave} platform={linkedplatform} />}</>
}
