import React, { useState } from 'react'
import { Modal, Button, Descriptions, Card, Input, Form, Alert, message, InputNumber } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { EObtainState, ETransactionStatus, Transaction } from '../../entities/Transaction'
import TransactionApi from '../../api/TransactionApi'
import ShopItemLink from '../shop-item/shopItem-link'
import EnumPicker from '../picker/enum-picker'
import UserLink from '../user/user-link'

interface Props {
   transaction: Transaction
   onSave(transaction: Transaction): Promise<boolean>
   showDeleteButton: boolean
}

export default function TransactionDetail({ onSave, showDeleteButton, transaction }: Props): React.ReactElement {
   const history = useHistory()

   const [isChanged, setIsChange] = useState<boolean>(false)
   const [editTransaction, setEditTransaction] = useState<Transaction>(transaction)

   const [deleteActive, setDeleteActive] = useState(false)

   function onReset() {
      setIsChange(false)
      setEditTransaction(transaction)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(editTransaction).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   async function onDelete() {
      setDeleteActive(false)

      if (transaction.id) {
         try {
            const response = await TransactionApi.Delete(transaction.id)
            if (response.status == 200) {
               message.success('Deleted!')
               history.push('/transaction-all')
               return true
            }

            message.error(`Error response status ${response.statusText} (${response.status})`)
         } catch {
            return false
         }
      }

      return false
   }

   function onChange(_transaction: Transaction) {
      setIsChange(true)
      setEditTransaction(_transaction)
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}

         <Modal title={'Delete ' + editTransaction.id + '?'} visible={deleteActive} onOk={onDelete} onCancel={() => setDeleteActive(false)}>
            <p>Do you REALLY want to DELETE this transaction? This can not be reversed! Deleted objects are gone FOREVEEEEEEER!!</p>
         </Modal>

         <Descriptions
            column={1}
            title={editTransaction.id}
            extra={showDeleteButton && <DeleteOutlined style={{ color: 'red', fontSize: '42px' }} onClick={() => setDeleteActive(true)} />}>
            <Descriptions.Item label="Id" labelStyle={{ alignItems: 'center' }}>
               {editTransaction.id}
            </Descriptions.Item>
            <Descriptions.Item label="PaymentId" labelStyle={{ alignItems: 'center' }}>
               {editTransaction.paymentId}
            </Descriptions.Item>
            <Descriptions.Item label="Owner" labelStyle={{ alignItems: 'center' }}>
               <UserLink user={editTransaction.owner} />
            </Descriptions.Item>
            <Descriptions.Item label="ShopItem" labelStyle={{ alignItems: 'center' }}>
               <ShopItemLink shopItem={editTransaction.shopItem} />
            </Descriptions.Item>
            <Descriptions.Item label="Price" labelStyle={{ alignItems: 'center' }}>
               <InputNumber
                  name="value"
                  value={editTransaction.price.value}
                  onChange={(value) => onChange({ ...editTransaction, price: { ...editTransaction.price, value } })}
               />
               <Input
                  name="currency"
                  value={editTransaction.price.currency}
                  onChange={(event) => onChange({ ...editTransaction, price: { ...editTransaction.price, currency: event.target.value } })}
               />
            </Descriptions.Item>
            <Descriptions.Item label="Status" labelStyle={{ alignItems: 'center' }}>
               <EnumPicker from={ETransactionStatus} value={editTransaction.status} onChange={(status) => onChange({ ...editTransaction, status })} />
            </Descriptions.Item>
            <Descriptions.Item label="ErrorMessage" labelStyle={{ alignItems: 'center' }}>
               <Input value={editTransaction.errorMessage} disabled />
            </Descriptions.Item>
            <Descriptions.Item label="ObtainItemState" labelStyle={{ alignItems: 'center' }}>
               <EnumPicker
                  from={EObtainState}
                  value={editTransaction.obtainItemState}
                  onChange={(obtainItemState) => onChange({ ...editTransaction, obtainItemState })}
               />
            </Descriptions.Item>
         </Descriptions>
      </Card>
   )
}
