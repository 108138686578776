import React, { useState } from 'react'
import { Card, Col, Image, Divider, Row, Descriptions, Checkbox, Button } from 'antd'
import noImage from '../../images/noImage.png'
import settings from '../../settings'
import { Link, useHistory } from 'react-router-dom'
import AiImageApi, { AiImage, EUpscaleType, LeagueOfLegendsGenerateData } from '../../api/ImageApi'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import UserLink from '../user/user-link'
import moment from 'moment'

interface ImagePageProps {
   image: AiImage
   onSave(aiImage: AiImage): Promise<boolean>
   onRefresh?(): void
   showDeleteButton: boolean
}

export default function ImageDetail({
   onSave,
   onRefresh = () => {
      return
   },
   image
}: ImagePageProps): React.ReactElement {
   const [aiImage, setAiImage] = useState(image)
   const history = useHistory()

   async function deleteImage(id: string) {
      const response = await AiImageApi.DeleteImageById(id)
      if (response.status !== 200) {
         console.error(response.statusText)
         return
      }

      history.push('/images-all')
   }

   return (
      <Card>
         {aiImage.isHidden != image.isHidden && <Button onClick={() => onSave(aiImage)}>Save</Button>}
         <Row>
            <Col flex="200px">
               <Image src={aiImage.imageUrl} width={500} fallback={noImage} />
            </Col>
            <Col flex="1">
               <Descriptions title="AI Image Info" bordered>
                  <DescriptionsItem span={3} label={'Id'}>
                     {aiImage.id}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Seed'}>
                     {aiImage.seed}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'UpscaleType'}>
                     {EUpscaleType[aiImage.upscaleType]}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Upscaled From'}>
                     <Link target="_blank" rel="noopener noreferrer" to={`/image/${aiImage.upscaledFrom}`}>
                        {aiImage.upscaledFrom}
                     </Link>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Owner'}>
                     <UserLink user={aiImage.owner} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Skin'}>
                     {aiImage.skin}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Checkpoint'}>
                     <a target="_blank" href={aiImage.checkpointId} rel="noreferrer">
                        <Link target="_blank" rel="noopener noreferrer" to={`/checkpoints/${aiImage.checkpointId}`}>
                           {aiImage.checkpointId}
                        </Link>
                     </a>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Character'}>
                     <a target="_blank" href={aiImage.characterId} rel="noreferrer">
                        <Link target="_blank" rel="noopener noreferrer" to={`/characters/${aiImage.characterId}`}>
                           {aiImage.skin} {aiImage.name}
                        </Link>
                     </a>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'UserPrompt'}>
                     {aiImage.userPrompt}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Likes'}>
                     {aiImage.likedCount}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Impressions'}>
                     {aiImage.impressionCount}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'CreatedDate'}>
                     {moment(aiImage.createdDate).format('DD.MM.YYYY HH:mm:ss')}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'LastModifyDate'}>
                     {moment(aiImage.lastModifyDate).format('DD.MM.YYYY HH:mm:ss')}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Hidden'}>
                     <Checkbox checked={aiImage.isHidden} onChange={(e) => setAiImage({ ...aiImage, isHidden: e.target.checked })} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Deleet'}>
                     <Button onClick={(e) => deleteImage(image.id)}>Deleet</Button>
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
