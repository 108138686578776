import { Alert, Button, Card, Col, Descriptions, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import { DEFAULT_AWS_URL_OPTIMIZED } from '../../../api/ImageApi'
import TagApi from '../../../api/tags-api'
import { ETagType, Tag } from '../../../entities/Tag'

export default function ImageTagNewPage(): React.ReactElement {
   const history = useHistory()
   const [imagetag, setImageTag] = useState<Tag>({
      name: '',
      alias: '',
      category: '',
      type: ETagType.AiImage
   })
   const [isValid, setIsValid] = useState(false)

   const { Option } = Select

   async function onSave(): Promise<boolean> {
      try {
         if (imagetag == undefined) return false

         const response = await TagApi.CreateTag(imagetag)
         history.push('/image-tags/' + response.data.id)
         return true
      } catch {
         return false
      }
   }

   useEffect(() => {
      if (imagetag.name != '') setIsValid(true)
   }, [imagetag])

   return (
      <Card>
         {isValid ? (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSave}>
                     Submit
                  </Button>
               </Form.Item>
            </>
         ) : (
            <></>
         )}
         {/* {imagetag.isHidden != image.isHidden && <Button onClick={() => onSave(imagetag)}>Save</Button>} */}
         <Row>
            <Col flex="1">
               <Descriptions title="ImageTag Info" bordered>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={imagetag.name} onChange={(e) => setImageTag({ ...imagetag, name: e.target.value })} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Alias'}>
                     <Input value={imagetag.alias} onChange={(e) => setImageTag({ ...imagetag, alias: e.target.value })} />
                     <label>Komma- und Leerzeichengetrennt (z.B. Shore, Coast, Sands)</label>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'UseCount'}>
                     <Input value={imagetag.useCount} disabled />
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
