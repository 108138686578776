import React from 'react'
import { Link } from 'react-router-dom'

interface MiniUser {
   id: string
   nickname: string
   nicknameHash: string
}

interface UserLinkProps {
   user: MiniUser
}

export default function UserLink({ user }: UserLinkProps): React.ReactElement {
   return (
      <Link target="_blank" rel="noopener noreferrer" to={`/user/${user?.id}`}>
         {user?.nickname}#{user?.nicknameHash}
      </Link>
   )
}
