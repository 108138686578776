import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import CharacterDetail from '../../../components/images/character-detail'
import AiPromptApi, { AiImageCharacter } from '../../../api/PromptApi'

interface CharacterPageProps {
   id: string
}

export default function CharacterPage({ match }: RouteComponentProps<CharacterPageProps>): React.ReactElement {
   const [character, setCharacter] = useState<AiImageCharacter>()
   const history = useHistory()

   async function fetch() {
      setCharacter(undefined)
      const character = await AiPromptApi.GetCharacter(match.params.id)
      setCharacter(character.data)
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [match])

   async function onSave(_character: AiImageCharacter): Promise<boolean> {
      try {
         const response = await AiPromptApi.UpdateCharacter(_character)
         history.push('/characters/' + _character.id)
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!character && <Skeleton active />}
         {character && <CharacterDetail onSave={onSave} showDeleteButton onRefresh={fetch} character={character} />}
      </>
   )
}
