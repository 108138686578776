import { Input, Table } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import TransactionApi from '../../api/TransactionApi'
import { EObtainState, ETransactionStatus, Transaction } from '../../entities/Transaction'
import ShopItemLink from '../shop-item/shopItem-link'
import UserLink from '../user/user-link'
import TransactionLink from './transaction-link'

interface TableParams {
   pagination?: TablePaginationConfig
   sortField?: string
   sortOrder?: string
   filters?: Record<string, FilterValue | null>
}

export default function TransactionList(): React.ReactElement {
   const [data, setData] = useState<Transaction[]>()
   const [searchValue, setSearchValue] = useState<string>()
   const [loading, setLoading] = useState(false)
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 20
      }
   })

   useEffect(() => {
      fetch(tableParams)
   }, [searchValue])

   async function fetch(_tableParams: TableParams) {
      setLoading(true)
      const result = await TransactionApi.GetAll(
         _tableParams.filters?.status as any,
         _tableParams.filters?.price as any,
         _tableParams.filters?.obtainItemState as any,
         searchValue,
         _tableParams.pagination?.pageSize,
         _tableParams.pagination?.current
      )
      setData(result.items)
      setTableParams({
         ..._tableParams,
         pagination: {
            total: result.pageInfo.TotalCount,
            current: result.pageInfo.CurrentPage,
            pageSize: result.pageInfo.PageSize
         }
      })
      setLoading(false)
   }

   const columns: ColumnsType<Transaction> = [
      {
         title: 'Id',
         key: 'id',
         render: function render(transaction: Transaction) {
            return <TransactionLink transaction={transaction} />
         }
      },
      {
         title: 'ShopItem',
         key: 'shopItem',
         render: function render(transaction: Transaction) {
            return <ShopItemLink shopItem={transaction.shopItem} />
         }
      },
      {
         title: 'Status',
         dataIndex: 'status',
         key: 'status',
         render: (value: number) => ETransactionStatus[value],
         filterMultiple: true,
         filters: [
            {
               text: 'Open',
               value: ETransactionStatus.Open
            },
            {
               text: 'Payed',
               value: ETransactionStatus.Payed
            },
            {
               text: 'Cancel',
               value: ETransactionStatus.Cancel
            },
            {
               text: 'Error',
               value: ETransactionStatus.Error
            }
         ]
      },
      {
         title: 'Price',
         key: 'price',
         render: (transaction: Transaction) => `${transaction.price.value} ${transaction.price.currency}`,
         filterMultiple: false,
         filters: [
            {
               text: 'REALMONEY',
               value: 'NOTGEMS'
            },
            {
               text: 'FREE',
               value: 'FREE'
            },
            {
               text: 'GEMS',
               value: 'GEMS'
            },
            {
               text: 'EUR',
               value: 'EUR'
            },
            {
               text: 'USD',
               value: 'USD'
            }
         ]
      },
      {
         title: 'Buyer',
         key: 'owner',
         render: (transaction: Transaction) => <UserLink user={transaction.owner} />
      },
      {
         title: 'ObtainItemState',
         dataIndex: 'obtainItemState',
         key: 'obtainItemState',
         render: (value: number) => EObtainState[value],
         filterMultiple: true,
         filters: [
            {
               text: 'Full',
               value: EObtainState.Full
            },
            {
               text: 'Partly',
               value: EObtainState.Partly
            },
            {
               text: 'None',
               value: EObtainState.None
            }
         ]
      },
      {
         title: 'Created',
         dataIndex: 'createdDate',
         key: 'createdDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      },
      {
         title: 'LastModifyDate',
         dataIndex: 'lastModifyDate',
         key: 'lastModifyDate',
         render: function render(date: Date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
         }
      }
   ]

   function onChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<Transaction> | SorterResult<Transaction>[]
   ) {
      const _tableParams = {
         pagination,
         filters,
         ...sorter
      }

      setTableParams(_tableParams)
      fetch(_tableParams)
   }

   useEffect(() => {
      fetch(tableParams)
   }, [])

   return (
      <>
         <Input.Search
            title="Search"
            placeholder="search for transactionId or paymentId"
            onSearch={setSearchValue}
            allowClear
            style={{ width: 400, marginBottom: 5 }}
         />
         <Table columns={columns} pagination={tableParams.pagination} loading={loading} dataSource={data} onChange={onChange} />
      </>
   )
}
