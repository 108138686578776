import React from 'react'
import { Link } from 'react-router-dom'
import { Transaction } from '../../entities/Transaction'

interface Props {
   transaction: Transaction
}

export default function TransactionLink({ transaction }: Props): React.ReactElement {
   return (
      <Link rel="noreferrer" target="_blank" to={`/transaction/${transaction.id}`}>
         {transaction.id}
      </Link>
   )
}
