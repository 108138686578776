import { Table, Image } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { LinkedPlatformSchema } from "../../entities/LinkedPlatformSchema";
import settings from "../../settings";


interface LinkedPlatformsListProps {
    linkedPlatforms: LinkedPlatformSchema[]
}

export default function LinkedPlatformsList({ linkedPlatforms }: LinkedPlatformsListProps): React.ReactElement {

    const columns = [
        {
            title: 'Logo',
            dataIndex: 'iconImageFileId',
            key: 'iconImageFileId',
            render: function render(fileId: string) { return <img width={40} src={settings.GetFileUrl(fileId)} /> },
        },
        {
            title: 'Name',
            dataIndex: 'platformName',
            key: 'platformName',
            render: function render(text: string, record: LinkedPlatformSchema) { return <Link to={`/platformlinks/${record.id}`}>{text}</Link> },
            sorter: (a: LinkedPlatformSchema, b: LinkedPlatformSchema) => a.platformName.localeCompare(b.platformName),
        },
        {
            title: 'User Name Example',
            dataIndex: 'userNameExample',
            key: 'userNameExample',
        },
        {
            title: 'User Name Regex',
            dataIndex: 'userNameRegex',
            key: 'userNameRegex',
        },
        {
            title: 'User Link Example',
            dataIndex: 'userLinkExample',
            key: 'userLinkExample',
            render: function render(text: string) { return <>{text !== null ? text : "N/A"}</> }
        },
        {
            title: 'User Link Regex',
            dataIndex: 'userLinkRegex',
            key: 'userLinkRegex',
            render: function render(text: string) { return <>{text !== null ? text : "N/A"}</> }
        },
        {
            title: 'Created',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a: LinkedPlatformSchema, b: LinkedPlatformSchema) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
    ];


    return <>
        <Table columns={columns} dataSource={linkedPlatforms} pagination={false} />
    </>
}