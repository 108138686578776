import { message, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import GameApi from '../../api/game-api'
import NewsApi from '../../api/news-api'
import GameDetail from '../../components/games/game-detail'
import { Game } from '../../entities/Game'
import { News } from '../../entities/News'

interface GamePageProps {
   id: string
}

export default function GamePage({ match }: RouteComponentProps<GamePageProps>): React.ReactElement {
   const [game, setGame] = useState<Game>()
   const history = useHistory()

   async function fetch() {
      const result = await GameApi.GetGame(match.params.id)
      if (result.status !== 200) {
         message.error(`response ${result.statusText} (${result.status})`)
         return
      }
      setGame(result.data)
   }

   useEffect(() => {
      fetch()
   }, [match])

   async function onSave(Game: Game): Promise<boolean> {
      try {
         if (Game.published && confirm('Is this a major update and should users GameProfiles be marked as outdated?')) {
            Game.lastMajorModifyDate = new Date()
         }
         setGame(undefined)
         const gameResponse = await GameApi.PutGame(Game)
         if (gameResponse.status !== 200) {
            message.error(`response ${gameResponse.statusText} (${gameResponse.status})`)
            return false
         }

         if (!game?.published && Game.published && confirm('You have published a game profile, do you want to announce it as news?')) {
            const newsResponse = await NewsApi.Create({
               title: `${Game.profileName} added`,
               href: `/game/${Game.id}/settings`,
               mainImageId: Game.coverImageFileId,
               mainText: undefined,
               published: true
            } as News)

            if (newsResponse.status !== 200) {
               message.error(`response ${gameResponse.statusText} (${gameResponse.status})`)
            } else {
               history.push(`/news/${newsResponse.data.id}`)
            }
         } else {
            setGame(gameResponse.data)
         }
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!game && <Spin size="large" />}
         {game && <GameDetail showDeleteButton={true} onSave={onSave} game={game} />}
      </>
   )
}
