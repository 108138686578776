import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import AiImageApi, { AiImage } from '../../api/ImageApi'
import ImageDetail from '../../components/images/image-detail'

interface ImagePageProps {
   id: string
}

export default function ImagePage({ match }: RouteComponentProps<ImagePageProps>): React.ReactElement {
   const [image, setImage] = useState<AiImage>()
   const history = useHistory()

   async function fetch() {
      setImage(undefined)
      const image = await AiImageApi.GetImage(match.params.id)
      setImage(image.data)
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [match])

   async function onSave(image: AiImage): Promise<boolean> {
      try {
         const response = await AiImageApi.UpdateVisibleState(image.isHidden, image.id)
         history.push('/image/' + image.id)
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!image && <Skeleton active />}
         {image && <ImageDetail onSave={onSave} showDeleteButton onRefresh={fetch} image={image} />}
      </>
   )
}
