import React, { useState } from 'react'
import { Card, Col, Image, Divider, Row, Descriptions, Checkbox, Button, Input, message, Alert, Form } from 'antd'
import noImage from '../../images/noImage.png'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import { Tag } from '../../entities/Tag'

interface ImageTagDetailProps {
   imagetag: Tag
   onSave(imagetag: Tag): Promise<boolean>
   onRefresh?(): void
   showDeleteButton: boolean
}

export default function ImageTagDetail({
   onSave,
   onRefresh = () => {
      return
   },
   imagetag: _imagetag
}: ImageTagDetailProps): React.ReactElement {
   const [imagetag, setImageTag] = useState(_imagetag)

   const [isChanged, setIsChange] = useState<boolean>(false)

   function onReset() {
      setIsChange(false)
      setImageTag(_imagetag)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(imagetag).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   function onChange(editImageTag: Tag) {
      setIsChange(true)
      setImageTag(editImageTag)
   }

   return (
      <Card>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}
         {/* {aiImage.isHidden != image.isHidden && <Button onClick={() => onSave(aiImage)}>Save</Button>} */}
         <Row>
            <Col flex="1">
               <Descriptions title="ImageTag Info" bordered>
                  <DescriptionsItem span={3} label={'Id'}>
                     {imagetag.id}
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={imagetag.name} onChange={(e) => onChange({ ...imagetag, name: e.target.value })} />
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Alias'}>
                     <Input value={imagetag.alias} onChange={(e) => onChange({ ...imagetag, alias: e.target.value })} />
                     <label>Komma- und Leerzeichengetrennt (z.B. Shore, Coast, Sands)</label>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'UseCount'}>
                     <Input value={imagetag.useCount} disabled />
                  </DescriptionsItem>
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
