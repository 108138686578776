import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import UserApi from '../../api/api-user'
import UserDetail from '../../components/user/user-detail'
import { ReigniteUser } from '../../entities/ReigniteUser'

interface UserPageProps {
   id: string
}

export default function UserPage({ match }: RouteComponentProps<UserPageProps>): React.ReactElement {
   const [user, setUser] = useState<ReigniteUser>()
   const history = useHistory()

   function fetch() {
      setUser(undefined)
      UserApi.GetUser(match.params.id).then((_user) => setUser(_user.data))
   }

   //onMount
   useEffect(fetch, [match])

   async function onSave(ReigniteUser: ReigniteUser): Promise<boolean> {
      try {
         await UserApi.PutUser(ReigniteUser)
         history.push('/user-all')
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {!user && <Skeleton active />}
         {user && <UserDetail onSave={onSave} showDeleteButton onRefresh={fetch} user={user} />}
      </>
   )
}
