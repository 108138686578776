import React, { useEffect, useState } from 'react'
import PlatformApi from '../../api/api-platform'
import LinkedPlatformsList from '../../components/linkedplatforms/linkedplatforms-list'
import { useApiContext } from '../../context/api-context'
import { LinkedPlatformSchema } from '../../entities/LinkedPlatformSchema'

export default function LinkedPlatformAllPage(): React.ReactElement {
   const [linkedplatform, setPlatform] = useState<LinkedPlatformSchema[]>()
   const apiContext = useApiContext()

   //onMount
   useEffect(() => {
      PlatformApi.GetPlatformSchemas().then((profiles) => {
         setPlatform(profiles?.data)
      })
   }, [])

   return <>{linkedplatform && <LinkedPlatformsList linkedPlatforms={linkedplatform} />}</>
}
