import {
   CopyOutlined,
   FileImageOutlined,
   LinkOutlined,
   LogoutOutlined,
   MonitorOutlined,
   NotificationOutlined,
   PieChartOutlined,
   PlusOutlined,
   ReconciliationOutlined,
   ShopOutlined,
   TagsOutlined,
   ToolOutlined,
   TrophyOutlined,
   UserAddOutlined,
   UserOutlined
} from '@ant-design/icons'
import { Layout, Menu /*, Breadcrumb*/, Switch as ToggleSwitch, message } from 'antd'
import type { MenuInfo } from 'rc-menu/lib/interface'
import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import AiImageApi from '../api/ImageApi'
import GameApi from '../api/game-api'
import NewsApi from '../api/news-api'
import TagApi from '../api/tags-api'
import { useApiContext } from '../context/api-context'
import { Game } from '../entities/Game'
import { News } from '../entities/News'
import { Tag } from '../entities/Tag'
import IgdbLogo from '../images/igdb-logo.png'
import logo from '../logo.svg'
import CardsAllPage from '../pages/card/cards-all-page'
import NewGameIgdb from '../pages/game/game-igdb'
import GameLfgPage from '../pages/game/game-lfg-page'
import GamePage from '../pages/game/game-page'
import GameTagsPage from '../pages/game/game-tags-page'
import GameprofileAllContent from '../pages/game/games-all-page'
import HangfireDashboardPage from '../pages/hangfire-dashboard-page'
import CharacterPage from '../pages/images/characters/character-page'
import CharactersAllPage from '../pages/images/characters/characters-all-page'
import CharacterNewPage from '../pages/images/characters/characters-new'
import CheckpointPage from '../pages/images/checkpoints/checkpoint-page'
import CheckpointsAllPage from '../pages/images/checkpoints/checkpoints-all-page'
import CheckpointNewPage from '../pages/images/checkpoints/checkpoints-new'
import ImagesForbiddenWordsPage from '../pages/images/image-forbidden-words-page'
import ImagePage from '../pages/images/image-page'
import ImagesAllPage from '../pages/images/images-all-page'
import ImagesCuratePage from '../pages/images/images-curate-page'
import ImagesHotPage from '../pages/images/images-hot-page'
import ImagesNewestPage from '../pages/images/images-newest-page'
import MetricsAllPage from '../pages/images/metrics/metrics-all-page'
import MetricsPage from '../pages/images/metrics/metrics-page'
import StyleNewPage from '../pages/images/styles/style-new'
import StylePage from '../pages/images/styles/style-page'
import StylesAllPage from '../pages/images/styles/styles-all-page'
import ImageTagPage from '../pages/images/tags/tag-page'
import ImageTagsAllPage from '../pages/images/tags/tags-all-page'
import ImageTagNewPage from '../pages/images/tags/tags-new'
import ThemeNewPage from '../pages/images/themes/theme-new'
import ThemePage from '../pages/images/themes/theme-page'
import ThemesAllPage from '../pages/images/themes/themes-all-page'
import ItemAllPage from '../pages/item-all-page'
import ItemPage from '../pages/item-page'
import LfgAllPage from '../pages/lfg/lfg-all-page'
import LinkedPlatformAllPage from '../pages/linkedplatfrom/linkedplatforms-all-page'
import LinkedPlatformNewPage from '../pages/linkedplatfrom/linkedplatforms-new-page'
import LinkedPlatformPage from '../pages/linkedplatfrom/linkedplatforms-page'
import MePage from '../pages/me-page'
import NewsAllPage from '../pages/news/news-all-page'
import NewsPage from '../pages/news/news-page'
import NotFoundPage from '../pages/not-found'
import OrderPage from '../pages/orders/order-detail-page'
import OrdersPage from '../pages/orders/orders-all-page'
import ShopItemAllPage from '../pages/shop/shopItem-all-page'
import ShopItemPage from '../pages/shop/shopItem-page'
import TransactionAllPage from '../pages/shop/transaction-all-page'
import TransactionPage from '../pages/shop/transaction-page'
import TagPage from '../pages/tag/tag-page'
import GameTagsAllPage from '../pages/tag/tags-games-all-page'
import TagsNewPage from '../pages/tag/tags-new-page'
import ProfileTagsAllPage from '../pages/tag/tags-profile-all-page'
import UserAllPage from '../pages/user/user-all-page'
import DashboardPage from '../pages/user/user-insights-page'
import UserInsightsPageV2 from '../pages/user/user-insights-page-v2'
import UserInvitePage from '../pages/user/user-invite-page'
import UserNewPage from '../pages/user/user-new-page'
import UserPage from '../pages/user/user-page'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

export default function MasterLayout(): React.ReactElement {
   const history = useHistory()
   const [collapsed, setCollapsed] = useState(false)
   const [testing, setTesting] = useState<boolean>()
   const api = useApiContext()

   function onCollapse(collapsed: boolean) {
      setCollapsed(collapsed)
   }

   function handleMenuClick(info: MenuInfo) {
      if (info.key !== undefined && info.key.length > 0) {
         history.push(info.key)
      }
   }

   function handleLogout() {
      api?.Logout()
   }

   async function CreateGame() {
      const result = await GameApi.PostGame({} as Game)
      if (result.status === 200) {
         history.push(`/game/${result.data.id}`)
         message.success('Game Created')
      } else {
         message.error(`response ${result.statusText} (${result.status})`)
      }
   }

   async function CreateTag() {
      const result = await TagApi.CreateTag({} as Tag)
      if (result.status === 200) {
         history.push(`/tag/${result.data.id}`)
         message.success('TagCreated')
      } else {
         message.error(`response ${result.statusText} (${result.status})`)
      }
   }

   async function CreateNews() {
      const result = await NewsApi.Create({} as News)
      if (result.status === 200) {
         history.push(`/news/${result.data.id}`)
         message.success('News Created')
      } else {
         message.error(`response ${result.statusText} (${result.status})`)
      }
   }

   useEffect(() => {
      getTestingStatus()
   }, [])

   function handleCheckboxChange(checked: boolean) {
      updateTestingStatus(checked)
      setTesting(checked)
   }

   async function updateTestingStatus(testing: boolean) {
      const response = await AiImageApi.UpdateTestserver(testing)
      if (response.status !== 200) {
         return
      }
   }

   async function getTestingStatus() {
      const response = await AiImageApi.GetIsTestserver()
      setTesting(response.data)
   }

   return (
      <Layout style={{ minHeight: '100vh' }}>
         <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div className="logo">
               <img src={logo} style={{ height: '90px', width: '100%' }} className="App-logo" alt="logo" />
            </div>
            <Menu onClick={handleMenuClick} theme="dark" defaultSelectedKeys={['1']} mode="inline">
               <Menu.Item key="/dashboard-v2" icon={<PieChartOutlined />}>
                  Dashboard
               </Menu.Item>

               <Menu.Item key="/hangfire" icon={<ReconciliationOutlined />}>
                  Hangfire
               </Menu.Item>

               <Menu.Item key="/orders" icon={<ShopOutlined />}>
                  Orders
               </Menu.Item>

               <Menu.Item key="/me" icon={<UserOutlined />}>
                  Me
               </Menu.Item>

               <SubMenu key="sub1" icon={<UserOutlined />} title="User">
                  <Menu.Item key="/user-all">All User</Menu.Item>
                  <Menu.Item key="/user-new" icon={<PlusOutlined />}>
                     New User
                  </Menu.Item>
                  <Menu.Item key="/user-invite" icon={<UserAddOutlined />}>
                     Invite Users
                  </Menu.Item>
               </SubMenu>

               <SubMenu key="aiimages" icon={<FileImageOutlined />} title="AiImages">
                  <Menu.Item key="/images-all" icon={<FileImageOutlined />}>
                     All
                  </Menu.Item>
                  <Menu.Item key="/images-raw" icon={<FileImageOutlined />}>
                     Curate
                  </Menu.Item>
                  <Menu.Item key="/images-newest" icon={<FileImageOutlined />}>
                     Newest
                  </Menu.Item>
                  <Menu.Item key="/images-hot" icon={<FileImageOutlined />}>
                     Hot
                  </Menu.Item>
                  <SubMenu key="checkpoints" title="Prompting">
                     <Menu.Item key="/image-tags">Tags</Menu.Item>
                     <Menu.Item key="/checkpoints">Checkpoints</Menu.Item>
                     <Menu.Item key="/themes">Themes</Menu.Item>
                     <Menu.Item key="/characters">Characters</Menu.Item>
                     <Menu.Item key="/styles">Styles</Menu.Item>
                     <Menu.Item key="/images-forbidden-words">Forbidden Words</Menu.Item>
                  </SubMenu>
                  <Menu.Item key="/metrics" icon={<MonitorOutlined />}>
                     Metrics
                  </Menu.Item>
               </SubMenu>

               <SubMenu key="sub5" icon={<ShopOutlined />} title="Shop">
                  <Menu.Item key="/transaction">Transactions</Menu.Item>
                  <Menu.Item key="/shop-item">Shop Items</Menu.Item>
                  <Menu.Item key="/item">Items</Menu.Item>
               </SubMenu>

               {/*                <Menu.Item key="/lfg-all" icon={<AliwangwangOutlined />}>
                  LFG Entries
               </Menu.Item> */}

               <SubMenu key="legacy" icon={<ToolOutlined />} title="Legacy">
                  <SubMenu key="sub4" icon={<LinkOutlined />} title="PlatformLinks">
                     <Menu.Item key="/platformlinks-all">All Platforms</Menu.Item>
                     <Menu.Item key="/platformlinks-new" icon={<PlusOutlined />}>
                        New Platform
                     </Menu.Item>
                  </SubMenu>
                  <SubMenu key="tag" icon={<TagsOutlined />} title="Tags">
                     <Menu.Item key="/tags-profile-all">All Profile Tags</Menu.Item>
                     <Menu.Item key="/tags-games-all">All Game Tags</Menu.Item>
                     <Menu.Item onClick={CreateTag} icon={<PlusOutlined />}>
                        New Tag
                     </Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub3" icon={<NotificationOutlined />} title="News">
                     <Menu.Item key="/news-all">All News</Menu.Item>
                     <Menu.Item onClick={CreateNews} icon={<PlusOutlined />}>
                        New News
                     </Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub2" icon={<TrophyOutlined />} title="Games">
                     <Menu.Item key="/games-all">All Games</Menu.Item>
                     <Menu.Item key="/game-igdb" icon={<img src={IgdbLogo} width="40px" />}>
                        Import
                     </Menu.Item>
                     <Menu.Item onClick={CreateGame} icon={<PlusOutlined />}>
                        New Game
                     </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="/cards" icon={<CopyOutlined />}>
                     Cards
                  </Menu.Item>
               </SubMenu>

               <Menu.Item key="/" onClick={handleLogout} icon={<LogoutOutlined />}>
                  Logout
               </Menu.Item>
               <div style={{ marginLeft: '20px', marginTop: '20px' }}>
                  {testing !== undefined && (
                     <label>
                        Backup-Server <ToggleSwitch defaultChecked={testing} onChange={handleCheckboxChange} />
                     </label>
                  )}
               </div>
            </Menu>
         </Sider>
         <Layout className="site-layout">
            <Header className="site-layout-background">
               <h1 style={{ color: 'white' }}>Update: Game TAGs 🎮🏷️ LFGs 👥 Game Search 🎮🔍</h1>
            </Header>
            <Content style={{ margin: '5px 16px', overflowY: 'auto', height: '84vh' }}>
               <Switch>
                  <Route exact path="/" component={UserInsightsPageV2} />
                  <Route exact path="/dashboard" component={DashboardPage} />
                  <Route exact path="/dashboard-v2" component={UserInsightsPageV2} />
                  <Route exact path="/hangfire" component={HangfireDashboardPage} />
                  <Route exact path="/me" component={MePage} />
                  <Route exact path="/orders" component={OrdersPage} />
                  <Route exact path="/orders/:transactionReference" component={OrderPage} />
                  <Route exact path="/tags-profile-all" component={ProfileTagsAllPage} />
                  <Route exact path="/tags-games-all" component={GameTagsAllPage} />
                  <Route exact path="/tags/:gameId" component={GameTagsPage} />
                  <Route exact path="/tags-new" component={TagsNewPage} />
                  <Route exact path="/cards" component={CardsAllPage} />
                  <Route exact path="/images-raw" component={ImagesCuratePage} />
                  <Route exact path="/images-all" component={ImagesAllPage} />
                  <Route exact path="/images-newest" component={ImagesNewestPage} />
                  <Route exact path="/images-hot" component={ImagesHotPage} />
                  <Route exact path="/images-forbidden-words" component={ImagesForbiddenWordsPage} />
                  <Route exact path="/image/:id" component={ImagePage} />
                  <Route exact path="/image-tags" component={ImageTagsAllPage} />
                  <Route exact path="/image-tags-new" component={ImageTagNewPage} />
                  <Route exact path="/image-tags/:id" component={ImageTagPage} />
                  <Route exact path="/checkpoints" component={CheckpointsAllPage} />
                  <Route exact path="/checkpoints-new" component={CheckpointNewPage} />
                  <Route exact path="/checkpoints/:id" component={CheckpointPage} />
                  <Route exact path="/themes" component={ThemesAllPage} />
                  <Route exact path="/themes-new" component={ThemeNewPage} />
                  <Route exact path="/themes/:id" component={ThemePage} />
                  <Route exact path="/characters" component={CharactersAllPage} />
                  <Route exact path="/characters-new" component={CharacterNewPage} />
                  <Route exact path="/characters/:id" component={CharacterPage} />
                  <Route exact path="/styles" component={StylesAllPage} />
                  <Route exact path="/styles-new" component={StyleNewPage} />
                  <Route exact path="/styles/:id" component={StylePage} />
                  <Route exact path="/metrics" component={MetricsAllPage} />
                  <Route exact path="/metrics/:id" component={MetricsPage} />

                  <Route exact path="/lfg-all" component={LfgAllPage} />
                  <Route exact path="/lfg/:gameId" component={GameLfgPage} />
                  <Route exact path="/user-all" component={UserAllPage} />
                  <Route exact path="/user-new" component={UserNewPage} />
                  <Route exact path="/user-invite" component={UserInvitePage} />
                  <Route exact path="/user/:id" component={UserPage} />
                  <Route exact path="/games-all" component={GameprofileAllContent} />
                  <Route exact path="/game/:id" component={GamePage} />
                  <Route exact path="/game-igdb" component={NewGameIgdb} />
                  <Route exact path="/tag/:id" component={TagPage} />
                  <Route exact path="/platformlinks-all" component={LinkedPlatformAllPage} />
                  <Route exact path="/platformlinks-new" component={LinkedPlatformNewPage} />
                  <Route exact path="/platformlinks/:id" component={LinkedPlatformPage} />
                  <Route exact path="/news-all" component={NewsAllPage} />
                  <Route exact path="/news/:id" component={NewsPage} />
                  <Route exact path="/item" component={ItemAllPage} />
                  <Route exact path="/item/:id" component={ItemPage} />
                  <Route exact path="/shop-item" component={ShopItemAllPage} />
                  <Route exact path="/shop-item/:id" component={ShopItemPage} />
                  <Route exact path="/transaction" component={TransactionAllPage} />
                  <Route exact path="/transaction/:id" component={TransactionPage} />
                  <Route component={NotFoundPage} />
               </Switch>
            </Content>
            <Footer style={{ textAlign: 'center' }}>re:ignite Admin Frontend ©2024 Created by Flo301 &#38; Crew &#9829;</Footer>
         </Layout>
      </Layout>
   )
}
