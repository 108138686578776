import axios from "axios";
import { ShopItem } from "../entities/ShopItem";

const GetAll = () => axios.get<ShopItem[]>("/api/admin/shop-item");
const Get = (id: string) => axios.get<ShopItem>(`/api/admin/shop-item/${id}`);
const Update = (item: ShopItem) => axios.put<ShopItem>(`/api/admin/shop-item`, item);
const Create = (item: ShopItem) => axios.post<ShopItem>(`/api/admin/shop-item`, item);
const Delete = (id: string) => axios.delete(`/api/admin/shop-item/${id}`);

const ShopItemApi = {
  GetAll,
  Get,
  Update,
  Create,
  Delete
};

export default ShopItemApi;
