/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import { LinkedPlatformSchema } from "../entities/LinkedPlatformSchema";

function GetPlatformSchemas() {
  return axios.get<LinkedPlatformSchema[]>(`/api/admin/linkedplatform`);
}

function GetPlatformSchema(id: string) {
  return axios.get<LinkedPlatformSchema>(`/api/admin/linkedplatform/${id}`);
}

function DeletePlatformSchema(id: string) {
  return axios.delete(`/api/admin/linkedplatform/${id}`);
}

function PostPlatformSchema(obj: LinkedPlatformSchema) {
  return axios.post<LinkedPlatformSchema>(`/api/admin/linkedplatform`, obj);
}

function PutPlatformSchema(obj: LinkedPlatformSchema) {
  return axios.put<LinkedPlatformSchema>(`/api/admin/linkedplatform`, obj);
}

function PostIconImage(platformId: string, imageFile: Blob) {
  const body = new FormData();
  body.append("imageFile", imageFile);

  return axios.post(`/api/admin/linkedplatform/${platformId}/icon`, body);
}

const PlatformApi = {
  GetPlatformSchemas,
  GetPlatformSchema,
  DeletePlatformSchema,
  PostPlatformSchema,
  PutPlatformSchema,
  PostIconImage,
};

export default PlatformApi;
