import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ItemApi from "../api/ItemApi";
import ItemDetail from "../components/item/item-detail";
import { useApiContext } from "../context/api-context";
import { Item } from "../entities/Item";

interface LinkedPlatformsPageProps {
    id: string
}

export default function ItemPage({ match }: RouteComponentProps<LinkedPlatformsPageProps>): React.ReactElement {
    const { AuthPending } = useApiContext();
    const [item, setItem] = useState<Item>();

    //onMount
    useEffect(() => {
        if (!AuthPending) {
            ItemApi.Get(match.params.id).then(_item => setItem(_item.data));
        }
    }, [match, AuthPending]);

    async function onSave(_item: Item): Promise<boolean> {
        try {
            await ItemApi.Update(_item);
            return true;
        }
        catch {
            return false;
        }
    }

    return <>
        {item && <ItemDetail showDeleteButton={true} onSave={onSave} item={item} />}
    </>
}
