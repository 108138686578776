
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import TransactionApi from '../../api/TransactionApi'
import TransactionDetail from '../../components/transaction/transaction-detail'
import { useApiContext } from '../../context/api-context'
import { Transaction } from '../../entities/Transaction'

interface Props {
   id: string
}

export default function TransactionPage({ match }: RouteComponentProps<Props>): React.ReactElement {
   const { AuthPending } = useApiContext()
   const [transaction, setTransaction] = useState<Transaction>()

   //onMount
   useEffect(() => {
      if (!AuthPending) {
         TransactionApi.Get(match.params.id).then((_item) => setTransaction(_item.data))
      }
   }, [match, AuthPending])

   async function onSave(_item: Transaction): Promise<boolean> {
      try {
         await TransactionApi.Update(_item)
         return true
      } catch {
         return false
      }
   }

   return <>{transaction && <TransactionDetail showDeleteButton={true} onSave={onSave} transaction={transaction} />}</>
}
