import { Checkbox, Table } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { News } from "../../entities/News";
import React from "react";
import NewsLink from "./news-link";
import settings from "../../settings";

interface Props {
    news: News[]
}

export default function NewsList({ news }: Props): React.ReactElement {

    const columns = [
        {
            title: 'MainImage',
            dataIndex: 'mainImageId',
            key: 'mainImageId',
            render: function render(fileId: string) { return <img width={40} src={settings.GetFileUrl(fileId)} alt="No Image" /> },
        },
        {
            title: 'Title',
            key: 'title',
            render: function render(news: News) { return <NewsLink news={news} /> },
        },
        {
            title: 'Published',
            dataIndex: 'published',
            key: 'published',
            render: function render(value: boolean) { return <Checkbox checked={value} /> },
            //sorter: (a: ReigniteUser, b: ReigniteUser) => a.nickname.localeCompare(b.nickname),
        },
        {
            title: 'Created',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a: News, b: News) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
            render: (date: string) => moment(date).format("DD.MM.YYYY HH:mm"),
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        }
    ];

    return <Table columns={columns} dataSource={news} pagination={false} />
}