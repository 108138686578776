import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UserApi from '../api/api-user'
import UserDetail from '../components/user/user-detail'
import { ReigniteUser } from '../entities/ReigniteUser'

export default function MePage(): React.ReactElement {
   const [user, setUser] = useState<ReigniteUser>()
   const history = useHistory()

   async function fetch() {
      const meResponse = await UserApi.GetMe()
      const userResponse = await UserApi.GetUser(meResponse.data.id)
      setUser(userResponse.data)
   }

   //onMount
   useEffect(() => {
      fetch()
   }, [])

   async function onSave(ReigniteUser: ReigniteUser): Promise<boolean> {
      try {
         await UserApi.PutUser(ReigniteUser)
         history.push('/user-all')
         return true
      } catch {
         return false
      }
   }

   return (
      <>
         {user && <UserDetail onSave={onSave} showDeleteButton={false} user={user} />}
         {!user && <Spin />}
      </>
   )
}
