import React, { useState } from "react";

import { useApiContext } from "../context/api-context";

import { Form, Input, Button, Checkbox, Row, Col } from 'antd';

interface LoginModel {
  mail: string,
  password: string
}

export default function LoginPage(): React.ReactElement {
  const [status, setStatus] = useState<string>();
  const api = useApiContext();

  const onFinish = async (values: LoginModel) => {
    setStatus("loading...");

    if (await api.Login(values.mail, values.password)) {
      setStatus("success!");
    }
    else {
      setStatus("Falsche Anmeldedaten!");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <Row justify="center" style={{ height: "100vh" }} align="middle">
      <Col span={12}>
        <h1 style={{ textAlign: "center" }}>Bitte anmelden!</h1>
        <h2 style={{ textAlign: "center", color: "red" }}>{status}</h2>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Mail"
            name="mail"
            rules={[{ required: true, message: 'Please input your mail!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}