export default interface MatchBehaviorOption {
    key: number,
    value: string,
    discription: string
}

export const MatchBehaviorOptions: MatchBehaviorOption[] =
    [
        {
            "key": 1,
            "value": "EqualsMatch",
            "discription": "Equals Matches increase the score, miss matches reduce the score"
        },
        {
            "key": 2,
            "value": "NotEqualsMatch",
            "discription": "Miss Matches increase the score, equals matches reduce the score"
        },
        {
            "key": 3,
            "value": "SimilarityMatch",
            "discription": "Like Equals but not equals matches don't reduce the score"
        },
        {
            "key": 4,
            "value": "ComplementMatch",
            "discription": "Like NotEquals but equals matches don't reduce the score"
        },
        {
            "key": 5,
            "value": "ProximityMatch",
            "discription": "Score is higher when the comparing values are closer"
        },
        {
            "key": 6,
            "value": "AtLeastOne",
            "discription": "Like SimilarityMatch, but when nothing is matching the complete gameprofile scroe drops to zero!"
        },
        {
            "key": 7,
            "value": "Expo2ProximityMatch",
            "discription": "Like Proximity Match, but it is exponential with a streght of 2"
        }
    ]
