import ReloadOutlined from '@ant-design/icons/lib/icons/ReloadOutlined'
import { Button, Card, Col, Divider, Row, Spin, Statistic, Table } from 'antd'
import Title from 'antd/lib/typography/Title'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TransactionApi, { SubscriptionRoot } from '../../api/TransactionApi'
import UserApi from '../../api/api-user'
import { UserInsightsV2 } from '../../entities/UserInsgightsV2'
import { DownloadAsCsv } from '../../helper/DownloadHelper'

export default function UserInsightsPageV2(): React.ReactElement {
   const [lastUpdateDate, setLastUpdateDate] = useState<Moment>()
   const [userInsights, setUserInsights] = useState<UserInsightsV2>()
   const [subscriptions, setSubscriptions] = useState<SubscriptionRoot>()
   const [registerLoad, setRegisterLoad] = useState<boolean>(false)
   const [registerPage, setRegisterPage] = useState<number>(1)
   const [loadCount, setLoadCount] = useState<number>(0)

   async function LoadInsights() {
      setUserInsights(undefined)
      setLoadCount(loadCount + 1)
      const response = await UserApi.GetUserInsightsV2()
      const subResponse = await TransactionApi.GetSubscriptions()
      setLastUpdateDate(moment())
      setUserInsights(response.data)
      setSubscriptions(subResponse.data)
   }

   async function LoadMoreRegistrations() {
      if (userInsights) {
         setLoadCount(loadCount + 1)
         setRegisterLoad(true)
         const response = await UserApi.GetRegistrationInsightsV2(7, userInsights.registerd.length)
         if (response) {
            const insight = {
               ...userInsights,
               registerd: [...userInsights.registerd, ...response.data]
            }
            setUserInsights(insight)
            setRegisterPage(insight.registerd.length / 7)
         }
         setRegisterLoad(false)
      }
   }

   async function PageChange(page: number, pageSize: number) {
      setRegisterPage(page)
   }

   useEffect(() => {
      LoadInsights()
   }, [])

   useEffect(() => {
      if (loadCount == 11) {
         alert("Make sure you don't load insights too often, it's a heavy task, u know?")
      }
      if (loadCount == 16) {
         alert("Please... take care of your loads. I don't want to have to limit you!")
      }
   }, [loadCount])

   return (
      <>
         {!userInsights && <Spin size="large" />}
         {userInsights && lastUpdateDate && (
            <>
               <Row>
                  <Title>User Insights from {lastUpdateDate?.format('LT')}</Title>
                  <Button
                     onClick={LoadInsights}
                     style={{ marginLeft: '15px', marginTop: '2px' }}
                     size="large"
                     type="primary"
                     shape="circle"
                     icon={<ReloadOutlined />}
                  />
               </Row>
               <Link
                  to="/dashboard"
                  style={{
                     color: 'green',
                     fontSize: '1.5rem',
                     textDecoration: 'underline'
                  }}>
                  This is the new dashboard (Open V1)
               </Link>
               <Card title={<strong>Mollie Subscriptions</strong>}>
                  <Row gutter={16}>
                     <Col span={6}>
                        <Statistic title="Total Subscriptions" value={subscriptions?._embedded.length} suffix="Subscriptions" />
                     </Col>
                     <Col span={6}>
                        <Statistic
                           title="Total Active Subscriptions"
                           value={subscriptions?._embedded.filter((x) => x.status === 'active').length}
                           suffix="Subscriptions"
                        />
                     </Col>
                     <Col span={6}>
                        <Statistic
                           title="Total Canceled Subscriptions"
                           value={subscriptions?._embedded.filter((x) => x.status === 'canceled').length}
                           suffix="Subscriptions"
                        />
                     </Col>
                     <Col span={6}>
                        <Statistic
                           title="Total Pending Subscriptions"
                           value={subscriptions?._embedded.filter((x) => x.status === 'pending').length}
                           suffix="Subscriptions"
                        />
                     </Col>
                     <Divider />
                     <Col span={12}>
                        <Statistic
                           title="Active Subscriptions ($0.99)"
                           value={subscriptions?._embedded.filter((x) => x.status === 'active').filter((x) => x.amount.value == 0.99).length}
                           suffix="Subscriptions"
                        />
                     </Col>
                     <Col span={12}>
                        <Statistic
                           title="Canceled Subscriptions ($0.99)"
                           value={subscriptions?._embedded.filter((x) => x.status === 'canceled').filter((x) => x.amount.value == 0.99).length}
                           suffix="Subscriptions"
                        />
                     </Col>
                     <Divider />
                     <Col span={12}>
                        <Statistic
                           title="Active Subscriptions ($11.96)"
                           value={subscriptions?._embedded.filter((x) => x.status === 'active').filter((x) => x.amount.value == 11.96).length}
                           suffix="Subscriptions"
                        />
                     </Col>
                     <Col span={12}>
                        <Statistic
                           title="Canceled Subscriptions ($11.96)"
                           value={subscriptions?._embedded.filter((x) => x.status === 'canceled').filter((x) => x.amount.value == 11.96).length}
                           suffix="Subscriptions"
                        />
                     </Col>
                  </Row>
               </Card>
               <Card style={{ marginTop: '15px' }} title={<strong>Users</strong>}>
                  <Row gutter={16}>
                     <Col span={24}>
                        <Statistic title="Total" value={userInsights.totalUsers} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Registrations" value={userInsights.registeredUsers} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Open Registrations" value={userInsights.openRegisters} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Registrations (today)" value={userInsights.newUserToday} suffix="Users" />
                     </Col>
                     <Col span={12}>
                        <Statistic title="Registrations (week)" value={userInsights.newUserWeek} suffix="Users" />
                     </Col>
                  </Row>
               </Card>
               {/* ToDo: make prettier graphs => https://github.com/samueldelesque/react-simple-charts */}
               <Card
                  style={{ marginTop: '15px' }}
                  title={
                     <>
                        <strong>Registrations per day</strong>
                        <Button style={{ margin: 5 }} type="primary" onClick={() => DownloadAsCsv('registrations', userInsights.registerd)}>
                           Export Result
                        </Button>
                     </>
                  }>
                  <Table
                     dataSource={userInsights.registerd}
                     columns={[
                        {
                           title: 'Date',
                           key: 'time',
                           dataIndex: 'date',
                           render: (date) => moment(date).format('DD.MM.YYYY')
                        },
                        {
                           title: 'Open',
                           dataIndex: 'openRegistration',
                           key: 'openRegistration'
                        },
                        {
                           title: 'Finish',
                           dataIndex: 'registered',
                           key: 'registered'
                        },
                        {
                           title: 'Finish + Card',
                           dataIndex: 'withCards',
                           key: 'withCards'
                        }
                     ]}
                     pagination={{
                        pageSize: 7,
                        onChange: PageChange,
                        current: registerPage,
                        pageSizeOptions: []
                     }}
                     footer={() => (
                        <>
                           <Button disabled={registerLoad} loading={registerLoad} type="primary" onClick={LoadMoreRegistrations}>
                              Load more...
                           </Button>
                        </>
                     )}
                  />
               </Card>
               <Card
                  style={{ marginTop: '15px' }}
                  title={
                     <>
                        <strong>Cards Rank</strong>
                        <Button style={{ margin: 5 }} type="primary" onClick={() => DownloadAsCsv('cards-rank', userInsights.cards)}>
                           Export
                        </Button>
                     </>
                  }>
                  <Row gutter={16}>
                     <Col span={8}>
                        <Statistic title="With Owner" value={userInsights.totalCardsWithOwner} suffix="Cards" />
                     </Col>
                     <Col span={8}>
                        <Statistic title="Without Owner" value={userInsights.totalCards - userInsights.totalCardsWithOwner} suffix="Cards" />
                     </Col>
                     <Col span={8}>
                        <Statistic title="Total" value={userInsights.totalCards} suffix="Cards" />
                     </Col>
                  </Row>
                  <Divider />
                  <Row style={{ marginTop: '15px' }} gutter={16}>
                     {userInsights.cards.map((cards) => (
                        <Col key={cards.cardType} span={12}>
                           <Statistic title={cards.cardType} value={cards.count} suffix="Users" />
                        </Col>
                     ))}
                  </Row>
               </Card>
               <Card title={<strong>Owners per Cardcount</strong>} style={{ marginTop: '15px' }}>
                  <Row gutter={16}>
                     {userInsights.cardcountPerUser.map((cards) => (
                        <Col key={cards.cards} span={12}>
                           <Statistic title={`${cards.cards} Cards`} value={cards.users} suffix="User" />
                        </Col>
                     ))}
                  </Row>
               </Card>
            </>
         )}
      </>
   )
}
