import { Alert, Button, Card, Checkbox, Col, Descriptions, Form, Image, Row, message } from 'antd'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PrintOrder } from '../../api/TransactionApi'
import noImage from '../../images/noImage.png'
import TransactionLink from '../transaction/transaction-link'

interface OrderDetailProps {
   order: PrintOrder
   onSave(orde: PrintOrder): Promise<boolean>
   onRefresh?(): void
   showDeleteButton: boolean
}

export default function OrderDetail({
   onSave,
   onRefresh = () => {
      return
   },
   order: _order
}: OrderDetailProps): React.ReactElement {
   const [order, setOrder] = useState(_order)
   const [isChanged, setIsChange] = useState<boolean>(false)

   function onReset() {
      setIsChange(false)
      setOrder(_order)
   }

   function onSubmit() {
      if (!isChanged) {
         return
      }

      onSave(order).then((success) => {
         if (success) {
            setIsChange(false)
            message.success('Saved!')
         }
      })
   }

   function onChange(printOrder: PrintOrder) {
      setIsChange(true)
      setOrder(printOrder)
   }

   return (
      <>
         {isChanged && (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </Form.Item>
            </>
         )}
         <Card>
            <Row>
               <Col flex="200px">
                  <Image src={order.imageUrl} width={500} fallback={noImage} />
               </Col>
               <Col flex="1">
                  <Descriptions title="Order Info" bordered>
                     <DescriptionsItem span={3} label={'PaymentId'}>
                        {order?.transaction.paymentId}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'ImageUrl'}>
                        <Link target="_blank" rel="noreferrer" to={{ pathname: order?.imageUrl }}>
                           {' '}
                           {order?.imageUrl}
                        </Link>
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Mail Adress'}>
                        {order?.mailadress}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Name'}>
                        {order?.name}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Surname'}>
                        {order?.surname}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'City'}>
                        {order?.city}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Zip'}>
                        {order?.zip}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Street'}>
                        {order?.street}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Street Number'}>
                        {order?.number}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Appartment/Suite'}>
                        {order?.appartment}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Phone'}>
                        {order?.phone}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Transaction ID'}>
                        <TransactionLink transaction={order.transaction} />
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'ShopItem Name'}>
                        {order?.transaction.shopItem.name}
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Commissioned (In Auftrag gegeben)'}>
                        <Checkbox checked={order?.commissioned} onChange={(e) => onChange({ ...order, commissioned: e.target.checked })} />
                     </DescriptionsItem>
                     <DescriptionsItem span={3} label={'Completed'}>
                        <Checkbox checked={order?.completed} onChange={(e) => onChange({ ...order, completed: e.target.checked })} />
                     </DescriptionsItem>
                  </Descriptions>
               </Col>
            </Row>
         </Card>
      </>
   )
}
