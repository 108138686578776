import React, { useState } from 'react'
import { Card, Col, Descriptions, Divider, Row, Image, Input, Button, Alert, message, Upload, Checkbox, DatePicker, InputNumber } from 'antd'
import { Game } from '../../entities/Game'
import noImage from '../../images/noImage.png'
import LanguagePicker from '../picker/language-picker'
import { DeleteOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import settings from '../../settings'
import moment from 'moment'
import GameApi from '../../api/game-api'
import GameSelectionList from './selection/games-selection-list'
import Selection from '../../entities/Selection'

interface GamePageProps {
   game: Game
   onSave(Game: Game): Promise<boolean>
   showDeleteButton: boolean
}

export default function GameDetail({ showDeleteButton, game, onSave }: GamePageProps): React.ReactElement {
   const [isChanged, setIsChanged] = useState<boolean>(false)
   const [languageCode, setLanguageCode] = useState<string>('en')
   const [profileName, setProfileName] = useState(game.profileName)
   const [coverImageFileId, setCoverImageFileId] = useState(game.coverImageFileId)
   const [bannerImageFileId, setBannerImageFileId] = useState(game.bannerImageFileId)
   const [selections, setSelections] = useState(game.selections)
   const [steamId, setSteamId] = useState(game.steamId)
   const [igdbId, setIgdbId] = useState(game.igdbId)
   const [generated, setGenerated] = useState(game.generated)
   const [published, setPublished] = useState(game.published)
   const history = useHistory()

   function onChangeSelections(targetSchemas: Selection[]) {
      setIsChanged(true)
      setSelections(targetSchemas)
   }

   function onReset() {
      setIsChanged(false)
      setProfileName(game.profileName)
      setSelections(game.selections)
   }

   async function onSubmit() {
      setIsChanged(false)

      const targetGame: Game = {
         createdDate: game.createdDate,
         id: game.id,
         bannerImageFileId,
         coverImageFileId,
         lastModifyDate: game.lastModifyDate,
         ownerId: game.ownerId,
         profileName,
         published,
         selections: selections,
         lastMajorModifyDate: game.lastMajorModifyDate,
         steamId,
         igdbId,
         generated
      }

      onSave(targetGame).then((success) => {
         if (success) {
            setIsChanged(false)
            message.success('submit succesful')
         }
      })
   }

   async function onDelete() {
      if (game.id) {
         try {
            const success = await GameApi.DeleteGame(game.id)
            if (success) {
               history.push('/games-all')
            }
            return success
         } catch {
            return false
         }
      }
   }

   async function onCoverUpload(file: Blob): Promise<string> {
      if (!game.id) {
         message.warning('upload after creation possible')
         return 'error'
      }
      if (!file) {
         message.warning('missing content')
         return 'error'
      }

      const response = await GameApi.PostCoverImage(game.id, file)
      if (response.status === 200) {
         setCoverImageFileId(response.data.coverImageFileId)
         message.success('Uploaded!')
         return 'done'
      }

      return 'error'
   }

   async function onBannerUpload(file: Blob): Promise<string> {
      if (!game.id) {
         message.warning('upload after creation possible')
         return 'error'
      }
      if (!file) {
         message.warning('missing content')
         return 'error'
      }

      const response = await GameApi.PostBannerImage(game.id, file)
      if (response.status === 200) {
         setBannerImageFileId(response.data.bannerImageFileId)
         message.success('Uploaded!')
         return 'done'
      }

      return 'error'
   }

   return (
      <>
         <LanguagePicker value={languageCode} onChange={(value: string) => setLanguageCode(value)} />

         <Card>
            {isChanged && (
               <>
                  <Alert message="You have unsaved changes!" banner closable />
                  <Button type="primary" htmlType="submit" onClick={onSubmit}>
                     Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                     Reset
                  </Button>
               </>
            )}
            <Descriptions extra={showDeleteButton && <DeleteOutlined style={{ color: 'red', fontSize: '42px' }} onClick={onDelete} />} />
            <Row>
               <Col flex="200px">
                  <Image src={settings.GetFileUrl(coverImageFileId)} width={180} fallback={noImage} />
               </Col>
               <Col flex="1">
                  <Link to={`/tags/${game.id}`}>
                     <Button>View Tags</Button>
                  </Link>
                  <Link to={`/lfg/${game.id}`}>
                     <Button>View LFG Entries</Button>
                  </Link>

                  <Descriptions column={3} title={profileName} extra={game.id}>
                     <Descriptions.Item span={3} label="Name" labelStyle={{ alignItems: 'center' }}>
                        <Input
                           name="profileName"
                           value={profileName}
                           onChange={(value) => {
                              setProfileName(value.target.value)
                              setIsChanged(true)
                           }}
                        />
                     </Descriptions.Item>
                     <Descriptions.Item label="SteamId" labelStyle={{ alignItems: 'center' }}>
                        <InputNumber
                           type="number"
                           name="steamId"
                           value={steamId}
                           onChange={(value) => {
                              setSteamId(value)
                              setIsChanged(true)
                           }}
                        />
                     </Descriptions.Item>
                     <Descriptions.Item label="IGDB ID" labelStyle={{ alignItems: 'center' }}>
                        <InputNumber
                           type="number"
                           name="igdbId"
                           disabled
                           value={igdbId}
                           onChange={(value) => {
                              setIgdbId(igdbId)
                              setIsChanged(true)
                           }}
                        />
                     </Descriptions.Item>
                     <Descriptions.Item label="Published" labelStyle={{ alignItems: 'center' }}>
                        <Checkbox
                           name="published"
                           checked={published}
                           onChange={(x) => {
                              setPublished(x.target.checked)
                              setIsChanged(true)
                           }}
                        />
                     </Descriptions.Item>
                     <Descriptions.Item label="Generated" labelStyle={{ alignItems: 'center' }}>
                        <Checkbox
                           name="generated"
                           checked={generated}
                           onChange={(x) => {
                              setGenerated(x.target.checked)
                              setIsChanged(true)
                           }}
                        />
                     </Descriptions.Item>
                     <Descriptions.Item label="Create Date" labelStyle={{ alignItems: 'center' }}>
                        <DatePicker showTime disabled value={moment(game.createdDate)} />
                     </Descriptions.Item>
                     <Descriptions.Item label="Last Modify Date" labelStyle={{ alignItems: 'center' }}>
                        <DatePicker showTime disabled value={moment(game.lastModifyDate)} />
                     </Descriptions.Item>
                     <Descriptions.Item label="Last Major Modify Date" labelStyle={{ alignItems: 'center' }}>
                        <DatePicker showTime disabled value={moment(game.lastMajorModifyDate)} />
                     </Descriptions.Item>
                  </Descriptions>
               </Col>
            </Row>
            <Row>
               <Col flex="200px">
                  <label>CoverImage</label>
                  <Upload
                     name="CoverImage"
                     accept=".png, .jpg, .jpeg"
                     listType="picture-card"
                     className="avatar-uploader"
                     showUploadList={false}
                     action={onCoverUpload}>
                     <img src={settings.GetFileUrl(coverImageFileId)} alt="Missing" style={{ width: '100%' }} />
                  </Upload>
               </Col>
               <Col flex="500px">
                  <label>BannerImage</label>
                  <Upload
                     name="BannerImage"
                     accept=".png, .jpg, .jpeg"
                     listType="picture-card"
                     className="avatar-uploader"
                     showUploadList={false}
                     action={onBannerUpload}>
                     <img src={settings.GetFileUrl(bannerImageFileId)} alt="Missing" style={{ width: '100%' }} />
                  </Upload>
               </Col>
            </Row>
            <Divider />
            <h3>
               <strong>Options</strong>
            </h3>
            <GameSelectionList selections={selections} languageCode={languageCode} onChange={onChangeSelections} />
         </Card>
      </>
   )
}
