import { Alert, Button, Card, Col, Descriptions, Dropdown, Form, Input, MenuProps, Row, Select, Skeleton, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import CharacterDetail from '../../../components/images/character-detail'
import AiPromptApi, { AiImageCharacter, AiImageTheme, EThemeCategory } from '../../../api/PromptApi'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import { DEFAULT_AWS_URL_OPTIMIZED } from '../../../api/ImageApi'
import { DownOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'

export default function CharacterNewPage(): React.ReactElement {
   const history = useHistory()
   const [character, setCharacter] = useState<AiImageCharacter>({
      id: undefined,
      helperPrompts: '',
      loraId: '',
      name: '',
      skin: '',
      themeId: '66d0a2ee-f3e8-f947-9952-6f4a72a421ce',
      positivePromptTrigger: '',
      secondaryModelType: 'lora'
   })
   const [themes, setThemes] = useState<AiImageTheme[]>()
   const [isValid, setIsValid] = useState(false)

   const { Option } = Select

   async function onSave(): Promise<boolean> {
      try {
         if (character == undefined) return false

         const response = await AiPromptApi.AddCharacter(character)
         history.push('/characters/' + response.data.id)
         return true
      } catch {
         return false
      }
   }

   async function fetch() {
      const response = await AiPromptApi.GetThemes()

      setThemes(response.data)
   }

   useEffect(() => {
      fetch()
   }, [])

   useEffect(() => {
      if (character.name != '' && character.skin != '' && character.loraId != '' && character.positivePromptTrigger != '' && character.themeId != '')
         setIsValid(true)
   }, [character])

   return (
      <Card>
         {isValid ? (
            <>
               <Alert message="You have unsaved changes!" banner closable />
               <Form.Item>
                  <Button type="primary" htmlType="button" onClick={onSave}>
                     Submit
                  </Button>
               </Form.Item>
            </>
         ) : (
            <></>
         )}
         {/* {character.isHidden != image.isHidden && <Button onClick={() => onSave(character)}>Save</Button>} */}
         <Row>
            <Col flex="1">
               <Descriptions title="Character Info" bordered>
                  <DescriptionsItem span={3} label={'Name'}>
                     <Input value={character.name} onChange={(e) => setCharacter({ ...character, name: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Skin'}>
                     <Input value={character.skin} onChange={(e) => setCharacter({ ...character, skin: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Lora Id'}>
                     <Input value={character.loraId} onChange={(e) => setCharacter({ ...character, loraId: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Positive Prompt Trigger'}>
                     <Input
                        value={character.positivePromptTrigger}
                        onChange={(e) => setCharacter({ ...character, positivePromptTrigger: e.target.value })}></Input>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Helper Prompts'}>
                     <TextArea
                        rows={5}
                        value={character.helperPrompts}
                        onChange={(e) => setCharacter({ ...character, helperPrompts: e.target.value })}></TextArea>
                  </DescriptionsItem>
                  <DescriptionsItem span={3} label={'Secondary Model Type'}>
                     <Input
                        disabled
                        value={character.secondaryModelType}
                        onChange={(e) => setCharacter({ ...character, secondaryModelType: e.target.value })}></Input>
                  </DescriptionsItem>
                  {themes && (
                     <DescriptionsItem span={3} label={'Theme'}>
                        <Select style={{ width: '100%' }} defaultValue={themes[0].id} onChange={(e) => setCharacter({ ...character, themeId: e })}>
                           {themes.map((theme) => {
                              return <Option key={theme.id}>{theme.name}</Option>
                           })}
                        </Select>
                     </DescriptionsItem>
                  )}
               </Descriptions>
            </Col>
         </Row>
      </Card>
   )
}
