import { Price } from './Price'
import { ReigniteUser } from './ReigniteUser'
import { ShopItem } from './ShopItem'

export interface Transaction {
   shopItemId: string
   shopItem: ShopItem
   price: Price
   status: ETransactionStatus
   obtainItemState: EObtainState
   errorMessage: string
   createdDate: Date
   lastModifyDate: Date
   ownerId: string
   owner: ReigniteUser
   id: string
   paymentId: string
}

export enum ETransactionStatus {
   Open = 0,
   Error = -1,
   Cancel = 1,
   //Refund = 2,
   Payed = 10
}

export enum EObtainState {
   None = 0,
   Partly = 1,
   Full = 2
}