import { MultilanguageString } from "./MultilanguageString";

export interface Translation {
    key: string
    translations: MultilanguageString[]
}

export function GetTranslation(languageCode: string, translation: Translation | undefined): string | undefined {
    if (translation === undefined) {
        return undefined;
    }
    return translation.translations.find(item => item.languageCode === languageCode)?.value;
}

export function SetTranslation(languageCode: string, translation: Translation | undefined, value: string): void {
    if (translation === undefined) {
        return undefined;
    }
    const index = translation.translations.findIndex(item => item.languageCode === languageCode);
    if (index === -1) {
        translation.translations.push({languageCode: languageCode, value: value });
    }
    else {
        translation.translations[index] = { languageCode: languageCode, value: value, id: translation.translations[index].id };
    }
}

export function SetAllTranslation(translation: Translation | undefined, value: string): void {
    if (translation === undefined) {
        return;
    }

    translation.translations.forEach(trans => trans.value = value);
}